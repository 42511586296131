const GoalIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 13V2L20.5 6L12.5 10"
        stroke="#216BCC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0612 10.222C21.5933 11.8621 21.6431 13.6206 21.2046 15.2883C20.7661 16.9559 19.8578 18.4624 18.5877 19.6287C17.3177 20.7949 15.7393 21.5718 14.0404 21.8668C12.3415 22.1619 10.5936 21.9627 9.00473 21.2929C7.41581 20.6232 6.05272 19.5111 5.07766 18.0889C4.10259 16.6668 3.55658 14.9945 3.50458 13.2709C3.45259 11.5474 3.8968 9.84522 4.78437 8.36689C5.67194 6.88856 6.96551 5.69632 8.51117 4.93201"
        stroke="#216BCC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5019 9.99701C8.0008 10.664 7.67458 11.4458 7.55294 12.2712C7.43131 13.0966 7.51813 13.9392 7.8055 14.7225C8.09287 15.5057 8.57165 16.2045 9.19822 16.7554C9.8248 17.3062 10.5792 17.6915 11.3928 17.8762C12.2064 18.0608 13.0533 18.039 13.8562 17.8126C14.6592 17.5862 15.3928 17.1625 15.9901 16.5801C16.5874 15.9977 17.0295 15.275 17.2761 14.478C17.5227 13.681 17.5659 12.835 17.4019 12.017"
        stroke="#216BCC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GoalIcon
