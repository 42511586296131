import { ReleaseAtom } from '@/atom/release'
import { IRelease } from '@/types/IRelease'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

interface Props {
  className?: string
  releases: IRelease[]
}

export default function ReleaseVersionList({ className, releases }: Readonly<Props>) {
  const { hash } = useLocation()
  const [releaseState] = useAtom(ReleaseAtom)
  const [isActiveLink, setIsActiveLink] = useState(hash.split('#')[1])


  useEffect(() => {
    setIsActiveLink((releaseState?.activeSectionID) ?? hash.split('#')[1])
  }, [releaseState?.activeSectionID, hash])

  return (
    <ul
      className={clsx(
        'flex flex-col gap-y-2 bg-white border rounded px-8 py-6 h-auto',
        className
      )}
    >
      {releases.map((release) => (
        <li key={release.id}>
          <NavLink
            className={clsx(
              isActiveLink === release.id ? 'text-primary-light' : '',
              'text-sm font-semibold text-primary-dark transition-all hover:text-primary-light'
            )}
            to={location.pathname + '#' + release.id}
          >
            {release.number}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}
