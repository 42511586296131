import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const project = Cookies.get('projectID')

export function getReleaseList() {
  return axiosClient.get(`/releases/${project}`)
}

export function createRelease(payload: { number: string; notes: string }) {
  return axiosClient.post(`/releases/${project}`, { project, ...payload })
}

export function updateRelease(
  payload: { number: string; notes: string },
  releaseID: string
) {
  return axiosClient.put(`/releases/${project}/${releaseID}`, {
    project,
    ...payload,
  })
}
