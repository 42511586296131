import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const projectKey = Cookies.get('projectKey')

export function getProject() {
  return axiosClient.get(`/projects`)
}

export function getProjectByKey() {
  return axiosClient.get(`/projects/${projectKey}`)
}