import ContactProduct from '@/components/navigation/topbar/ContactProduct'
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableCell,
  TableRow,
} from '@/components/ui/table'

interface UserProfile {
  id: string
  profile_picture: string | null
  availability: string
}

interface User {
  id: number
  email: string
  first_name: string
  last_name: string
  user_profile: UserProfile
}

interface DevelopmentTeam {
  id: number
  name: string
}

interface Repository {
  id: string
  name: string
  main_branch: string
}

interface Client {
  id: number
  name: string
  users: User[]
}

export interface Project {
  id: string
  project_manager: User
  product_owners: User[]
  devops: User[]
  development_team: DevelopmentTeam
  client: Client
  repositories: Repository[]
  feedback_users: User[]
  requests_count: any[]
  created_at: string
  modified_at: string
  name: string
  key: string
  napta_id: number
  napta_tma_id: null | number
  real_staffing: boolean
  data: Record<string, any>
  definition_of_done: string[]
  definition_of_ready: string[]
  definition_of_bug: string[]
  slack_id: string
  repository_name: string
  main_branch: string
  staffing_names: boolean
  grouped_staffing: boolean
  client_logo: string
  client_logo_dark: string
  monitoring: boolean
  product_owner: number
  is_feedback_user: boolean
}

interface MyProjectProps {
  projects: Project[] | null
  loading: boolean
}

const MyProject: React.FC<MyProjectProps> = ({ projects, loading }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-2xl text-primary-dark mb-6">Mes projets</h3>
      <div className="mb-4">
        <ContactProduct />
      </div>
      <div className="border border-neutral-dark rounded-sm [&>div]:rounded-sm overflow-hidden">
        <div className="relative max-h-[495px] overflow-y-auto">
          <Table className="border-0">
            <TableHeader className="sticky top-0 bg-primary-dark z-50 border-0">
              <TableRow>
                <TableHead className="font-normal text-white text-xs py-4 w-1/2 bg-primary-dark [&_th]:text-xs [&_th]:p-4  [&_tr]:hover:bg-primary-dark">
                  Titre du projet et client
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell className="text-center py-4">
                    Chargement des données...
                  </TableCell>
                </TableRow>
              ) : (
                projects?.map((project: Project) => (
                  <TableRow key={project.id}>
                    <TableCell className="inline-flex gap-4 items-center">
                      <div className="w-8 h-8">
                        {project?.client_logo && (
                          <img
                            src={project?.client_logo}
                            alt="logo"
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      <div className="inline-flex flex-col">
                        <span className="text-primary-dark text-sm">
                          {project?.name}
                        </span>
                        <span className="text-neutral-darker text-xs">
                          {project?.client?.name}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default MyProject
