import CircleRating from './CircleRating'
import clsx from 'clsx'

interface IQualityRatingBlockProps {
  title: string
  paragraph: string
  rating: string
  className?: string
}
const QualityRatingBlock: React.FC<IQualityRatingBlockProps> = ({
  title,
  paragraph,
  rating,
  className,
}) => {
  return (
    <div className={clsx('flex items-center gap-4 px-4 ', className)}>
      <div>
        <CircleRating rating={rating} className="w-20 h-20  text-2xl " />
      </div>
      <div className="flex flex-col items-center gap-2">
        <h3 className="text-primary-dark text-base self-start">{title}</h3>
        <p className="text-neutral-darker text-sm self-start">{paragraph}</p>
      </div>
    </div>
  )
}

export default QualityRatingBlock
