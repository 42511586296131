import { useEffect, useState } from 'react'
import useUserAccess from './use-user-access'
import Cookies from 'js-cookie'
import { IAccess } from '@/types/IAccess'

export default function useDirOwnerProdAccess() {
  const { decrypt } = useUserAccess()
  const [hasAccess, setHasAccess] = useState(true)
  useEffect(() => {
    const rawAccess = Cookies.get('user_access') as string
    if (rawAccess) {
      const _d = decrypt(rawAccess)
      handleAccess(_d)
    } else {
      setHasAccess(false)
    }
  }, [decrypt])
  const handleAccess = (stringifyValue: string) => {
    const access: IAccess = JSON.parse(stringifyValue)
    setHasAccess(access.is_manager || access.is_superuser)
  }

  return { hasAccess }
}
