import React from "react"
import ContentLoader, { IContentLoaderProps } from "react-content-loader"

const ReleaseDetailsLoader = (props: IContentLoaderProps) => (
  <ContentLoader 
    speed={2}
    width={1000}
    height={211}
    viewBox="0 0 1000 211"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="-2" y="41" rx="2" ry="2" width="125" height="10" /> 
    <rect x="-2" y="15" rx="2" ry="2" width="197" height="18" /> 
    <rect x="0" y="60" rx="2" ry="2" width="1000" height="176" />
  </ContentLoader>
)

export default ReleaseDetailsLoader

