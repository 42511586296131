import axiosClient from '@/configs/axios'

interface IProfilePayload {
  subscribed_to_emails?: boolean
  subscribed_to_reminders?: boolean
  profile_picture?: File | string
  first_name?: string
  last_name?: string
}
export function postProfile(payload: IProfilePayload) {
  const formData = new FormData()

  if (payload.profile_picture) {
    formData.append('profile_picture', payload.profile_picture)
    payload?.first_name && formData.append('first_name', payload.first_name)
    payload?.last_name && formData.append('last_name', payload.last_name)
  }

  const params = payload.profile_picture ? formData : payload
  return axiosClient.post('/profile', params)
}

export function getProfileInformation() {
  return axiosClient.get('/profile')
}

export function getProjectList() {
  return axiosClient.get('/projects')
}
