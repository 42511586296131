import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

const VersionNoteLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={400}
    height={50}
    viewBox="0 0 400 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="58" y="18" rx="2" ry="2" width="400" height="10" />
    <rect x="58" y="34" rx="2" ry="2" width="400" height="10" />
    <rect x="9" y="14" rx="2" ry="2" width="36" height="36" />
  </ContentLoader>
)

export default VersionNoteLoader
