import HeadingTitle from '@/components/ui/heading-title'
import { Button } from '@/components/ui/button'
import PlusIcon from '@/components/icons/PlusIcon'
import CustomCard from '@/components/ui/custom-card'
import MultipleLineChart from '@/components/client-satisfaction/MultipleLineChart'
import { useEffect, useState } from 'react'
import { ChartData, InputData } from '@/types/IClientSatisfaction'
import { getClientSatisfaction } from '@/services/client-satification.service'
import { LoaderCircleIcon } from 'lucide-react'
import EmptyData from '@/components/empty-data'
import SidebarRating from '@/components/client-satisfaction/SidebarRating'
import SatisfactionReviewItem from '@/components/client-satisfaction/SatisfactionReviewItem'
import { Card, CardContent } from '@/components/ui/card'
import FeedBackAccordion from '@/components/client-satisfaction/FeedBackAccordion'
import GlobalFeedback from '@/components/client-satisfaction/GlobalFeedback'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/configs/route-naming'

interface IAverageRatings {
  overall: number
  communication: number
  understanding: number
  reactivity: number
  deliverables: number
  deadlines: number
}

export default function ClientSatisfaction() {
  const [satisfactionData, setSatisfactionData] = useState<InputData[] | null>(
    null
  )
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [averages, setAverages] = useState<IAverageRatings>({
    overall: 0,
    communication: 0,
    understanding: 0,
    reactivity: 0,
    deliverables: 0,
    deadlines: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFeedback, setSelectedFeedback] = useState<InputData | null>(
    null
  )
  const navigate = useNavigate()

  const calculateAverages = (data: InputData[]) => {
    if (data.length === 0) return

    const sum = data.reduce(
      (acc, item) => ({
        communication: acc.communication + item.grade_communication,
        understanding: acc.understanding + item.grade_understanding,
        reactivity: acc.reactivity + item.grade_reactivity,
        deliverables: acc.deliverables + item.grade_deliverables,
        deadlines: acc.deadlines + (item.grade_deadlines || 0),
        count: acc.count + 1,
      }),
      {
        communication: 0,
        understanding: 0,
        reactivity: 0,
        deliverables: 0,
        deadlines: 0,
        count: 0,
      }
    )

    const averages = {
      communication: +(sum.communication / sum.count).toFixed(1),
      understanding: +(sum.understanding / sum.count).toFixed(1),
      reactivity: +(sum.reactivity / sum.count).toFixed(1),
      deliverables: +(sum.deliverables / sum.count).toFixed(1),
      deadlines: +(sum.deadlines / sum.count).toFixed(1),
    }

    // Calculate overall average
    const overall = +(
      Object.values(averages).reduce((a, b) => a + b, 0) /
      Object.keys(averages).length
    ).toFixed(1)

    setAverages({
      overall,
      ...averages,
    })
  }

  const formatDataForChart = (inputData: InputData[]): ChartData[] => {
    // sort data by week
    const sortedData = [...inputData].sort((a, b) =>
      a.week.localeCompare(b.week)
    )

    return sortedData.map((item) => ({
      week: item.week,
      Communication: item.grade_communication,
      'Conseil et enjeux': item.grade_understanding,
      Réactivité: item.grade_reactivity,
      'Qualité des livrables': item.grade_deliverables,
      'Respect des délais': item.grade_deadlines,
    }))
  }

  const formatISODateToDate = (isoDate: string): string => {
    const date = new Date(isoDate)
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }

    return new Intl.DateTimeFormat('fr-FR', options).format(date)
  }

  const getSelectedFeedback = (id: string) => {
    const selectedFeedback = satisfactionData?.find((item) => item.id === id)
    if (selectedFeedback) setSelectedFeedback(selectedFeedback)
  }

  const calculateSelectedFeedbackAverages = (
    item: InputData | null
  ): IAverageRatings => {
    if (!item)
      return {
        overall: 0,
        communication: 0,
        understanding: 0,
        reactivity: 0,
        deliverables: 0,
        deadlines: 0,
      }

    const communication = item.grade_communication
    const understanding = item.grade_understanding
    const reactivity = item.grade_reactivity
    const deliverables = item.grade_deliverables
    const deadlines = item.grade_deadlines || 0

    const overall = +(
      (communication + understanding + reactivity + deliverables + deadlines) /
      5
    ).toFixed(1)

    return {
      overall,
      communication,
      understanding,
      reactivity,
      deliverables,
      deadlines,
    }
  }

  const handleLargeData = (data: InputData[]): InputData[] => {
    const len = data?.length ?? 0
    if (len < 25) {
      return data
    }
    const interval = Math.floor(len / 25)
    const reducedData = []
    for (let i = 0; i < 25; i++) {
      const index = i * interval
      reducedData.push(data[index])
    }
    return reducedData
  }

  const resetSelectedFeedback = () => {
    setSelectedFeedback(null)
  }

  useEffect(() => {
    const getSatisfactionData = async () => {
      try {
        setIsLoading(true)
        const { data } = await getClientSatisfaction()
        const feedbackData = handleLargeData(data)
        setSatisfactionData(feedbackData)
        const formattedChartData = formatDataForChart(feedbackData)
        setChartData(formattedChartData)
        calculateAverages(feedbackData)
      } catch (error) {
        throw new Error(`Unexpected error fetching satisfaction: ${error}`)
      } finally {
        setIsLoading(false)
      }
    }
    getSatisfactionData()
  }, [])

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <LoaderCircleIcon className="animate-spin" width={'32'} height={'32'} />
      </div>
    )
  }

  return (
    <div className="pb-6">
      <HeadingTitle>
        <div className="flex justify-between items-center pb-6">
          {!selectedFeedback ? (
            <h1>
              <button
                className="appearance-none"
                onClick={resetSelectedFeedback}
              >
                Satisfaction client
              </button>
            </h1>
          ) : (
            <h1 className="inline-flex gap-2 items-center">
              <button
                onClick={resetSelectedFeedback}
                className="appearance-none text-neutral-darker/60 hover:text-neutral-darker transition-colors"
              >
                Satisfaction client ›
              </button>
              <span className="text-2xl text-primary-dark">
                Avis du {formatISODateToDate(selectedFeedback?.created_at)}
              </span>
            </h1>
          )}
          <Button onClick={() => navigate(ROUTES.PROTECTED.ADD_FEEDBACK)}>
            <PlusIcon /> Ajouter un avis client
          </Button>
        </div>
      </HeadingTitle>

      {satisfactionData?.length === 0 ? (
        <div className="h-[464px] flex justify-center items-center">
          <EmptyData />
        </div>
      ) : (
        <>
          {!selectedFeedback && (
            <div className="flex flex-col gap-6">
              <CustomCard
                title="Informations générales"
                className="[&>div]:mb-0"
              >
                <div className="grid grid-cols-6">
                  <div className="col-span-2 border-r border-neutral-dark">
                    <SidebarRating averages={averages} />
                  </div>
                  <div className="col-span-4">
                    <div className="flex justify-center items-center h-full w-full pr-4">
                      <MultipleLineChart data={chartData} />
                    </div>
                  </div>
                </div>
              </CustomCard>

              <CustomCard
                title="Détails des avis clients"
                className="[&_div]:mb-0"
              >
                {satisfactionData?.map((item) => (
                  <SatisfactionReviewItem
                    key={JSON.stringify(item)}
                    review={item}
                    selected={getSelectedFeedback}
                  />
                ))}
              </CustomCard>
            </div>
          )}
          {selectedFeedback && (
            <Card className="overflow-hidden shadow-none">
              <CardContent className="p-0">
                <div className="grid grid-cols-6">
                  <div className="col-span-2 border-r border-neutral-dark">
                    <SidebarRating
                      averages={calculateSelectedFeedbackAverages(
                        selectedFeedback
                      )}
                    />
                  </div>
                  <div className="col-span-4">
                    <div className="px-8 py-6">
                      <GlobalFeedback feedback={selectedFeedback} />
                    </div>
                    <div>
                      <FeedBackAccordion feedback={selectedFeedback} />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </div>
  )
}
