import axiosClient from '@/configs/axios'

interface IDocumentPayload {
  file: File
}
export function postDocuments(issueId: string, payload: IDocumentPayload) {
  const formData = new FormData()
  formData.append('file', payload.file)
  return axiosClient.post(`/document/${issueId}`, formData)
}
