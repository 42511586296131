const ProfilIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2605_4954)">
        <path
          d="M15 16.6667C15 15.3407 14.4732 14.0689 13.5355 13.1312C12.5979 12.1935 11.3261 11.6667 10 11.6667C8.67392 11.6667 7.40215 12.1935 6.46447 13.1312C5.52678 14.0689 5 15.3407 5 16.6667"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0003 11.6667C11.8413 11.6667 13.3337 10.1743 13.3337 8.33333C13.3337 6.49238 11.8413 5 10.0003 5C8.15938 5 6.66699 6.49238 6.66699 8.33333C6.66699 10.1743 8.15938 11.6667 10.0003 11.6667Z"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2605_4954">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProfilIcon
