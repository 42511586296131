import React from 'react'

interface TaskStatusItemProps {
  icon: React.ReactElement
  count: number
  label: string
}

const TaskStatusItem: React.FC<TaskStatusItemProps> = ({
  icon,
  count,
  label,
}) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="p-2 rounded bg-primary-light/10">{icon}</div>
      <div className="">
        <p className="text-semibold text-primary-dark text-base">{count}</p>
        <p className="text-neutral-darker text-sm">{label}</p>
      </div>
    </div>
  )
}

export default TaskStatusItem
