import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from 'recharts'
import LineAreaTooltip from '@/components/dashboard/LineAreaTooltip'
import { ChartContainer } from '../ui/chart'
import { format, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
interface ILineAreaChartData {
  date: string
  coverage: number
}

interface ILineAreaChartProps {
  data: ILineAreaChartData[]
  hideAxis?: boolean
  height?: string
  showGrid?: boolean
  referencePosition?: number
}

const LineAreaChart: React.FC<ILineAreaChartProps> = ({
  data,
  hideAxis = true,
  height = '200',
  showGrid = false,
  referencePosition = 60,
}) => {
  const renderXAxisTick = (props: any) => {
    const { x, y, payload } = props
    const date = parseISO(payload.value)

    const formattedDate = format(date, 'd MMM', { locale: fr })
    const formattedTime = format(date, 'HH:mm', { locale: fr })

    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text dy={16} fontSize={12} fill="#717171">
          {formattedDate}
        </text>
        <text dy={30} fontSize={12} fill="#717171">
          {formattedTime}
        </text>
      </g>
    )
  }
  return (
    <ChartContainer
      className={`max-h-[${height}px] w-full`}
      config={{
        coverage: {
          label: 'coverage',
          color: 'hsl(var(--chart-))',
        },
      }}
    >
      <AreaChart
        accessibilityLayer
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="fillTime" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1F73E0" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#1F73E0" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        {showGrid && <CartesianGrid stroke="#F6F6F6" strokeWidth={1.5} />}
        <XAxis
          dataKey="date"
          hide={hideAxis}
          tickLine={false}
          axisLine={false}
          angle={-45}
          textAnchor="end"
          height={70}
          tick={renderXAxisTick}
        />
        <YAxis
          hide={hideAxis}
          domain={[0, 100]}
          tickLine={false}
          axisLine={false}
        />

        {/* tooltip for hover interaction */}
        <Tooltip content={<LineAreaTooltip />} cursor={false} />

        {/* Reference Line */}
        <ReferenceLine
          y={referencePosition}
          stroke="#FFDE34"
          strokeDasharray="6 6"
        />

        <foreignObject x="70%" y="34%" width="200" height="100">
          <div className="relative z-50 translate-x-4">
            <div className="text-primary-dark text-xs bg-third text-center rounded-sm h-6 inline-flex items-center justify-center px-3">
              Objectif
              <div
                className="absolute h-3 w-3 -left-1 -translate-y-1/2  bg-third"
                style={{ transform: 'rotate(45deg)', top: '6px' }}
              />
            </div>
          </div>
        </foreignObject>

        <Area
          type="natural"
          dataKey="coverage"
          stroke="#1F73E0"
          fill="url(#fillTime)"
          fillOpacity={0.4}
        />
      </AreaChart>
    </ChartContainer>
  )
}

export default LineAreaChart
