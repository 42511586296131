import Cookies from 'js-cookie'
import { Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from '@/configs/route-naming'

const REDIRECT_PATH = ROUTES.CLIENT.PROJECT
export default function HasProjectGard() {
  const hasProject = Cookies.get('projectID')
  if (!hasProject) {
    return <Navigate to={REDIRECT_PATH} replace />
  }
  return <Outlet />
}
