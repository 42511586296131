import { useState } from 'react'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Progress } from '@/components/ui/progress'
import SearchIcon from '@/components/icons/SearchIcon'
import ChevronLeft from '../icons/ChevronLeft'
import { Button } from '../ui/button'
import ChevronRight from '../icons/ChevronRight'

export interface FileReport {
  name: string
  lines: number
  hits: number
  partials: number
  misses: number
  coverage: number
}

interface CoverageTableProps {
  data: FileReport[]
  searchPlaceholder?: string
  tableLabels?: {
    name?: string
    lines?: string
    hits?: string
    partials?: string
    misses?: string
    coverage?: string
  }
}

const CoverageTable = ({
  data,
  searchPlaceholder = 'Rechercher un fichier...',
  tableLabels = {
    name: 'Fichier',
    lines: 'Lignes',
    hits: 'Couvert',
    partials: 'Partiels',
    misses: 'Manquants',
    coverage: 'Couverture (en %)',
  },
}: CoverageTableProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const filteredFiles = data.filter((file) =>
    file.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const totalPages = Math.ceil(filteredFiles.length / itemsPerPage)

  // Calculate current page items
  const currentData = filteredFiles.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const getPageNumbers = () => {
    const pages: (number | string)[] = []
    const startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(totalPages, currentPage + 3)

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    if (startPage > 1) {
      pages.unshift('...')
      pages.unshift(1)
    }
    if (endPage < totalPages) {
      pages.push('...')
      pages.push(totalPages)
    }

    return pages
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-between pb-6">
        <span className="text-sm text-neutral-darker">
          {filteredFiles.length} fichiers scannés
        </span>
        <Input
          placeholder={searchPlaceholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="placeholder:text-[#717171] max-w-[275px] placeholder:font-medium text-sm bg-neutral-light h-8"
          prefixIcon={<SearchIcon />}
        />
      </div>
      <div className="border border-neutral-dark rounded-sm [&>div]:rounded-sm">
        <Table>
          <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
            <TableRow>
              <TableHead className="text-white">{tableLabels.name}</TableHead>
              <TableHead className="text-white text-left">
                {tableLabels.lines}
              </TableHead>
              <TableHead className="text-white text-left">
                {tableLabels.hits}
              </TableHead>
              <TableHead className="text-white text-left">
                {tableLabels.partials}
              </TableHead>
              <TableHead className="text-white text-left">
                {tableLabels.misses}
              </TableHead>
              <TableHead className="text-white text-left">
                {tableLabels.coverage}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="bg-white [&_td]:p-3">
            {currentData.map((file) => (
              <TableRow key={file.name}>
                <TableCell className="text-sm text-primary-dark">
                  {file.name}
                </TableCell>
                <TableCell className="text-left">{file.lines}</TableCell>
                <TableCell className="text-left">{file.hits}</TableCell>
                <TableCell className="text-left">{file.partials}</TableCell>
                <TableCell className="text-left">{file.misses}</TableCell>
                <TableCell className="min-w-52">
                  <div className="flex items-center justify-end gap-2">
                    <div className="w-full">
                      <Progress
                        value={file.coverage}
                        className="h-2 bg-neutral-light rounded-lg [&[data-state=indeterminate]>div]:bg-primary-light"
                      />
                    </div>
                    <span className="min-w-14 text-left text-primary-dark">
                      {Number(file.coverage.toFixed(2))}%
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Pagination Section */}
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          className="w-8 h-8 p-0"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <ChevronLeft />
        </Button>

        {/* Page numbers */}
        {getPageNumbers().map((page, index) => (
          <Button
            key={`${page}-${index}`}
            variant={currentPage === page ? 'default' : 'outline'}
            className="w-8 h-8 p-0"
            onClick={() => setCurrentPage(page as any)}
          >
            {page}
          </Button>
        ))}

        <Button
          variant="outline"
          className="w-8 h-8 p-0"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  )
}

export default CoverageTable
