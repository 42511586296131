import InclinedArrow from '@/components/icons/InclinedArrow'

interface IReleaseItemProps {
  version: string
  isNew: boolean
  date: string
}
const getDayMonth = (timestamp: string): { day: string; month: string } => {
  const date = new Date(timestamp)

  const day = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleString('fr-FR', { month: 'short' })

  return { day, month }
}

const ReleaseItem: React.FC<IReleaseItemProps> = ({ version, isNew, date }) => {
  const release = getDayMonth(date)
  return (
    <div className="bg-neutral-dark rounded p-2 relative before:contents[''] before:top-0 before:rounded-s-md before:absolute before:w-1 before:h-full before:bg-third before:left-0 hover:bg-neutral-dark/60 transition duration-300 ease-in-out cursor-pointer">
      <div className="flex justify-between items-center gap-2">
        <div className="flex gap-2">
          <div className="font-semibold p-1 rounded bg-primary-dark w-11 h-11 self-center">
            <span className="text-light text-sm text-center block">
              {release.day}
            </span>
            <span className="uppercase text-light/60 text-sm block">
              {release.month}
            </span>
          </div>
          <div className="inline-flex gap-1 flex-col">
            <span className="font-semibold text-primary-dark text-sm">
              Version {version}
            </span>
            {isNew && (
              <div className="p-[2px] rounded-full bg-third/30 text-center">
                <span className="text-primary-dark">Nouveau</span>
              </div>
            )}
          </div>
        </div>
        <InclinedArrow />
      </div>
    </div>
  )
}

export default ReleaseItem
