import CustomBarChart from '@/components/code-quality/CustomBarChart'
import GoalIcon from '@/components/icons/Goal'
import { IChartData } from '@/types/IQuality'
import EmptyData from '@/components/empty-data'

interface ITabQualityItemProps {
  data: IChartData[]
  legend: string
  goal?: string
  showReferenceLine?: boolean
}

const TabQualityItem: React.FC<ITabQualityItemProps> = ({
  data,
  legend,
  goal,
  showReferenceLine = false,
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      {goal && (
        <div className="px-8 self-start">
          <div className="bg-neutral-light p-2 inline-flex items-center gap-2 rounded-md">
            <div className="w-8 h-8 inline-flex items-center justify-center bg-primary-light/10 rounded-md">
              <GoalIcon />
            </div>
            <p>{goal}</p>
          </div>
        </div>
      )}
      {data?.length ? (
        <CustomBarChart
          data={data}
          showReferenceLine={showReferenceLine}
          legend={legend}
        />
      ) : (
        <EmptyData />
      )}
    </div>
  )
}

export default TabQualityItem
