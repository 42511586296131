import HomeIcon from '@/components/icons/Home'
import TimeTracking from '../icons/TimeTracking'
import MessageIcon from '../icons/Message'
import TicketToTest from '../icons/TicketToTest'
import RequestIcon from '../icons/Request'
import ServerIcon from '../icons/Server'
import BellIcon from '../icons/BellIcon'
import LigthningIcon from '../icons/Lighnting'
import VulnerabilityIcon from '../icons/Vulnerability'
import CodeIcon from '../icons/Code'
import UnitTestingIcon from '../icons/Unit'
import ReportIcon from '../icons/report'
import PerfIcon from '../icons/PerfIcon'
import { StarIcon } from 'lucide-react'
import StatisticIcon from '../icons/StatisticsIcon'
import { ROUTES } from '@/configs/route-naming'
import SprintInProgressIcon from '../icons/SprintInProgressIcon'
import SprintPastIcon from '../icons/SprintPastIcon'
import BacklogIcon from '../icons/BacklogIcon'

export interface IMenuItem {
  title: string
  icon: JSX.Element
  path: string
  name?: string
  noRedirect?: boolean
}

export const TOP_MENU_LIST: IMenuItem[] = [
  {
    title: 'Accueil',
    icon: <HomeIcon />,
    path: ROUTES.CLIENT.HOME,
  },
  {
    title: 'Notifications',
    icon: <BellIcon />,
    path: '',
    noRedirect: true,
  },
  {
    title: 'Messages',
    icon: <MessageIcon />,
    path: ROUTES.CLIENT.CHAT,
  },
  {
    title: 'Demandes',
    icon: <RequestIcon />,
    path: ROUTES.CLIENT.REQUEST,
  },
]

export const BOTTOM_MENU_LIST_DELIVERY: IMenuItem[] = [
  {
    title: 'Tickets à tester',
    icon: <TicketToTest />,
    path: ROUTES.CLIENT.TICKETS_TO_TEST,
    name: 'to-test',
  },
  {
    title: 'Sprint en cours',
    icon: <SprintInProgressIcon />,
    path: ROUTES.CLIENT.INPROGRESS_SPRINT,
  },
  {
    title: 'Sprint passés',
    icon: <SprintPastIcon />,
    path: ROUTES.CLIENT.PAST_SPRINT,
  },
  {
    title: 'Backlog',
    icon: <BacklogIcon />,
    path: ROUTES.CLIENT.BACKLOG,
  },
  {
    title: 'Suivi des temps',
    icon: <TimeTracking />,
    path: ROUTES.CLIENT.STAFFING,
  },
]

export const BOTTOM_MENU_LIST_MONITORING: IMenuItem[] = [
  {
    title: 'Surveillance serveur',
    icon: <ServerIcon />,
    path: ROUTES.CLIENT.MONITORING_SERVER,
  },
  {
    title: 'État de l’application',
    icon: <PerfIcon />,
    path: ROUTES.CLIENT.APP_STATUS,
  },
  {
    title: 'Performances',
    icon: <LigthningIcon />,
    path: ROUTES.CLIENT.PERFORMANCE,
  },
  {
    title: 'Vulnérabilités',
    icon: <VulnerabilityIcon />,
    path: ROUTES.CLIENT.VULNERABILITY,
  },
  {
    title: 'Qualité du code',
    icon: <CodeIcon />,
    path: ROUTES.CLIENT.CODE_QUALITY,
  },
  {
    title: 'Tests unitaires',
    icon: <UnitTestingIcon />,
    path: ROUTES.CLIENT.UNIT_TEST,
  },
  {
    title: 'Rapports d’erreurs',
    icon: <ReportIcon />,
    path: ROUTES.CLIENT.ERROR_REPORT,
  },
]

export const MANAGER_DIRECTOR_ROUTE: IMenuItem[] = [
  {
    title: 'Satisfaction client',
    icon: <StarIcon className="text-primary-dark stroke-1" />,
    path: ROUTES.PROTECTED.FEEDBACK,
  },
  {
    title: 'Rapports des sprints',
    icon: <StatisticIcon />,
    path: ROUTES.PROTECTED.SPRINT_REPORT,
  },
]
