import { HistoryEntry, QualityMetric } from '@/types/IQuality'

interface IChartData {
  date: string
  value: number
}

export const METRICS = {
  MAINTAINABILITY: 'sqale_rating',
  RELIABILITY: 'reliability_rating',
  SECURITY: 'security_rating',
  SECURITY_REVIEW: 'security_review_rating',
  CODE_SMELLS: 'code_smells',
  BUGS: 'bugs',
  VULNERABILITIES: 'vulnerabilities',
  SECURITY_HOTSPOTS: 'security_hotspots',
} as const

export const calculateAverage = (data: HistoryEntry[]): number => {
  const values = data?.map((item) => parseFloat(item.value ?? '0'))
  const sum = values?.reduce((acc, curr) => acc + curr, 0)
  return sum ? Math.round(sum / values.length) : 0
}

export const convertValueToLetterRating = (value: number): string => {
  const ratings = ['N/A', 'A', 'B', 'C', 'D', 'E']
  return ratings[value] || 'A'
}

export const getRating = (
  qualityData: QualityMetric[] | null,
  metric: string
) => {
  const metricItem = qualityData?.find((item) => item.metric === metric)
  const average = calculateAverage(metricItem?.history || [])
  return { rating: convertValueToLetterRating(average), score: average }
}

export const getMetricData = (
  qualityData: QualityMetric[] | null,
  metric: string
) => {
  const metricItem = qualityData?.find((item) => item.metric === metric)
  const metricCount = metricItem?.history.length ?? 0
  if (metricCount < 2) return { current: 0, last: 0 }

  const lastMetricValue = metricItem?.history[metricCount - 2]?.value ?? 0
  const currentMetricValue = metricItem?.history[metricCount - 1]?.value ?? 0

  return {
    current: Number(currentMetricValue),
    last: Number(lastMetricValue),
  }
}

export const getChartData = (
  qualityData: QualityMetric[] | null,
  metric: string
): IChartData[] => {
  if (!qualityData) return []

  const metricItem =
    qualityData.find((item) => item.metric === metric)?.history || []
  return metricItem.map((item) => {
    const date = new Date(item.date)
    const formattedDate = date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'short',
    })
    return {
      date: formattedDate,
      value: Number(item.value ?? 0),
    }
  })
}

export const getLastAnalysisDate = (
  qualityData: QualityMetric[] | null
): string => {
  const history = qualityData?.[0]?.history
  if (!history || history.length === 0) return ''
  return new Date(history[history.length - 1].date).toLocaleDateString('fr-FR')
}

export const getTotalScore = (qualityData: QualityMetric[] | null): string => {
  const scores = [
    getRating(qualityData, METRICS.MAINTAINABILITY).score,
    getRating(qualityData, METRICS.RELIABILITY).score,
    getRating(qualityData, METRICS.SECURITY).score,
    getRating(qualityData, METRICS.SECURITY_REVIEW).score,
  ]
  const total = Math.round(
    scores.reduce((acc, curr) => acc + curr, 0) / scores.length
  )
  return convertValueToLetterRating(total)
}
