import { InputData } from '@/types/IClientSatisfaction'
import CustomRating from '@/components/client-satisfaction/CustomRating'
import EmptyData from '@/components/empty-data'
import { extractFeedbackRatings } from '@/lib/utils.satisfaction'

interface SatisfactionReviewItemProps {
  review: InputData
  selected: Function
}

const SatisfactionReviewItem = ({
  review,
  selected,
}: SatisfactionReviewItemProps) => {
  const formattedDate = new Date(review.created_at).toLocaleDateString(
    'fr-FR',
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  )

  const weekNumber = review.week.split('-W')[1]

  const globalAverage = +(
    (review.grade_communication +
      review.grade_understanding +
      review.grade_reactivity +
      review.grade_deliverables +
      review.grade_deadlines) /
    5
  ).toFixed(1)

  return (
    <div className="grid grid-cols-4 px-8 py-4 border-b border-neutral-dark">
      <div className="col-span-1">
        <div className="inline-flex flex-col justify-start items-start gap-2">
          <CustomRating rating={globalAverage} showNumber={false} />
          <p className="text-neutral-darker text-sm">
            {formattedDate} • semaine {weekNumber}
          </p>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-primary-dark">
            {(extractFeedbackRatings(review.comment).global
              ? extractFeedbackRatings(review.comment).global
              : review.comment) ?? <EmptyData />}
          </p>
          {review.comment && (
            <button
              onClick={() => selected(review.id)}
              className="self-start appearance-none underline text-neutral-darker transition duration-300 text-sm hover:cursor-pointer hover:text-primary-dark"
            >
              En savoir plus
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default SatisfactionReviewItem
