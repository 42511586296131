interface FeedbackRating {
  communication: string
  conseil: string
  reactivite: string
  deliverables: string
  deadlines: string
  global: string
}

const TOPIC = {
  communication: 'COM',
  conseil: 'UND',
  reactivite: 'REA',
  delivrable: 'DEL',
  deadlines: 'DEA',
  global: 'GLO',
} as const

export const extractFeedbackRatings = (feedback: string): FeedbackRating => {
  // Function to extract comments
  const extractComments = (text: string, prefix: string): string => {
    const lines = text.split('\n').map((line) => line.trim()) // Split and trim lines
    const line = lines.find((line) => line.startsWith(`${prefix}:`)) // Find matching line
    return line?.split(':').slice(1).join(':').trim() ?? ''
  }

  return {
    communication: extractComments(feedback, TOPIC.communication),
    conseil: extractComments(feedback, TOPIC.conseil),
    reactivite: extractComments(feedback, TOPIC.reactivite),
    deliverables: extractComments(feedback, TOPIC.delivrable),
    deadlines: extractComments(feedback, TOPIC.deadlines),
    global: extractComments(feedback, TOPIC.global),
  }
}
