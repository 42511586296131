import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'
import { useEffect, useState } from 'react'

const ACTIVE_MENU_CLASS =
  "before:contents[' '] before:absolute before:border-b-2 before:-bottom-2 before:w-full before:border-b-primary-light"
export default function FilterBar({
  setFilterProps,
  totalCount,
  data,
}: Readonly<{
  setFilterProps: (v: string) => void
  totalCount: number
  data: any[]
}>) {
  const [filterValue, setFilterValue] = useState('')
  const [filterList] = useState<
    { label: string; count: number }[]
  >([
    {
      count: 0,
      label: 'Refus PO',
    },
    {
      count: 0,
      label: 'Refus Client',
    },
  ])
  useEffect(() => {
    setFilterProps(filterValue)
  }, [filterValue])
  return (
    <ul className="flex items-center gap-x-4 [&>li>button]:flex [&>li>button]:items-center [&>li>button]:gap-x-1">
      <li
        className={cn(filterValue === '' ? ACTIVE_MENU_CLASS : '', 'relative')}
      >
        <button onClick={() => setFilterValue('')}>
          Tous{' '}
          <Badge className="bg-neutral-darker/20 hover:bg-neutral-darker/20 text-neutral-darker shadow-none">
            {data.length}
          </Badge>
        </button>
      </li>
      {filterList.map((item) => (
        <li
          key={item.label}
          className={cn(
            filterValue === item.label ? ACTIVE_MENU_CLASS : '',
            'relative'
          )}
        >
          <button onClick={() => setFilterValue(item.label)}>
            {item.label}
            <Badge className="bg-neutral-darker/20 hover:bg-neutral-darker/20 text-neutral-darker shadow-none">
              {item.label === 'Refus PO'
                ? data.filter((item) => item.rejectedPO > 0).length
                : data.filter((item) => item.rejectedClient > 0).length}
            </Badge>
          </button>
        </li>
      ))}
    </ul>
  )
}
