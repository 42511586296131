import { Card, CardContent } from '../ui/card'
import { CustomTooltipProps } from '@/types/IChartTooltip'

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (!active || !payload) return null

  return (
    <Card className="bg-white border border-neutral-dark">
      <CardContent className="p-3">
        <p className="mb-1 text-neutral-darker text-xs">{label}</p>
        {payload.map((item) => (
          <div key={item.name} className="flex items-center gap-2 text-sm mb-1">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-sm text-neutral-darker">{item.name}:</span>
            <span className="text-sm text-primary-dark">{item.value}</span>
          </div>
        ))}
      </CardContent>
    </Card>
  )
}

export default CustomTooltip
