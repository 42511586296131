import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const projectKey = Cookies.get('projectKey')
export function getTicketToTest() {
  return axiosClient.get(`/tickets/${projectKey}?status=Client+Testing`)
}

export function getTotalTicketToTest() {
  return axiosClient.get(`/tickets/${projectKey}?status=Client+Testing&count=1`)
}
