const LigthningIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33325 11.6666C2.17555 11.6672 2.02094 11.623 1.88737 11.5391C1.75381 11.4553 1.64677 11.3353 1.5787 11.193C1.51063 11.0508 1.48432 10.8921 1.50283 10.7355C1.52133 10.5789 1.5839 10.4308 1.68325 10.3083L9.93325 1.80832C9.99513 1.73688 10.0795 1.68861 10.1724 1.67143C10.2653 1.65424 10.3613 1.66916 10.4447 1.71374C10.528 1.75831 10.5937 1.8299 10.631 1.91674C10.6683 2.00358 10.675 2.10052 10.6499 2.19165L9.04991 7.20831C9.00273 7.33458 8.98689 7.47042 9.00374 7.60415C9.02059 7.73789 9.06963 7.86555 9.14665 7.97617C9.22368 8.08679 9.32639 8.17708 9.44598 8.23928C9.56556 8.30149 9.69845 8.33375 9.83325 8.33332H15.6666C15.8243 8.33278 15.9789 8.377 16.1125 8.46083C16.246 8.54467 16.3531 8.66469 16.4211 8.80693C16.4892 8.94918 16.5155 9.10783 16.497 9.26443C16.4785 9.42104 16.4159 9.56918 16.3166 9.69165L8.06658 18.1916C8.00469 18.2631 7.92036 18.3113 7.82743 18.3285C7.73449 18.3457 7.63848 18.3308 7.55514 18.2862C7.4718 18.2417 7.40609 18.1701 7.3688 18.0832C7.33151 17.9964 7.32485 17.8994 7.34991 17.8083L8.94991 12.7916C8.99709 12.6654 9.01294 12.5295 8.99609 12.3958C8.97924 12.2621 8.9302 12.1344 8.85317 12.0238C8.77615 11.9132 8.67343 11.8229 8.55385 11.7607C8.43426 11.6985 8.30137 11.6662 8.16658 11.6666H2.33325Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LigthningIcon
