import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { DataTable } from '@/components/sprint-report/DataTable/DataTable'

import RedirectIcon from '@/components/icons/RedirectIcon'
import { cn } from '@/lib/utils'

export type TicketsListType = {
  key: string
  rejectedPO: number
  rejectedClient: number
}

export default function SprintReportTable({
  data,
}: Readonly<{ data: TicketsListType[] }>) {
  const [filter, setFilter] = useState<string>()
  const handleBadgeColor = (v: number) => {
    switch (v) {
      case 0:
        return 'text-success bg-success/20'
      case 1:
        return 'text-average bg-average/20'
      case 2:
        return 'text-secondary bg-secondary/20'
    }
    return 'text-secondary bg-secondary/20'
  }
  const columns: ColumnDef<TicketsListType>[] = [
    {
      accessorKey: 'key',
      header: 'Clé',
      cell: ({ row }) => {
        const key: string = row.getValue('key')
        return (
          <div className="text-start transition-all text-primary-dark text-sm font-medium">
            <div
              aria-hidden
              className="hover:cursor-pointer font-semibold flex gap-x-2 [&>span]:hover:opacity-100"
            >
              {key}
              <span className="opacity-0 transition-all">
                <RedirectIcon className="[&_path]:stroke-primary-dark" />
              </span>{' '}
            </div>
          </div>
        )
      },
    },
    {
      accessorKey: 'rejectedPO',
      header: 'Nb refus PO',
      cell: ({ row }) => {
        const count: number = row.getValue('rejectedPO')
        return (
          <span
            className={cn(handleBadgeColor(+count), 'py-1 px-2 rounded-full')}
          >
            {count}
          </span>
        )
      },
    },
    {
      accessorKey: 'rejectedClient',
      header: 'Nb refus Client',
      cell: ({ row }) => {
        const count: number = row.getValue('rejectedClient')
        return (
          <span
            className={cn(handleBadgeColor(+count), 'py-1 px-2 rounded-full')}
          >
            {count}
          </span>
        )
      },
    },
  ]
  const [ticketsList, setTicketsList] = useState<TicketsListType[]>([])
  useEffect(() => {
    setTicketsList([...data])
  }, [data])
  useEffect(() => {
    if (!filter) {
      setTicketsList(data)
      return
    }
    if (filter === 'Refus PO') {
      setTicketsList(data.filter((item) => item.rejectedPO > 0))
      return
    }
    if (filter === 'Refus Client') {
      setTicketsList(data.filter((item) => item.rejectedClient > 0))
    }
  }, [filter])

  return (
    <div className="text-primary-dark">
      <DataTable
        handleFilter={setFilter}
        columns={columns}
        data={ticketsList}
        oraiginalDataset={data}
      />
    </div>
  )
}
