const DiskIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 13H2"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45 6.11L2 13V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V13L18.55 6.11C18.3844 5.77679 18.1292 5.49637 17.813 5.30028C17.4967 5.10419 17.1321 5.0002 16.76 5H7.24C6.86792 5.0002 6.50326 5.10419 6.18704 5.30028C5.87083 5.49637 5.61558 5.77679 5.45 6.11Z"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17H6.01"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17H10.01"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DiskIcon
