const ArchivedIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 2H2.00016C1.63197 2 1.3335 2.29848 1.3335 2.66667V4.66667C1.3335 5.03486 1.63197 5.33333 2.00016 5.33333H14.0002C14.3684 5.33333 14.6668 5.03486 14.6668 4.66667V2.66667C14.6668 2.29848 14.3684 2 14.0002 2Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 5.33337V12.6667C2.6665 13.0203 2.80698 13.3595 3.05703 13.6095C3.30708 13.8596 3.64622 14 3.99984 14H11.9998C12.3535 14 12.6926 13.8596 12.9426 13.6095C13.1927 13.3595 13.3332 13.0203 13.3332 12.6667V5.33337"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 8H9.33317"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArchivedIcon
