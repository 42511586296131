import TicketNotifIcon from '@/components/icons/TicketNotifIcon'
import { formatTimeElapsed } from '@/lib/utils'
import { INotificationType } from '@/types/INotificationResponse'
import clsx from 'clsx'

interface Props {
  label: string
  notificationList: INotificationType[]
}

export default function SplittedNotifications({
  label,
  notificationList,
}: Readonly<Props>) {
  const isSeen = (lastSeen: Date, lastActivity: Date) => {
    return lastSeen.getTime() > lastActivity.getTime()
  }
  return (
    <div className="border-b-2">
      <div className="px-8 py-6 text-xs text-neutral-darker/60">{label}</div>
      {notificationList.map((notification) => (
        <div
          key={notification.content}
          className={clsx(
            isSeen(
              new Date(notification.last_seen),
              new Date(notification.last_activity)
            )
              ? 'bg-[#FFDE340D]'
              : '',
            'hover:cursor-pointer border-b flex py-7 px-9 justify-center items-start gap-x-5 text-sm font-semibold'
          )}
        >
          <div
            className={clsx(
              isSeen(
                new Date(notification.last_seen),
                new Date(notification.last_activity)
              )
                ? " before:contents[' '] before:absolute before:w-2 before:h-2 before:bg-yellow before:rounded-full before:left-4"
                : '',
              'flex flex-col justify-center items-center gap-y-2 pb-2'
            )}
          >
            <TicketNotifIcon />
          </div>
          <div className="w-full">
            <p>{notification.content}</p>
            <span className="text-neutral-darker/60 text-xs font-normal">
              {formatTimeElapsed(notification.last_activity)}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}
