import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
import { Navigate, Outlet } from 'react-router-dom'

const REDIRECT_PATH = '/'
export default function ProductOwnerAndDirectorGuard() {
  const {hasAccess} = useDirOwnerProdAccess()
  if (!hasAccess) {
    return <Navigate to={REDIRECT_PATH} replace />
  }
  return <Outlet />
}
