const LeaveIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4H16C16.5304 4 17.0391 4.21071 17.4142 4.58579C17.7893 4.96086 18 5.46957 18 6V20"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H5"
        stroke="#11103B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 20H22"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12V12.01"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 4.56201V20.719C13 20.8709 12.9653 21.0208 12.8987 21.1573C12.8321 21.2938 12.7352 21.4134 12.6155 21.5069C12.4958 21.6004 12.3564 21.6653 12.2078 21.6969C12.0592 21.7285 11.9054 21.7258 11.758 21.689L5 20V5.56201C5.00007 5.11602 5.1492 4.68285 5.42371 4.33134C5.69821 3.97984 6.08232 3.73017 6.515 3.62201L10.515 2.62201C10.8098 2.54834 11.1174 2.54278 11.4146 2.60577C11.7118 2.66875 11.9908 2.79863 12.2303 2.98553C12.4699 3.17243 12.6637 3.41144 12.797 3.68443C12.9304 3.95741 12.9998 4.2582 13 4.56201Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LeaveIcon
