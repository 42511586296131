import EmptyData from '@/components/empty-data'
import { Metric } from '@/components/time-tracking/Metric'
import Table from '@/components/time-tracking/Table'
import HeadingTitle from '@/components/ui/heading-title'
import { getStaffingData } from '@/services/staffing.service'
import { IStaffingData } from '@/types/IStaffing'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
export default function TimeTracking() {
  const [userList, setUserList] = useState<IStaffingData['users']>([])
  const [periodList, setPeriodList] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const getStaffing = async () => {
    try {
      const { data }: { data: IStaffingData } = await getStaffingData()
      setPeriodList(data.periods)
      setUserList(data.users)
    } catch (error) {
      throw new Error('An error occured: ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getStaffing()
  }, [])

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }
  if (!userList.length) {
    return (
      <div className="h-full">
        <EmptyData msg="Pas de suivi" />
      </div>
    )
  }
  return (
    <>
      <HeadingTitle label="Suivi des temps" />
      <Metric userList={userList} />
      <Table periodList={periodList} userList={userList} />
    </>
  )
}
