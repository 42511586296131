const UnitTestingIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.83333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H5.83333"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 2.5H15.8334C16.2754 2.5 16.6994 2.67559 17.0119 2.98816C17.3245 3.30072 17.5001 3.72464 17.5001 4.16667V5.83333"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5001 14.166V15.8327C17.5001 16.2747 17.3245 16.6986 17.0119 17.0112C16.6994 17.3238 16.2754 17.4993 15.8334 17.4993H14.1667"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 17.4993H4.16667C3.72464 17.4993 3.30072 17.3238 2.98816 17.0112C2.67559 16.6986 2.5 16.2747 2.5 15.8327V14.166"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 9.99935L9.44436 10.8327L11.6666 9.16602"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5714 5H6.42857C5.63959 5 5 5.63959 5 6.42857V13.5714C5 14.3604 5.63959 15 6.42857 15H13.5714C14.3604 15 15 14.3604 15 13.5714V6.42857C15 5.63959 14.3604 5 13.5714 5Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UnitTestingIcon
