import { ProjectsAtom } from '@/atom/projects'
import ProjectCardLoader from '@/components/loader/project-card-loader'
import TopBar from '@/components/navigation/topbar/TopBar'
import ProjectCard from '@/components/project/ProjectCard'
import { ROUTES } from '@/configs/route-naming'
import useUserAccess from '@/hooks/use-user-access'
import { getUserStatus } from '@/services/user-status.service'
import { IAccess } from '@/types/IAccess'
import { IMappedProject } from '@/types/IProject'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

export default function ProjectPage() {
  const [projectList, setProjectList] = useState<IMappedProject[]>([])
  const [isLoadUserAccess, setIsLoadUserAccess] = useState(true)
  const [projectAtomState] = useAtom(ProjectsAtom)
  const { setUserAccess } = useUserAccess()
  const computeRequestCount = (request: { count: number; status: string }[]) =>
    request.reduce((sum, curr) => curr.count + sum, 0)

  const handleProjectSizeDisplay = (totalItem: number) =>
    totalItem > 5 ? 'grid-cols-3' : 'grid-cols-2'

  function getProjectList() {
    try {
      const { isPending, list } = projectAtomState
      if (!isPending) {
        setProjectList(
          list.map((item) => ({
            logoDarkUrl: item.client_logo_dark,
            logoUrl: item.client_logo,
            clientName: item.client.name,
            id: item.id,
            key: item.key,
            name: item.name,
            requestCount: computeRequestCount(item.requests_count),
          }))
        )
      }
    } catch (error) {
      throw new Error('An error occured')
    }
  }

  async function getUserStatusData() {
    try {
      const { data }: { data: IAccess } = await getUserStatus()
      setUserAccess(data)
    } catch (error) {
      throw new Error('Error on fetching user access: ' + error)
    } finally {
      setIsLoadUserAccess(false)
    }
  }

  function handleUniqueProject(project: IMappedProject) {
    Cookies.set('projectID', project.id)
    Cookies.set('projectKey', project.key)
    Cookies.set('projectName', project.name)
    Cookies.set('projectUrlAvatar', project.logoUrl)
    Cookies.set('projectUrlAvatarDark', project.logoDarkUrl)
    window.location.href = ROUTES.CLIENT.HOME
  }
  useEffect(() => {
    getProjectList()
  }, [projectAtomState.isPending])

  useEffect(() => {
    if (projectList.length === 1) {
      handleUniqueProject(projectList[0])
    }
  }, [projectList.length])

  useEffect(() => {
    getUserStatusData()
  }, [])
  return (
    <div className="bg-neutral-red h-screen">
      <TopBar />
      <div className="w-full h-[calc(100%-3rem)] bg-neutral-light flex-col flex justify-center items-center">
        <h3 className="font-semibold text-2xl text-primary-dark">
          Mes projets
        </h3>
        {!projectAtomState.isPending &&
        projectList.length > 1 &&
        !isLoadUserAccess ? (
          <div
            className={clsx(
              handleProjectSizeDisplay(projectList.length),
              'grid gap-4 pt-6 overflow-y-auto max-h-[644px]'
            )}
          >
            {projectList.map((project) => (
              <ProjectCard project={project} key={project.id} />
            ))}
          </div>
        ) : (
          <div className="grid gap-4 pt-6 max-h-[644px] grid-cols-2">
            <div className="bg-white rounded border p-6">
              <ProjectCardLoader />
            </div>
            <div className="bg-white rounded border p-6">
              <ProjectCardLoader />
            </div>
            <div className="bg-white rounded border p-6">
              <ProjectCardLoader />
            </div>
            <div className="bg-white rounded border p-6">
              <ProjectCardLoader />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
