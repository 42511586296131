export const chartConfig = {
  value: {
    label: 'Tâches',
  },
  todo: {
    label: 'À faire',
    color: '#1F73E0',
  },
  inProgress: {
    label: 'En cours',
    color: '#1F73E099',
  },
  done: {
    label: 'Terminées',
    color: '#1F73E033',
  },
}
