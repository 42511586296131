import { Badge } from '@/components/ui/badge'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { IIncident } from '@/types/IIncidents'
import clsx from 'clsx'

export default function LastIncidents({
  lastIncidents,
}: Readonly<{ lastIncidents: IIncident[] }>) {

  function formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <div className="px-8 py-6">
      <Accordion className="border rounded" type="single" collapsible>
        {lastIncidents.map((item) => (
          <AccordionItem key={item.id} value={item.id}>
            <AccordionTrigger className="bg-neutral-dark/40 text-primary-dark text-sm font-normal flex-row-reverse hover:no-underline justify-end gap-x-4 px-4 border-b">
              <div className="px-4 flex items-center gap-x-8">
                {formatDate(new Date(item.modified_at))}
                <Badge
                  className={clsx(
                    item.status === 'SOLVED'
                      ? 'bg-success/10 text-success hover:bg-success/10'
                      : 'bg-secondary/10 text-secondary hover:bg-secondary/10',
                    'uppercase shadow-none'
                  )}
                >
                  {item.status === 'SOLVED' ? 'Résolue' : 'Non résolue'}
                </Badge>{' '}
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4 text-primary-dark text-sm">
              {item.comment}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
