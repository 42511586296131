import React from 'react'
import { Card, CardContent } from '@/components/ui/card'
import CircleRating from './CircleRating'
import CustomBarChart from './CustomBarChart'
import clsx from 'clsx'

interface IChartData {
  date: string
  value: number
}

interface IMetricPanelProps {
  value: number
  previousValue: number
  title: string
  subtitle: string
  rating: string
  chartData: IChartData[]
}

const MetricPanelCard: React.FC<IMetricPanelProps> = ({
  value,
  title,
  subtitle,
  rating,
  previousValue,
  chartData,
}) => {
  let stats,
    style,
    difference = value - previousValue

  if (difference > 0) {
    stats = `+ ${difference}`
    style = 'bg-secondary/10 text-secondary'
  } else {
    stats = difference.toString().split('').join(' ')
    style = 'bg-success/10 text-success'
  }
  return (
    <div className="px-8 py-6">
      <Card>
        <CardContent className="p-0">
          <div className="grid grid-cols-[244px_1fr]">
            <div className="flex flex-col items-center justify-between gap-5 p-8 border-r border-neutral-dark">
              <h3 className="text-primary-dark tex t-base">{title}</h3>
              <CircleRating rating={rating} className=" w-20 h-20 text-2xl" />
              <div className="flex flex-col items-center justify-center">
                <div className="text-primary-dark text-3xl relative ">
                  {value}
                  {difference !== 0 && (
                    <p
                      className={clsx(
                        'absolute top-1 ml-1 h-6 w-10 rounded-full inline-flex items-center justify-center',
                        style
                      )}
                    >
                      <span className="text-xs">{stats}</span>
                    </p>
                  )}
                </div>

                <p className="text-neutral-darker text-sm">{subtitle}</p>
              </div>
            </div>
            <div className="p-8">
              <CustomBarChart data={chartData} legend={subtitle} />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default MetricPanelCard
