import { INotificationType } from '@/types/INotificationResponse'
import TicketNotifIcon from '../icons/TicketNotifIcon'
import { formatTimeElapsed } from '@/lib/utils'
import clsx from 'clsx'

interface Props {
  notification: INotificationType
  idx: number
  notifCount: number
}

export default function CurrentNotificationsItem({
  notification,
  idx,
  notifCount,
}: Readonly<Props>) {
  const isLastItem = () => notifCount === idx + 1
  return (
    <div className="flex justify-center items-start gap-x-5 text-sm font-semibold">
      <div className="flex flex-col justify-center items-center gap-y-2 pb-2">
        <TicketNotifIcon />
        <div
          className={clsx(isLastItem() ? 'hidden' : '', 'h-6 border-l w-0')}
        />
      </div>
      <div className="w-full">
        <p>{notification.content}</p>
        <span className="text-neutral-darker/60 text-sm font-normal">
          {formatTimeElapsed(notification.last_activity)}
        </span>
      </div>
    </div>
  )
}
