import clsx from 'clsx'
interface ICircleRating {
  rating: string
  className?: string
}

const getColorByRating = (rating: string): string => {
  switch (rating) {
    case 'A':
      return 'bg-success'
    case 'B':
      return 'bg-light-green'
    case 'C':
      return 'bg-third'
    case 'D':
      return 'bg-average'
    case 'E':
      return 'bg-secondary'
    default:
      return 'bg-secondary'
  }
}

const CircleRating: React.FC<ICircleRating> = ({ rating = 'A', className }) => {
  return (
    <div
      className={clsx(
        'rounded-full flex items-center justify-center',
        className,
        getColorByRating(rating)
      )}
    >
      <span className="text-white"> {rating}</span>
    </div>
  )
}

export default CircleRating
