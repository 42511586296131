import { Star } from 'lucide-react'

interface RatingProps {
  /**
   * Current rating value (supports half stars)
   * @default 0
   */
  rating: number
  /**
   * Maximum rating value
   * @default 4
   */
  maxRating?: number
  /**
   * Size of the star icons in pixels
   * @default 24
   */
  size?: number
  /**
   * Additional CSS classes
   */
  className?: string
  /**
   * Show  the rating
   */
  showNumber?: boolean
}

const CustomRating: React.FC<RatingProps> = ({
  rating = 0,
  maxRating = 4,
  size = 24,
  className = '',
  showNumber = true,
}) => {
  // Ensure rating is between 0 and maxRating
  const normalizedRating = Math.min(Math.max(0, rating), maxRating)
  const totalRating = Number(normalizedRating.toFixed(1))
    .toString()
    .replace('.', ',')

  const renderStar = (position: number): JSX.Element => {
    const filled = normalizedRating >= position
    const halfFilled = !filled && normalizedRating > position - 1

    return (
      <div
        key={position}
        className="relative inline-block"
        aria-label={`${normalizedRating} out of ${maxRating} stars`}
      >
        {/* Empty star background */}
        <Star size={size} className="stroke-primary-dark" strokeWidth={1} />

        {/* Full star */}
        {filled && (
          <Star
            size={size}
            className="absolute top-0 left-0 fill-primary-dark stroke-primary-dark"
            strokeWidth={1}
          />
        )}

        {/* Half star */}
        {halfFilled && (
          <div className="absolute top-0 left-0 w-1/2 overflow-hidden">
            <Star
              size={size}
              className="fill-primary-dark stroke-primary-dark"
              strokeWidth={1}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center gap-4">
      <div className={`inline-flex items-center gap-1 rounded ${className}`}>
        {[...Array(maxRating)].map((_, index) => renderStar(index + 1))}
      </div>
      {showNumber && (
        <span className="text-2xl text-primary-dark">
          {totalRating} / {maxRating}
        </span>
      )}
    </div>
  )
}
export default CustomRating
