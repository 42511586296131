export const ROUTES = {
  AUTH: {
    LOGOUT: '/auth/logout',
    LOGIN: '/auth/login',
    RESET_PWD: '/auth/reset'
  },
  CLIENT: {
    HOME: '/',
    PROJECT: '/projects',
    INPROGRESS_SPRINT: '/sprint',
    PAST_SPRINT: '/archive',
    STAFFING: '/staffing',
    TICKETS_TO_TEST: '/testing',
    BACKLOG: '/backlog',
    REQUEST: '/requests',
    MONITORING_SERVER: '/monitoring',
    REQUEST_DETAILS: 'request/:id',
    RELEASE: '/release',
    CHAT: '/messages',
    VULNERABILITY: '/security',
    CODE_QUALITY: 'quality',
    UNIT_TEST: '/coverage',
    ERROR_REPORT: '/errors',
    APP_STATUS: '/status',
    PERFORMANCE: '/performances',
  },
  PROTECTED: {
    FEEDBACK: '/feedbacks',
    ADD_FEEDBACK: '/add-feedbacks',
    SPRINT_REPORT: '/reports',
  },
}
