import { X } from 'lucide-react'
interface IActiveFilterTag {
  label: string
  onRemove: React.MouseEventHandler<HTMLButtonElement>
}

const ActiveFilterTag: React.FC<IActiveFilterTag> = ({ label, onRemove }) => {
  return (
    <div className="inline-flex items-center gap-2 px-2 py-1  bg-primary-light/5  rounded-full mr-2 border border-primary-light">
      <span className="text-primary-light text-base">{label}</span>
      <button
        className="appearance-none font-semibold text-primary-light w-4 h-4 rounded-full inline-flex justify-center items-center border border-primary-light m-0 hover:cursor-pointer"
        onClick={onRemove}
      >
        <X size={12} />
      </button>
    </div>
  )
}

export default ActiveFilterTag
