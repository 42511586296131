import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable } from '@/components/error-reports/DataTable'

import clsx from 'clsx'
import { ChevronDownIcon, Clock, File, LoaderCircleIcon } from 'lucide-react'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import RepositoryTabs from '@/components/ui/repository-tab'
import { getErrors } from '@/services/error-reports.service'
import { getProjectByKey } from '@/services/project.service'
import { IProject, Repository } from '@/types/IProject'
import { IErrorReports } from '@/types/IErrorReports'
import { formatDistanceToNow } from 'date-fns'
import { fr } from 'date-fns/locale'
import HeadingTitle from '@/components/ui/heading-title'

type ErrorType = {
  search?: string
  description: {
    value: string
    file: string
    type: string
    substatus: 'new' | 'escalating' | 'ongoing'
    isUnhandled: boolean
    priority: string
    creationDate: string
    lastSeen: string
    id: string
  }
  substatus: 'new' | 'escalating' | 'ongoing'
  isUnhandled: string
  priority: string
  occurences: number
  userCount: number
  action: string
  creationDate: string
}

enum ErrorColor {
  new = 'bg-yellow',
  high = 'bg-secondary',
  isUnhandled = 'bg-average',
  ongoing = 'bg-black',
  default = '',
}

export default function ErrorReports() {
  const [currentExpendedRow, setCurrentExpendedRow] = useState('')

  const handleColorStatusByPriority = (status: {
    substatus: 'new' | 'escalating' | 'ongoing'
    isUnhandled: boolean
    priority: string
  }) => {
    const { substatus, isUnhandled, priority } = status
    if (substatus === 'new') {
      return substatus
    }
    if (priority === 'high') {
      return priority
    }
    if (isUnhandled === true) {
      return 'isUnhandled'
    }
    if (substatus === 'ongoing') {
      return substatus
    }
    return 'default'
  }

  const columns: ColumnDef<ErrorType>[] = [
    {
      accessorKey: 'search',
      header: 'Nom et risque de la dépendance',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('search')}
          </div>
        )
      },
    },
    {
      accessorKey: 'creationDate',
      header: 'Date',
      cell: ({ row }) => {
        const data = new Date(row.getValue('creationDate'))
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {data.getDate()}
          </div>
        )
      },
    },
    {
      accessorKey: 'substatus',
      header: 'Substatus',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('substatus')}
          </div>
        )
      },
    },
    {
      accessorKey: 'priority',
      header: 'Prio',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('priority')}
          </div>
        )
      },
    },
    {
      accessorKey: 'isUnhandled',
      header: 'handling',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('isUnhandled')}
          </div>
        )
      },
    },
    {
      accessorKey: 'description',
      header: () => <p className="pl-1">Type d'erreur et description</p>,
      cell: ({ row }) => {
        const {
          value,
          type,
          id,
          file,
          creationDate,
          lastSeen,
          isUnhandled,
          priority,
          substatus,
        }: ErrorType['description'] = row.getValue('description')
        return (
          <div className="relative">
            <div
              className={clsx(
                currentExpendedRow === id
                  ? ''
                  : 'text-nowrap overflow-hidden text-ellipsis',
                'text-primary-dark pl-2 text-sm max-w-[600px]'
              )}
            >
              <span
                className={clsx(
                  ErrorColor[
                    handleColorStatusByPriority({
                      isUnhandled,
                      priority,
                      substatus,
                    })
                  ],
                  'absolute w-3 h-3 -left-3 top-1 rounded-e-full'
                )}
              />
              <p className="font-bold capitalize">{type}</p>
              {value}
              <div className="text-neutral-darker/60 pt-2 flex text-xs flex-col gap-y-1">
                {currentExpendedRow === id ? (
                  <div className="flex items-center gap-x-1">
                    <File height={16} width={16} />
                    {file}
                  </div>
                ) : (
                  ''
                )}
                <div className="flex items-center gap-x-1">
                  <Clock height={16} width={16} />
                  {formatDistanceToNow(new Date(lastSeen), {
                    locale: fr,
                    addSuffix: true,
                  })}{' '}
                  - existant depuis{' '}
                  {formatDistanceToNow(new Date(creationDate), {
                    locale: fr,
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      accessorKey: 'occurences',
      header: 'Occurences',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm">
            {row.getValue('occurences')}
          </div>
        )
      },
    },
    {
      accessorKey: 'userCount',
      header: 'Utilisateurs affectés',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm">
            {row.getValue('userCount')}
          </div>
        )
      },
    },
    {
      accessorKey: 'action',
      header: () => <div />,
      cell: ({ row }) => {
        return (
          <button
            className={clsx(
              row.getValue('action') === currentExpendedRow ? 'rotate-180' : '',
              'transition-all'
            )}
          >
            <ChevronDownIcon
              className="text-neutral-darker"
              width={20}
              height={20}
            />
          </button>
        )
      },
    },
  ]
  const [errorList, setErrorList] = useState<ErrorType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [noApplication, setNoApplication] = useState(false)
  const [branches, setBranches] = useState<Repository[]>([])
  const [activeIndex, setActiveIndex] = useState(0)

  const getRepositoriesID: () => Promise<string> = async () => {
    try {
      const { data }: { data: IProject[] } = await getProjectByKey()
      if (!data[0]?.repositories.length) {
        setNoApplication(true)
        return Promise.resolve('')
      }
      setBranches(data[0].repositories)
      return Promise.resolve(data[0].repositories[activeIndex].id)
    } catch (error) {
      return Promise.reject(
        new Error('Error occured when fetching Repository ID')
      )
    }
  }

  const tabItems =
    branches?.map((branch) => ({
      id: branch.id,
      label: branch.name,
    })) || []

  const handleTabChange = (index: number) => {
    setActiveIndex(index)
  }

  async function fetchData() {
    try {
      setIsLoading(true)
      const repositoryID = await getRepositoriesID()
      // const STATIC_ID = '12f2712b-4e07-437b-b655-e76b847e9872'
      const { data }: { data: IErrorReports[] } = await getErrors(repositoryID)

      const mappedResult: ErrorType[] = data.map((item) => ({
        search: `${item.metadata.value} ${item.type}`,
        description: {
          value: item.metadata.value,
          file: item.metadata.filename || '',
          type: item.type,
          substatus: item.substatus,
          isUnhandled: item.isUnhandled,
          priority: item.priority,
          creationDate: item.firstSeen,
          lastSeen: item.lastSeen,
          id: item.id,
        },
        occurences: Number(item.count),
        userCount: Number(item.userCount),
        action: item.id,
        substatus: item.substatus,
        isUnhandled: item.isUnhandled ? 'isUnhandled' : '',
        priority: item.priority,
        creationDate: item.firstSeen,
      }))
      setErrorList(mappedResult)
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [activeIndex])

  return (
    <>
      <HeadingTitle label="Rapports d’erreurs" className="mb-0" />
      {noApplication ? (
        <MonitoringNoConfiguration
          context="Rapports d’erreurs"
          detailList={[
            "S'assurer que chaque partie du code est couverte par un ou plusieurs tests unitaires",
            'Suivre l’évolution de la couverture des tests dans le projet',
          ]}
          urlImg="/img/no-config-error-report.png"
        />
      ) : (
        <>
          {branches.length > 0 && (
            <RepositoryTabs
              items={tabItems}
              onChange={handleTabChange}
              activeIndex={activeIndex}
            />
          )}

          <div className="bg-white p-6 border border-neutral-dark">
            {isLoading ? (
              <div className="h-80 flex items-center w-full justify-center">
                <LoaderCircleIcon className="animate-spin w-8 h-8" />
              </div>
            ) : (
              <DataTable
                setCurrentExpendedRow={setCurrentExpendedRow as any}
                columns={columns}
                errorList={errorList}
                data={errorList}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}
