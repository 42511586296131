const DisponibilityCarret: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="9"
      height="22"
      viewBox="0 0 9 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8.76667" height="22" rx="4.38333" fill="#0BB54F" />
    </svg>
  )
}

export default DisponibilityCarret
