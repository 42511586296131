import clsx from 'clsx'
import ReleaseDetailItem from './ReleaseDetailItem'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { ReleaseAtom } from '@/atom/release'
import { IRelease } from '@/types/IRelease'
import ReleaseDetailsLoader from '../loader/release-details-loader'
interface Props {
  className?: string
  releases: IRelease[]
  isLoading: boolean
  setSelectedRelease: (release: IRelease) => void
}
export default function ReleaseDetailListing({
  className,
  releases,
  isLoading,
  setSelectedRelease,
}: Readonly<Props>) {
  const { hash } = useLocation()
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [releaseState, setSectionActive] = useAtom(ReleaseAtom)
  useEffect(() => {
    if (hash && !isLoading) {
      document.getElementById(hash.split('#')[1])?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [hash, isLoading])

  useEffect(() => {
    if (listContainerRef.current && !isLoading) {
      const listContainerRefRectTop =
        listContainerRef.current.getBoundingClientRect().top

      /**
       * set view level at 1/3 of parent div
       */
      const maxNearestPositionFromListContainer = Math.round(
        listContainerRefRectTop / 3 + listContainerRefRectTop
      )
      const allVisibleElements = Array.from(
        listContainerRef.current.querySelectorAll('.release-item')
      )
      const scrollEventCallback = () => {
        allVisibleElements.forEach((el) => {
          const currentElRectTop = el.getBoundingClientRect().top
          /**
           * when view is between far top and 1/3 of parent height
           */
          if (
            currentElRectTop >= listContainerRefRectTop &&
            currentElRectTop <= maxNearestPositionFromListContainer
          ) {
            setSectionActive({
              ...releaseState,
              activeSectionID: el.id,
            })
          }
        })
      }

      listContainerRef.current.addEventListener('scroll', scrollEventCallback)

      return () => {
        listContainerRef.current?.removeEventListener(
          'scroll',
          scrollEventCallback
        )
      }
    }
  }, [listContainerRef, isLoading])
  return (
    <div
      ref={listContainerRef}
      className={clsx(
        'flex overflow-y-auto h-[calc(100vh-8rem)] flex-col gap-y-6',
        className
      )}
    >
      {isLoading ? (
        <div className="bg-white border [&_svg]:w-full rounded p-4">
          <ReleaseDetailsLoader />
          <ReleaseDetailsLoader />
          <ReleaseDetailsLoader />
        </div>
      ) : (
        releases.map((release) => (
          <ReleaseDetailItem
            key={release.id}
            release={release}
            setSelectedRelease={setSelectedRelease}
            className="release-item"
          />
        ))
      )}
    </div>
  )
}
