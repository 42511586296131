import clsx from 'clsx'

export default function MainStatus({
  status,
}: Readonly<{ status?: 'UP' | 'DOWN' }>) {
  return (
    <div className="px-8 py-6 border-r flex flex-col">
      <p className="text-base text-primary-dark text-center">État actuel</p>
      <div className="px-20 h-full items-center flex">
        <div
          className={clsx(
            status === 'UP'
              ? 'bg-success shadow-success'
              : 'bg-secondary shadow-secondary',
            'w-40 h-40  shadow-[0px_0px_20px_0px]  rounded-[50%] flex justify-center items-center text-white font-semibold text-2xl'
          )}
        >
          {status === 'UP' ? 'En ligne' : 'Hors ligne'}
        </div>
      </div>
      <p className="text-sm text-neutral-darker/60 text-center">
        Vérification toutes les minutes
      </p>
    </div>
  )
}
