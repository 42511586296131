const ClipboardCopyIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1738_17555)">
        <path
          d="M13.3333 5.33301H6.66659C5.93021 5.33301 5.33325 5.92996 5.33325 6.66634V13.333C5.33325 14.0694 5.93021 14.6663 6.66659 14.6663H13.3333C14.0696 14.6663 14.6666 14.0694 14.6666 13.333V6.66634C14.6666 5.92996 14.0696 5.33301 13.3333 5.33301Z"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66659 10.6663C1.93325 10.6663 1.33325 10.0663 1.33325 9.33301V2.66634C1.33325 1.93301 1.93325 1.33301 2.66659 1.33301H9.33325C10.0666 1.33301 10.6666 1.93301 10.6666 2.66634"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1738_17555">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClipboardCopyIcon
