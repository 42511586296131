const MixedChartLegend = () => {
  return (
    <div className="flex flex-col gap-6">
      <div>
        <p className="text-primary-dark text-sm inline-flex items-center gap-1">
          <span className="text-secondary font-bold text-3xl">-</span>
          <span>Nombre de développeurs</span>
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-dark text-sm inline-flex items-center gap-1 ">
          <span className="-mt-1 w-2 h-2 rounded-[2px] bg-primary-light inline-block"></span>
          <span>Développé</span>
        </p>
        <p className="text-neutral-darker text-xs font-normal">
          TO DO et IN PROGRESS
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-dark text-sm inline-flex items-center gap-1">
          <span className="-mt-1 w-2 h-2 rounded-[2px] bg-average inline-block"></span>
          <span> À tester</span>
        </p>
        <p className="text-neutral-darker text-xs font-normal">
          TO CORRECT, DEV TESTING, PO TESTING et CLIENT TESTING
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-dark text-sm inline-flex items-center gap-1">
          <span className="-mt-1 w-2 h-2 rounded-[2px] bg-success inline-block"></span>
          <span>Terminé</span>
        </p>
        <p className="text-neutral-darker text-xs font-normal">
          TO DEPLOYED, DEPLOYED et DONE
        </p>
      </div>
    </div>
  )
}

export default MixedChartLegend
