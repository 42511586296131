const Paperclip: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8666 9.20855L10.2082 16.8669C9.27005 17.8051 7.99757 18.3322 6.67075 18.3322C5.34393 18.3322 4.07145 17.8051 3.13325 16.8669C2.19505 15.9287 1.66797 14.6562 1.66797 13.3294C1.66797 12.0026 2.19505 10.7301 3.13325 9.79188L10.2749 2.65021C10.9004 2.02364 11.7491 1.67119 12.6345 1.67041C13.5198 1.66963 14.3692 2.02058 14.9957 2.64604C15.6223 3.27151 15.9748 4.12027 15.9755 5.0056C15.9763 5.89093 15.6254 6.7403 14.9999 7.36688L7.84158 14.5085C7.52885 14.8213 7.10469 14.997 6.66242 14.997C6.22014 14.997 5.79598 14.8213 5.48325 14.5085C5.17051 14.1958 4.99482 13.7717 4.99482 13.3294C4.99482 12.8871 5.17051 12.4629 5.48325 12.1502L12.5582 5.08354"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Paperclip
