import axiosClient from '@/configs/axios'

interface IContactPayload {
  project: string
  email: string
  last_name: string
  first_name: string
}
export function addCollaborator(payload: IContactPayload) {
  return axiosClient.post('/contacts', payload)
}
