const MemoryIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 20V17"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 20V17"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20V17"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20V17"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12V10"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12V10"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V10"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16H22"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8C2 7.46957 2.21071 6.96086 2.58579 6.58579C2.96086 6.21071 3.46957 6 4 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V9.1C21.5858 9.22202 21.2222 9.47483 20.9635 9.82063C20.7049 10.1664 20.5651 10.5867 20.5651 11.0185C20.5651 11.4503 20.7049 11.8706 20.9635 12.2164C21.2222 12.5622 21.5858 12.815 22 12.937V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V12.9C2.41423 12.778 2.77784 12.5252 3.03648 12.1794C3.29512 11.8336 3.43488 11.4133 3.43488 10.9815C3.43488 10.5497 3.29512 10.1294 3.03648 9.78363C2.77784 9.43783 2.41423 9.18502 2 9.063V8Z"
        stroke="#1F73E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MemoryIcon
