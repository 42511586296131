import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { addCollaborator } from '@/services/api-client'
import { LoaderCircleIcon } from 'lucide-react'

const formSchema = z.object({
  firstName: z.string().min(1, {
    message: 'Le prénom ne peut pas être vide',
  }),
  lastName: z.string().min(1, {
    message: 'Le nom ne peut pas être vide',
  }),
  email: z.string().email({
    message: 'Veuillez entrer une adresse email valide',
  }),
})
interface IUserProfile {
  id: string
  profile_picture: string
  availability: string
}

interface IContactResponse {
  id: number
  email: string
  first_name: string
  last_name: string
  user_profile: IUserProfile
}

interface IInvitationFormProps {
  closeModal: () => void
  onSuccess: (response: IContactResponse) => void
}

const TeamInvitationForm = ({
  closeModal,
  onSuccess,
}: IInvitationFormProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsLoading(true)
      const payload = {
        project: '56c7588b-e7f1-4f39-93a7-3f55582debfb',
        email: values.email,
        last_name: values.lastName,
        first_name: values.firstName,
      }
      const { data, status } = await addCollaborator(payload)
      if (status === 201) {
        onSuccess(data)
        closeModal()
      }
    } catch (error) {
      throw new Error(`Unexpected error adding member: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="w-full max-w-2xl mx-auto ">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4 px-6">
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-primary-dark text-sm">
                    Nom
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Ex : John"
                      className="text-neutral-darker"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-primary-dark text-sm">
                    Prénom
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Ex : Doe"
                      className="text-neutral-darker"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="p-6 mt-0">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-primary-dark text-sm">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="text-neutral-darker"
                      type="email"
                      placeholder="Ex : john@example.com"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Separator className="my-6" />

          <div className="flex justify-end gap-4 p-6">
            <Button type="submit" disabled={isLoading}>
              Envoyer l'invitation
              {isLoading && <LoaderCircleIcon className="animate-spin" />}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default TeamInvitationForm
