import { getChat } from '@/services/api-client'
import { useEffect, useState } from 'react'
import { IChatSession } from '@/types/IChatSession'
import ChatView from './ChatView'
import { getContacts } from '@/services/contact.service'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import { getNameAbbreviation } from '@/lib/utils'
import { useAtom } from 'jotai'
import { ContactAtom } from '@/atom/contacts'
import { LoaderCircleIcon } from 'lucide-react'
import EmptyData from '../empty-data'

export default function Chat({
  toggleContactView,
}: Readonly<{
  toggleContactView: (v: boolean) => void
}>) {
  const [_, setContactAtom] = useAtom(ContactAtom)
  const [isLoadChat, setIsLoadChat] = useState(true)
  const [chatData, setChatData] = useState<IChatSession>()
  const [isChatUnvailable, setIsChatUnvailable] = useState(false)
  const [contacts, setContacts] = useState<
    { firstName: string; lastName: string; avatar: string }[]
  >([])
  const [contactsCount, setContactsCount] = useState(0)
  async function getChatContent() {
    try {
      const { data }: { data: IChatSession[] } = await getChat()
      const [apiChat] = data
      setChatData(apiChat)
    } catch (error) {
      setIsChatUnvailable(true)
    } finally {
      setIsLoadChat(false)
    }
  }

  async function getContactsList() {
    interface IContactRes {
      id: number
      email: string
      first_name: string
      last_name: string
      user_profile: {
        id: string
        profile_picture: string
        availability: string
      }
    }
    try {
      const { data }: { data: { client: IContactRes[], product: IContactRes[] } } = await getContacts()
      setContacts(() =>
        data.client.map((item) => ({
          avatar: item.user_profile.profile_picture,
          firstName: item.first_name,
          lastName: item.last_name,
          availability: item.user_profile.availability,
        }))
      )
      setContactsCount(data.client.length)
    } catch (error) {
      throw new Error('An error occured')
    }
  }

  const displayContacts = () => {
    setContactAtom(contacts)
    toggleContactView(true)
  }
  useEffect(() => {
    getContactsList()
    getChatContent()
  }, [])

  if (isLoadChat) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }
  if (isChatUnvailable) {
    return <div className='h-full'>
      <EmptyData msg="Le chat n'est pas disponible sur votre projet" />
    </div>
  }
  return (
    <div className="w-full pt-0 pr-6 ">
      <div className="bg-[#E5E7EB] h-12 flex items-center px-4 justify-end text-primary-dark">
        <div className="flex items-center gap-x-8">
          <span>Interlocuteurs: </span>
          <button onClick={displayContacts} className="flex items-center">
            {contacts.slice(0, 6).map((item, index) => (
              <div key={`${item.avatar}${index}`} className="-ml-5">
                <Avatar className="w-8 h-auto border">
                  <AvatarImage className="bg-white" src={item.avatar} />
                  <AvatarFallback>
                    {getNameAbbreviation(`${item.firstName} ${item.lastName}`)}
                  </AvatarFallback>
                </Avatar>
              </div>
            ))}
          </button>
        </div>
        {contactsCount > 10 ? (
          <span className="pl-2 text-neutral-darker/60">+ 10</span>
        ) : (
          ''
        )}
      </div>
      <div className="h-[calc(100vh-6.5rem)] overflow-y-auto">
        <ChatView
          url={chatData?.instance_url!}
          chatID={chatData?.chat_id!}
          accessToken={chatData?.weavy_user_token!}
        />
      </div>
    </div>
  )
}
