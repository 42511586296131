import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { IPerformanceAdvice } from '@/types/IPerformances'
import clsx from 'clsx'

export default function MetricDetailsByTags({
  filteredAdvice,
}: Readonly<{ filteredAdvice: IPerformanceAdvice[] }>) {
  function handleScore(value: number) {
    if (value >= 0 && value < 50) {
      return 'bg-secondary'
    } else if (value >= 50 && value < 90) {
      return 'bg-[#FA8334]'
    } else {
      return 'bg-success'
    }
  }
  return (
    <Accordion className="border rounded" type="single" collapsible>
      {filteredAdvice.map((item) => (
        <AccordionItem key={item.id} value={item.id}>
          <AccordionTrigger className="bg-neutral-dark/40 text-primary-dark text-sm font-normal flex-row-reverse hover:no-underline justify-end gap-x-4 px-4 border-b">
            <div className="px-4 flex items-center gap-4">
              <div
                className={clsx(
                  ' flex-[0_0_auto] h-2 w-2 rounded-[50%]',
                  handleScore(item.score)
                )}
              />
              <p className="text-sm text-left"> {item.advice}</p>
            </div>
          </AccordionTrigger>
          <AccordionContent className="px-4 pt-4 text-primary-dark text-sm">
            <p className="font-bold pb-4">{item.advice}</p>
            <p>{item.description}</p>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
