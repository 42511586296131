import { useAtom } from 'jotai'
import InterlocutorItem from '../dashboard/InterlocutorItem'
import CrossIcon from '../icons/CrossIcon'
import { ContactAtom } from '@/atom/contacts'

export default function ChatMemebers({
  toggleContactView,
}: Readonly<{ toggleContactView: (v: boolean) => void }>) {
  const [contacts] = useAtom(ContactAtom)
  return (
    <div>
      <div className="flex text-primary-dark justify-between px-8 py-6 border-b">
        Membre{' '}
        <button onClick={() => toggleContactView(false)}>
          <CrossIcon />
        </button>
      </div>
      <div className="flex flex-col py-6 px-8 gap-y-6 h-[calc(100vh-8rem)] overflow-y-auto">
        {contacts.map((item, index) => (
          <div key={`${item.avatar}${index}`}>
            <InterlocutorItem
              firstname={item.firstName}
              image={item.avatar}
              status={item.availability}
              position={''}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
