const TeamIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0003 17.5C15.0003 15.7319 14.2979 14.0362 13.0477 12.786C11.7975 11.5358 10.1018 10.8334 8.33366 10.8334C6.56555 10.8334 4.86986 11.5358 3.61961 12.786C2.36937 14.0362 1.66699 15.7319 1.66699 17.5"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33366 10.8333C10.6348 10.8333 12.5003 8.96785 12.5003 6.66667C12.5003 4.36548 10.6348 2.5 8.33366 2.5C6.03247 2.5 4.16699 4.36548 4.16699 6.66667C4.16699 8.96785 6.03247 10.8333 8.33366 10.8333Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 16.6667C18.3333 13.8584 16.6667 11.25 15 10C15.5478 9.58902 15.9859 9.04928 16.2755 8.4286C16.565 7.80792 16.6971 7.12543 16.66 6.44154C16.6229 5.75765 16.4178 5.09345 16.0629 4.50771C15.7079 3.92197 15.2141 3.43276 14.625 3.08337"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TeamIcon
