import axiosClient from '@/configs/axios'

export function sendRefusedTicketComments(
  issueId: string,
  payload: { content: string }
) {
  return axiosClient.post(`/comments/${issueId}`, payload)
}
export function getRequestComments(requestID: string) {
  return axiosClient.get(`/requests/${requestID}/comments`)
}
export function addRequestComments(payload: {
  request_id: string
  content: string
}) {
  return axiosClient.post(`/requests/${payload.request_id}/comments`, payload)
}

export function getIssueComments(issueID: string) {
  return axiosClient.get(`/comments/${issueID}`)
}
export function addIssueComments(payload: {
  issueID: string
  content: string
}) {
  return axiosClient.post(`/comments/${payload.issueID}`, payload)
}

