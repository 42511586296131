
import { IApplicationStatus } from '@/types/IAppStatus'
import MainStatus from './MainStatus'
import Config from './Config'

export default function GeneralInformations({
  generalInfo,
}: Readonly<{
  generalInfo: IApplicationStatus
}>) {
  return (
    <div className="flex">
      <MainStatus status={generalInfo.status} />
      <Config config={generalInfo} />
    </div>
  )
}
