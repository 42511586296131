import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import EvolutionTooltip from './EvolutionTooltip'

const RatingChart = ({ data }: any) => {
  return (
    <div className="h-[325px] w-full">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1F73E0" stopOpacity={0.3} />
              <stop offset="90%" stopColor="#1F73E0" stopOpacity={0.1} />
            </linearGradient>
            <linearGradient id="orangeGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FA8334" stopOpacity={0.3} />
              <stop offset="90%" stopColor="#FA8334" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#F6F6F6" strokeWidth={1.5} />

          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            fontSize={12}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            fontSize={12}
            tickCount={11}
          />
          <Tooltip content={<EvolutionTooltip />} />

          {/* Blue Area */}
          <Area
            type="monotone"
            dataKey="remainingPO"
            stroke="#1F73E0"
            fill="url(#blueGradient)"
          />

          {/* Orange Area */}
          <Area
            type="monotone"
            dataKey="remainingDev"
            stroke="#FA8334"
            fill="url(#orangeGradient)"
          />

          {/* Grey Line */}
          <Line
            type="monotone"
            dataKey="directive"
            stroke="#B3B3B3"
            strokeWidth={1}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default RatingChart
