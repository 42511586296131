import { Datapoint, IPerformance } from '@/types/IPerformances'
import CircleChart from './chart/CircleChart'
import { useEffect, useState } from 'react'

interface Props {
  selectedPage: IPerformance
}
export default function PerformancesDesc({ selectedPage }: Readonly<Props>) {
  const [stats, setStats] = useState<{
    bestPractice: number
    performance: number
    accessibility: number
  }>()

  function computedAverage(data: Datapoint[]) {
    let average = 0
    if (data?.length) {
      const values = data.map((item) => item[0])
      average = Math.round(
        values.reduce((sum, value) => sum + value, 0) / values.length
      )
    }
    return average
  }

  function handleAverage() {
    enum TARGET_KEY {
      BEST_PRACTICE = 'Bonnes pratiques',
      PERFORMANCE = 'Performances',
      ACCESSIBILITY = 'Vie privée',
    }
    const bestPracticeData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.BEST_PRACTICE
    )
    const performanceData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.PERFORMANCE
    )
    const accessibilityData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.ACCESSIBILITY
    )
    setStats({
      accessibility: computedAverage(accessibilityData?.datapoints!),
      bestPractice: computedAverage(bestPracticeData?.datapoints!),
      performance: computedAverage(performanceData?.datapoints!),
    })
  }
  useEffect(() => {
    handleAverage()
  }, [selectedPage])
  return (
    <div className="grid grid-cols-3 text-primary-dark">
      <div className="px-8 py-6 border-r last:border-l-0">
        <div className="-ml-2 [&>div]:w-[78px] [&>div]:h-[78px]">
          {stats?.bestPractice && (
            <CircleChart value={stats?.bestPractice} size="sm" />
          )}
        </div>
        <div>
          <p className="text-base font-semibold">Bonnes pratiques</p>
          <p className="text-neutral-darker/60 text-sm">
            La page web est adaptée aux moteurs de recherche et possède une
            bonne structure d'URL
          </p>
        </div>
      </div>
      <div className="px-8 py-6 border-r last:border-l-0">
        <div className="-ml-2 [&>div]:w-[78px] [&>div]:h-[78px]">
          {stats?.performance && (
            <CircleChart value={stats?.performance} size="sm" />
          )}
        </div>
        <div>
          <p className="text-base font-semibold">Performances</p>
          <p className="text-neutral-darker/60 text-sm">
            Le score de performance correspond à la moyenne pondérée des scores
            de métriques
          </p>
        </div>
      </div>
      <div className="px-8 py-6 border-r last:border-l-0">
        <div className="-ml-2 [&>div]:w-[78px] [&>div]:h-[78px]">
          {stats?.accessibility && (
            <CircleChart value={stats?.accessibility} size="sm" />
          )}
        </div>
        <div>
          <p className="text-base font-semibold">Accessibilité</p>
          <p className="text-neutral-darker/60 text-sm">
            Assure que votre site est accessible et utilisable par tous
          </p>
        </div>
      </div>
    </div>
  )
}
