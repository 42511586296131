import { TicketAtom } from '@/atom/ticket'
import { Badge } from '../ui/badge'
import { extractStringEpic, generateRandomAvatarColor } from '@/lib/utils'
import { TicketType } from '@/types/ITicket'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { MessageCircleMore } from 'lucide-react'

export default function TicketCard({
  ticketDetails,
}: Readonly<{ ticketDetails: TicketType }>) {
  const setTicketAtom = useSetAtom(TicketAtom)

  function daysBetween(_dateToCompare: Date) {
    const today = new Date().getTime()
    const dateToCompare = new Date(_dateToCompare).getTime()
    const differenceMs = Math.abs(dateToCompare - today)
    return Math.ceil(differenceMs / (1000 * 60 * 60 * 24))
  }
  const handleCommentIconBg = (
    comments: TicketType['fields']['comment']['comments']
  ) => {
    const commentsDateList = comments.map((item) => item.updated)
    for (let _date of commentsDateList) {
      const tmpDate = new Date(_date)
      if (daysBetween(tmpDate) <= 3) return 'bg-yellow'
      return 'bg-neutral-dark'
    }
  }

  const handleClickCard = () => {
    setTicketAtom(ticketDetails)
  }
  return (
    <div
      aria-hidden
      onClick={handleClickCard}
      className="border rounded-md px-8 hover:bg-light hover:border-neutral-darker/60 transition-all hover:cursor-pointer py-6 bg-white text-primary-dark"
    >
      <Badge
        className="uppercase shadow-none rounded-md px-2 py-1"
        style={{
          backgroundColor: generateRandomAvatarColor(
            ticketDetails?.fields.parent?.fields.summary ||
              'N/A' + 'offset_color',
            0.15
          ).bgColor,
          color: generateRandomAvatarColor(
            ticketDetails?.fields.parent?.fields.summary ||
              'N/A' + 'offset_color'
          ).bgColor,
        }}
      >
        <p>
          {extractStringEpic(ticketDetails.fields?.parent?.fields.summary) ||
            'N/A'}
        </p>
      </Badge>
      <p className="pt-2">{ticketDetails.fields.summary}</p>
      <div className="flex items-center justify-between pt-2">
        <span className="text-sm text-neutral-darker/60">
          {ticketDetails.key}
        </span>
        <div className="flex items-center gap-x-2">
          <img
            className="w-5 h-5"
            alt={ticketDetails.fields.priority.name}
            src={ticketDetails.fields.priority.iconUrl}
            title={ticketDetails.fields.priority.name}
          />
          <div className="relative">
            <MessageCircleMore className="stroke-1" />
            {Number(ticketDetails.fields.comment.total) ? (
              <span
                className={clsx(
                  handleCommentIconBg(ticketDetails.fields.comment.comments),
                  'absolute text-neutral-darker -top-1 -right-1 text-xs rounded-md w-4 h-4 flex items-center justify-center'
                )}
              >
                {ticketDetails.fields.comment.total}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
