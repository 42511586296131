import { useMemo } from 'react'
import CustomRating from './CustomRating'
import SatisfactionTopicItem from './SatisfactionTopicItem'

export interface IAverage {
  communication: number
  understanding: number
  reactivity: number
  deliverables: number
  deadlines: number
  overall: number
}

interface ISidebarRatingProps {
  averages: IAverage
  className?: string
}

const SATISFACTION_TOPICS = [
  {
    key: 'communication',
    label: 'Communication',
    chipBgClass: 'bg-primary-light',
  },
  {
    key: 'understanding',
    label: 'Conseil et enjeux',
    chipBgClass: 'bg-average',
  },
  {
    key: 'reactivity',
    label: 'Réactivité et anticipation',
    chipBgClass: 'bg-third',
  },
  {
    key: 'deliverables',
    label: 'Qualité des livrables',
    chipBgClass: 'bg-secondary',
  },
  {
    key: 'deadlines',
    label: 'Respect des délais',
    chipBgClass: 'bg-primary-dark',
  },
] as const

const SidebarRating: React.FC<ISidebarRatingProps> = ({
  averages,
  className = '',
}) => {
  const topicItems = useMemo(
    () =>
      SATISFACTION_TOPICS.map(({ key, label, chipBgClass }) => (
        <SatisfactionTopicItem
          key={key}
          topic={label}
          rating={averages[key as keyof Omit<IAverage, 'overall'>]}
          chipBgClass={chipBgClass}
          data-testid={`satisfaction-topic-${key}`}
        />
      )),
    [averages]
  )

  return (
    <div className={className}>
      <div className="w-full bg-success py-6">
        <CustomRating
          size={20}
          rating={averages.overall}
          data-testid="overall-rating"
        />
      </div>

      <div className="px-8 py-6">
        <h4 className="font-bold text-base text-primary-dark pb-6">
          Par thèmes
        </h4>
        <ul className="flex flex-col gap-6">{topicItems}</ul>
      </div>
    </div>
  )
}

export default SidebarRating
