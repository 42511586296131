import InformationIcon from '@/components/icons/Information'

const ContactProduct = () => {
  return (
    <div className="inline-flex gap-2 p-2 bg-primary-light/5 items-center">
      <div className="rounded bg-primary-light/10 [&_path]:stroke-primary-light w-8 h-8 inline-flex items-center justify-center ">
        <InformationIcon />
      </div>
      <div className="text-primary-dark text-sm">
        Si vous souhaitez modifier l'e-mail ou le nom d'affichage,{' '}
        <span className="text-primary-light underline">
          contactez votre Product Owner
        </span>
      </div>
    </div>
  )
}

export default ContactProduct
