import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { useState } from 'react'
import { postProfile } from '@/services/api-client'

const MyNotifications = () => {
  const [activityReport, setActivityReport] = useState(false)
  const [ticketNotification, setTicketNotification] = useState(false)

  const handleActivityChange = async (checked: boolean) => {
    setActivityReport(checked)
    const payload = { subscribed_to_reminders: checked }
    await sendData(payload)
  }

  const handleTicketChange = async (checked: boolean) => {
    setTicketNotification(checked)
    const payload = { subscribed_to_emails: checked }
    await sendData(payload)
  }

  const sendData = async (payload: any) => {
    try {
      await postProfile(payload)
    } catch (error) {
      throw new Error(`Unexpected error ${error}`)
    }
  }

  return (
    <div className="space-y-4">
      <h3 className="text-2xl text-primary-dark mb-6">Mes notifications</h3>
      <div>
        <div className="mb-4">
          <h4 className="text-primary-dark text-base mb-2">
            Compte rendu d'activité quotidienne
          </h4>
          <div className="flex justify-between items-start">
            <Label
              htmlFor="activity"
              className="text-xs text-neutral-darker w-1/2"
            >
              Recevoir des compte rendu des activités quotidienne lié aux
              tickets à tester et aux demandes par mail
            </Label>
            <Switch
              id="activity"
              checked={activityReport}
              onCheckedChange={handleActivityChange}
            />
          </div>
        </div>
        <div>
          <h4 className="text-primary-dark text-base mb-2">
            Recevoir une notification pour chaque activité
          </h4>
          <div className="flex justify-between items-start">
            <Label
              htmlFor="ticket"
              className="text-xs text-neutral-darker w-1/2"
            >
              Recevoir des notifications lié aux changement de statut des
              tickets à tester et aux demandes par mail
            </Label>
            <Switch
              id="ticket"
              checked={ticketNotification}
              onCheckedChange={handleTicketChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyNotifications
