import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { SprintReportType } from '@/pages/protected/sprint-report'

interface Props {
  sprintData: SprintReportType[]
  setCurrentSprint: (v: string) => void
}
const SprintNavigation = ({ sprintData, setCurrentSprint }: Props) => {
  const itemsPerPage = 8
  const [currentSprintIndex, setCurrentSprintIndex] = useState(0)
  const [currentPageStart, setCurrentPageStart] = useState(0)

  useEffect(() => {
    setCurrentSprint(sprintData[0].sprint)
  }, [sprintData.length])

  useEffect(() => {
    setCurrentSprint(sprintData[currentSprintIndex].sprint)
  }, [currentSprintIndex])


  const handlePrevSprint = () => {
    if (currentSprintIndex > 0) {
      const newIndex = currentSprintIndex - 1
      setCurrentSprintIndex(newIndex)

      // Shift view back if the selected sprint moves out of the current view range
      if (newIndex < currentPageStart) {
        setCurrentPageStart(currentPageStart - 1)
      }
    }
  }

  const handleNextSprint = () => {
    if (currentSprintIndex < sprintData.length - 1) {
      const newIndex = currentSprintIndex + 1
      setCurrentSprintIndex(newIndex)

      // Shift view forward if the selected sprint moves out of the current view range
      if (newIndex >= currentPageStart + itemsPerPage) {
        setCurrentPageStart(currentPageStart + 1)
      }
    }
  }

  // Slice the sprint data for the current view based on currentPageStart
  const displayedSprints = sprintData.slice(
    currentPageStart,
    currentPageStart + itemsPerPage
  )

  return (
    <div className="flex flex-col items-center gap-2 mb-4">
      <div className="flex items-center justify-center gap-2">
        <Button
          variant="outline"
          size="icon"
          className="w-8 h-8"
          onClick={handlePrevSprint}
          disabled={currentSprintIndex === 0}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        {displayedSprints.map((sprint: any, index: number) => (
          <Button
            className="px-6 py-4 h-12"
            key={sprint.sprint}
            variant={
              currentSprintIndex === currentPageStart + index
                ? 'default'
                : 'outline'
            }
            onClick={() => setCurrentSprintIndex(currentPageStart + index)}
          >
            {sprint.sprint}
          </Button>
        ))}
        <Button
          className="w-8 h-8"
          variant="outline"
          size="icon"
          onClick={handleNextSprint}
          disabled={currentSprintIndex === sprintData.length - 1}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default SprintNavigation
