const VulnerabilityIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 10.8331C16.6666 14.9997 13.7499 17.0831 10.2833 18.2914C10.1017 18.3529 9.90453 18.35 9.72492 18.2831C6.24992 17.0831 3.33325 14.9997 3.33325 10.8331V4.99972C3.33325 4.77871 3.42105 4.56675 3.57733 4.41047C3.73361 4.25419 3.94557 4.16639 4.16659 4.16639C5.83325 4.16639 7.91658 3.16639 9.36658 1.89972C9.54313 1.74889 9.76771 1.66602 9.99992 1.66602C10.2321 1.66602 10.4567 1.74889 10.6333 1.89972C12.0916 3.17472 14.1666 4.16639 15.8333 4.16639C16.0543 4.16639 16.2662 4.25419 16.4225 4.41047C16.5788 4.56675 16.6666 4.77871 16.6666 4.99972V10.8331Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.66602V9.99935"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.334H10.0083"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VulnerabilityIcon
