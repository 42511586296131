const CheckIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0002 37.1667C29.2049 37.1667 36.6668 29.7048 36.6668 20.5C36.6668 11.2953 29.2049 3.83337 20.0002 3.83337C10.7954 3.83337 3.3335 11.2953 3.3335 20.5C3.3335 29.7048 10.7954 37.1667 20.0002 37.1667Z"
        stroke="#0BB54F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 20.5L18.3333 23.8333L25 17.1666"
        stroke="#0BB54F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckIcon
