import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useState } from 'react'

interface ISprintFilterProps {
  onChange?: (value: number) => void
}

const SprintFilter: React.FC<ISprintFilterProps> = ({ onChange }) => {
  const [selectedRange, setSelectedRange] = useState<string>('3')

  const handleValueChange = (value: string) => {
    setSelectedRange(value)
    if (onChange) {
      onChange(+value)
    }
  }

  return (
    <div className="flex items-center gap-4">
      <span className="text-base text-primary-dark font-normal">
        Plage de sprint:
      </span>
      <Select value={selectedRange} onValueChange={handleValueChange}>
        <SelectTrigger className="w-44 h-7" isClassic>
          <SelectValue placeholder="Select range" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="3">3 derniers sprints</SelectItem>
          <SelectItem value="5">5 derniers sprints</SelectItem>
          <SelectItem value="8">8 derniers sprints</SelectItem>
          <SelectItem value="10">10 derniers sprints</SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}

export default SprintFilter
