import { useState } from 'react'
import clsx from 'clsx'
import FolderIcon from '@/components/icons/folder'

const ACTIVE_TAB_CLASS = 'bg-white border-b-4 border-primary-light'

interface TabItem {
  id: string | number
  label: string
}

interface CustomTabsProps {
  items: TabItem[]
  onChange: (index: number) => void
  activeIndex?: number
  className?: string
}

const RepositoryTabs: React.FC<CustomTabsProps> = ({
  items,
  onChange,
  activeIndex = 0,
  className = '',
}) => {
  const [selectedIndex, setSelectedIndex] = useState(activeIndex)

  const handleTabChange = (index: number) => {
    setSelectedIndex(index)
    onChange(index)
  }

  return (
    <div
      className={clsx(
        'inline-flex items-center bg-neutral-dark rounded-t-lg border border-neutral-dark overflow-hidden',
        className
      )}
    >
      {items.map((item, idx) => (
        <button
          key={item.id}
          className={clsx(
            'flex gap-4 py-4 px-6',
            idx === selectedIndex ? ACTIVE_TAB_CLASS : ''
          )}
          onClick={() => handleTabChange(idx)}
        >
          <FolderIcon />
          <span className="font-bold text-neutral-darker capitalize">
            {item.label}
          </span>
        </button>
      ))}
    </div>
  )
}

export default RepositoryTabs
