import InclinedArrow from '@/components/icons/InclinedArrow'
import { NavLink } from 'react-router-dom'

interface IUsefulinkItemProps {
  urlImage: string
  name: string
  link: string
}

const UsefulLinkItem: React.FC<IUsefulinkItemProps> = ({
  urlImage,
  name,
  link,
}) => {
  return (
    <NavLink to={link} target="_blank" rel="noopener noreferrer">
      <div className="border border-neutral-dark rounded p-2 transition duration-300 ease-in-out hover:bg-neutral-dark/50">
        <div className="flex flex-row gap-2">
          <figure className="max-w-6">
            <img
              src={urlImage}
              alt={name}
              className="object-cover max-w-full h-auto"
            />
          </figure>
          <div className="inline-flex justify-between w-full">
            <div className="text-primary-dark text-base font-semibold capitalize">
              {name}
            </div>
            <InclinedArrow />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default UsefulLinkItem
