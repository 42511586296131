import { useMemo } from 'react'
import { IIssue } from '@/types/ITicketToTest'

export const STATUS = {
  toTest: 'À tester Client',
  accepted: 'À déployer',
  refused: 'À tester PO',
}

interface TicketStats {
  currentTickets: IIssue[]
  toTest: number
  refused: number
  accepted: number
}

interface TicketAccumulator {
  [key: string]: number
}

export const useTicketStats = (ticketList: IIssue[]): TicketStats => {
  return useMemo(() => {
    const stats = ticketList.reduce<TicketAccumulator>((acc, item: IIssue) => {
      const status = item.fields.status.name
      acc[status] = (acc[status] || 0) + 1
      return acc
    }, {})

    return {
      currentTickets: ticketList.slice(0, 6),
      toTest: stats[STATUS.toTest] || 0,
      refused: stats[STATUS.refused] || 0,
      accepted: stats[STATUS.accepted] || 0,
    }
  }, [ticketList])
}
