import { CheckIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'

export const CATEGORY_LIST = [
  {
    value: 'Tâche',
    label: 'Tâche',
  },
  {
    value: 'Nouveauté',
    label: 'Nouveauté',
  },
  {
    value: 'Bug',
    label: 'Bug',
  },
  {
    value: 'Amélioration',
    label: 'Amélioration',
  },
  {
    value: 'Feedback',
    label: 'Feedback',
  },
  {
    value: 'Étude',
    label: 'Étude',
  },
  {
    value: 'Risque',
    label: 'Risque',
  },
]

export const LINK_TYPE_LIST = [
  'OTHER',
  'ENV',
  'FIGMA',
  'MIRO',
  'NOTIONS',
  'STATUS_PAGE',
  'DRIVE',
  'GITHUB',
  'GITLAB',
  'BITBUCKET',
  'AZURE_DO',
]

export const TOAST_ERROR_MSG: any = {
  description: (
    <div className="flex items-center gap-2 text-base">
      <ExclamationTriangleIcon width={28} height={28} />
      Une erreur est survenue!
    </div>
  ),
  variant: 'destructive',
}

export const toastSuccessMsg: any = (msg: string) => ({
  description: (
    <div className="flex items-center gap-2 text-base">
      <CheckIcon color="green" width={28} height={28} />
      {msg}
    </div>
  ),
})

export const TAB_LABELS = {
  TO_TEST: 'À tester',
  REFUSED: 'Refusés',
  ACCEPTED: 'Acceptés',
} as const

export const PRIORITY_STATUS: any = {
  Urgent: 'Highest',
  Forte: 'High',
  Moyenne: 'Medium',
  Faible: 'Low',
} as const

export const NOTIFICATION = {
  success: 'success',
  error: 'error',
  cancel: 'cancel',
} as const

export const TOOLTIP = {
  CPU: "Le CPU, ou processeur central, est l'unité de traitement clé d'un système informatique, agissant comme le cerveau du système. Il exécute rapidement les instructions et traite activement les données en cours d'utilisation, garantissant ainsi des opérations efficaces.",
  MEM: "La mémoire vive, ou RAM, est un espace de stockage conçu pour accéder à la mémoire à court terme. Elle est utilisée pour les informations actuellement en cours d'utilisation afin de garantir un accès rapide.",
  DISK: "L'espace disque disponible indique la capacité restante pour le stockage à long terme. Il est essentiel pour accueillir de nouveaux fichiers et maintenir le bon fonctionnement du système.",
  NET: 'La bande passante est une mesure de la quantité de trafic entrant ou sortant passant par les interfaces réseau du serveur.',
} as const
