import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import DashBoardLayout from '@/layouts/Dashboard'
import Dashboard from '@/pages/dashboard'
import SprintInProgress from '@/pages/sprint-in-progress'
import SprintPast from '@/pages/sprint-past'
import TimeTracking from '@/pages/time-tracking'
import TicketToTest from '@/pages/ticket-to-test'
import Backlog from '@/pages/backlog'
import Request from '@/pages/request'
import Monitoring from '@/pages/monitoring'
import FullScreenRequest from '@/pages/fullscreen/request/[id]'
import Release from '@/pages/release'
import ChatPage from '@/pages/chat'
import ProjectPage from '@/pages/project'
import Vulnerability from '@/pages/vulnerability'
import CodeQuality from '@/pages/code-quality'
import UnitTesting from '@/pages/unit-testing'
import AppStatus from '@/pages/app-status'
import PerformancesPage from '@/pages/performances'
import ErrorReports from '@/pages/error-reports'
import AuthGard from './guard/AuthGard'
import HasProjectGard from './guard/HasProjectGard'
import ClientSatisfaction from '@/pages/protected/client-satisfaction'
import SprintReport from '@/pages/protected/sprint-report'
import ProductOwnerAndDirectorGuard from './guard/ProductOwnerAndDirectorGuard'
import ClientSatisfactionStep from '@/pages/protected/client-satisfaction-step'
import { ROUTES } from '@/configs/route-naming'

const Routing: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.CLIENT.HOME} element={<AuthGard />}>
          <Route path={ROUTES.CLIENT.PROJECT} element={<ProjectPage />} />
          <Route path={ROUTES.CLIENT.HOME} element={<HasProjectGard />}>
            <Route path={ROUTES.CLIENT.HOME} element={<DashBoardLayout />}>
              <Route path={ROUTES.CLIENT.HOME} element={<Dashboard />} />
              <Route
                path={ROUTES.CLIENT.INPROGRESS_SPRINT}
                element={<SprintInProgress />}
              />
              <Route
                path={ROUTES.CLIENT.PAST_SPRINT}
                element={<SprintPast />}
              />
              <Route path={ROUTES.CLIENT.STAFFING} element={<TimeTracking />} />
              <Route
                path={ROUTES.CLIENT.TICKETS_TO_TEST}
                element={<TicketToTest />}
              />
              <Route path={ROUTES.CLIENT.BACKLOG} element={<Backlog />} />
              <Route path={ROUTES.CLIENT.REQUEST} element={<Request />} />
              <Route
                path={ROUTES.CLIENT.MONITORING_SERVER}
                element={<Monitoring />}
              />
              <Route
                path={ROUTES.CLIENT.REQUEST_DETAILS}
                element={<FullScreenRequest />}
              />
              <Route path={ROUTES.CLIENT.RELEASE} element={<Release />} />
              <Route path={ROUTES.CLIENT.CHAT} element={<ChatPage />} />
              <Route
                path={ROUTES.CLIENT.VULNERABILITY}
                element={<Vulnerability />}
              />
              <Route
                path={ROUTES.CLIENT.CODE_QUALITY}
                element={<CodeQuality />}
              />
              <Route path={ROUTES.CLIENT.UNIT_TEST} element={<UnitTesting />} />
              <Route
                path={ROUTES.CLIENT.ERROR_REPORT}
                element={<ErrorReports />}
              />
              <Route path={ROUTES.CLIENT.APP_STATUS} element={<AppStatus />} />
              <Route
                path={ROUTES.CLIENT.PERFORMANCE}
                element={<PerformancesPage />}
              />
              <Route
                path={ROUTES.CLIENT.HOME}
                element={<ProductOwnerAndDirectorGuard />}
              >
                <Route
                  path={ROUTES.PROTECTED.FEEDBACK}
                  element={<ClientSatisfaction />}
                />
                <Route
                  path={ROUTES.PROTECTED.ADD_FEEDBACK}
                  element={<ClientSatisfactionStep />}
                />
                <Route
                  path={ROUTES.PROTECTED.SPRINT_REPORT}
                  element={<SprintReport />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default Routing
