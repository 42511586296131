import axiosClient from '@/configs/axios'
import { v4 as uuid } from 'uuid'
import Cookies from 'js-cookie'
const projectID = Cookies.get('projectID') as string

export function getProjectRequest() {
  return axiosClient.get(`/project_requests/${projectID}`)
}

export function createRequest(payload: {
  title: string
  summary: string
  category: string
  environment: string
  files?: any
}) {
  if (payload.files) {
    ;(payload.files).id = uuid()
    const fileArr = []
    fileArr.push(payload.files)
    const formData = new FormData()
    const formattedData = {
      project: projectID,
      ...payload,
      files: fileArr,
    }
    Object.entries(formattedData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item)
        })
      } else {
        formData.append(key, value)
      }
    })
    return axiosClient.post(`/requests`, formData, {})
  }
  const {files, ...rest} = payload;
  return axiosClient.post(`/requests`, { ...rest, project: projectID })
}

export function editRequest(data: {
  title?: string
  summary?: string
  category?: string
  environment?: string
  id: string
  files?: File
  approve?: boolean
  reject?: boolean
  resolve?: boolean
}) {
  const { id, ...payload } = data
  if (payload.files) {
    ;(payload.files as any).id = uuid()
    const fileArr = []
    fileArr.push(payload.files)
    const formData = new FormData()
    const formattedData = {
      project: projectID,
      ...payload,
      files: fileArr,
    }
    Object.entries(formattedData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item)
        })
      } else {
        formData.append(key, value as any)
      }
    })
    return axiosClient.put(`/requests/${id}`, formData, {})
  }
  return axiosClient.put(`/requests/${id}`, { ...payload, project: projectID })
}

export function getRequestDetails(requestID: string) {
  return axiosClient.get(`/requests/${requestID}`)
}

export function deleteRequest(requestID: string) {
  return axiosClient.delete(`/requests/${requestID}`)
}