import { useEffect, useState } from 'react'
import { LoaderCircleIcon } from 'lucide-react'
import { Card, CardContent } from '@/components/ui/card'
import CustomCard from '@/components/ui/custom-card'
import HeadingTitle from '@/components/ui/heading-title'
import LineAreaChart from '@/components/dashboard/LineAreaChart'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import CoverageTable from '@/components/unit-testing/CoverageTable'
import EmptyData from '@/components/empty-data'
import { getCoverage } from '@/services/unit-testing.service'
import { BranchCoverageData } from '@/types/IUnitTesting'
import RepositoryTabs from '@/components/ui/repository-tab'

const UnitTesting: React.FC = () => {
  const [repositories, setRepositories] = useState<
    BranchCoverageData[] | null
  >()
  const [coverage, setCoverage] = useState<BranchCoverageData | null>()
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const prepareChartData = () => {
    if (!coverage) return []

    const totalPoints = 12

    if (coverage.coverage.length <= totalPoints) {
      return coverage?.coverage.map((item) => ({
        date: item.timestamp,
        coverage: item.coverage,
      }))
    }

    const step = Math.floor(coverage.coverage.length / totalPoints)
    const result = []

    for (let i = 0; i < totalPoints; i++) {
      const index = i * step
      result.push({
        date: coverage.coverage[index].timestamp,
        coverage: coverage.coverage[index].coverage,
      })
    }

    return result
  }

  const getDifferencePercentage = (): {
    value: string
    color: string
    background: string
  } => {
    if (coverage?.coverage?.length && coverage.coverage.length >= 2) {
      const { coverage: data } = coverage
      const previousCoverage = data[data.length - 2].coverage
      const latestCoverage = data[data.length - 1].coverage

      const differencePercentage =
        ((latestCoverage - previousCoverage) / previousCoverage) * 100

      const color = differencePercentage > 0 ? 'text-success' : 'text-secondary'
      const background =
        differencePercentage > 0 ? 'bg-success/10' : 'bg-secondary/10'
      const stringValue = `${differencePercentage > 0 ? '+' : ''}${differencePercentage.toFixed(2)}`
      return {
        value: isNaN(differencePercentage) ? '' : stringValue,
        color,
        background,
      }
    }
    return {
      value: '',
      color: '',
      background: '',
    }
  }

  const tabItems =
    repositories?.map((repo) => ({
      id: repo.branch,
      label: repo.branch,
    })) || []

  // Gestionnaire de changement d'onglet
  const handleTabChange = (index: number) => {
    if (repositories) {
      setCoverage(repositories[index])
    }
    setActiveIndex(index)
  }

  useEffect(() => {
    const getCoverageData = async () => {
      try {
        setIsLoading(true)
        const { data } = await getCoverage()
        setRepositories(data)
        setCoverage(data[0])
      } catch (error) {
        throw new Error(`Unexpected error fetching coverage date: ${error}`)
      } finally {
        setIsLoading(false)
      }
    }
    getCoverageData()
  }, [])

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <LoaderCircleIcon className="animate-spin" width={'32'} height={'32'} />
      </div>
    )
  }

  return (
    <div>
      <HeadingTitle label="Test unitaire" />
      {coverage ? (
        <>
          <RepositoryTabs
            items={tabItems}
            onChange={handleTabChange}
            activeIndex={activeIndex}
          />
          <div className="bg-white p-6 border border-neutral-dark">
            <div className="flex flex-col gap-6">
              <CustomCard title="Informations générales">
                {Array.isArray(coverage.report) && (
                  <div className="p-6">
                    <EmptyData />
                  </div>
                )}
                {!Array.isArray(coverage.report) && (
                  <div className="grid grid-cols-3 gap-4 px-8 pb-6 w-full">
                    <Card className="h-[154px] shadow-none">
                      <CardContent className="flex items-center justify-center h-full">
                        <div className="inline-flex items-center justify-center flex-col">
                          <div className="text-primary-dark text-3xl ">
                            {coverage?.report?.total?.coverage
                              ? Number(
                                  coverage?.report?.total?.coverage.toFixed(2)
                                )
                              : 0}

                            <p className=" inline text-neutral-darker text-xl relative">
                              %
                              {getDifferencePercentage().value && (
                                <span
                                  className={`rounded-full w-12 h-6
                   inline-flex items-center justify-center absolute -top-7 left-0 ${getDifferencePercentage()?.background}`}
                                >
                                  <span
                                    className={`text-xs ${getDifferencePercentage()?.color}`}
                                  >
                                    {getDifferencePercentage()?.value}%
                                  </span>
                                </span>
                              )}
                            </p>
                          </div>
                          <p className="text-neutral-darker text-sm">
                            de tests unitaire
                          </p>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="h-[154px] shadow-none">
                      <CardContent className="flex items-center justify-center h-full">
                        <div className="inline-flex items-center justify-center flex-col">
                          <div className="text-primary-dark text-3xl relative">
                            {coverage?.report?.total?.hits ?? 0}{' '}
                            <p className="inline text-neutral-darker text-xl relative">
                              sur {coverage?.report.total.lines ?? 0}
                            </p>
                          </div>
                          <p className="text-neutral-darker text-sm">
                            ligne de code couvert
                          </p>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="max-h-[385px] shadow-none">
                      <CardContent className="flex items-center justify-center h-full">
                        <div className="inline-flex items-center justify-center flex-col">
                          <p className="text-primary-dark text-4xl">
                            {coverage?.report.total.files ?? 0}
                          </p>
                          <p className="text-neutral-darker text-sm">
                            fichiers scannés
                          </p>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                )}
                {coverage.coverage.length > 2 && (
                  <div className="h-[430px] px-8 py-6">
                    <LineAreaChart
                      data={prepareChartData() ?? []}
                      hideAxis={false}
                      height="385"
                      showGrid={true}
                      referencePosition={55}
                    />
                  </div>
                )}
              </CustomCard>
              {!!coverage.report?.files?.length && (
                <CustomCard title="Couverture de tests unitaire par fichier">
                  <div className="px-8 pb-6">
                    <CoverageTable data={coverage.report.files} />
                  </div>
                </CustomCard>
              )}
            </div>
          </div>
        </>
      ) : (
        <MonitoringNoConfiguration
          context="Tests unitaires"
          urlImg="/img/no-config-server-monitoring.png"
          detailList={[
            "S'assurer que chaque partie du code est couverte par un ou plusieurs tests unitaires",
            "Suivre l'évolution de la couverture des tests dans le projet",
          ]}
        />
      )}
    </div>
  )
}

export default UnitTesting
