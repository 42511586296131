import RequestDetails from '@/components/request/request-details.tsx'
import ActionsTopFullscreen from '../ActionTop'
import { useParams, useOutletContext } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getRequestDetails } from '@/services/api-client'
import { useAtom } from 'jotai'
import { RequestAtom } from '@/atom/request'
import Comments from '@/components/commments/Comments'
import { LoaderCircleIcon } from 'lucide-react'

export default function FullScreenRequest() {
  const [requestAtom, setRequestAtom] = useAtom(RequestAtom)
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const { id } = useParams()
  const ajustParentPadding: any = useOutletContext()

  useEffect(() => {
    fetchRequestDetails()
    ajustParentPadding('pr-0')
    return () => {
      ajustParentPadding('')
    }
  }, [id])

  const fetchRequestDetails = async () => {
    setIsLoadingPage(true)
    try {
      const { data } = await getRequestDetails(id as string)
      setRequestAtom(data)
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadingPage(false)
    }
  }
  if (isLoadingPage) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <LoaderCircleIcon className="animate-spin" width={'32'} height={'32'} />
      </div>
    )
  }
  return (
    <div className="grid grid-cols-3 gap-x-6 h-full">
      <ActionsTopFullscreen
        ticketKey={id as string}
        ticketDetail={requestAtom}
      />
      <div className="relative col-span-2">
        <div className="mt-20 px-8 bg-white mb-6 rounded border border-neutral-dark">
          <div className="pb-6">
            <RequestDetails isFullScreen />
          </div>
        </div>
      </div>
      <div className="relative pt-20 px-8 h-[calc(100vh-4.25rem)] bg-white rounded border border-neutral-dark">
        <Comments requestId={requestAtom?.id ?? ''} />
      </div>
    </div>
  )
}
