import React from "react"
import ContentLoader, { IContentLoaderProps } from "react-content-loader"

const ProjectCardLoader = (props: IContentLoaderProps) => (
  <ContentLoader 
    speed={2}
    width={384}
    height={133}
    viewBox="0 0 384 133"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="2" y="55" rx="3" ry="3" width="52" height="6" /> 
    <rect x="0" y="69" rx="3" ry="3" width="132" height="6" /> 
    <rect x="1" y="92" rx="3" ry="3" width="218" height="15" /> 
    {/* <circle cx="22" cy="26" r="20" />  */}
    <rect x="0" y="14" rx="2" ry="2" width="26" height="26" />
    <rect x="2" y="114" rx="3" ry="3" width="218" height="15" />
  </ContentLoader>
)

export default ProjectCardLoader

