import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

const ProfilLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={300}
    height={40}
    viewBox="0 0 300 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="44" y="3" rx="4" ry="4" width="342" height="12" />
    <circle cx="20" cy="20" r="20" />
    <rect x="45" y="25" rx="4" ry="4" width="342" height="7" />
  </ContentLoader>
)

export default ProfilLoader
