import {
  Table as TableEl,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { IStaffingData } from '@/types/IStaffing'
import { useEffect, useState } from 'react'
import NotAllowedIcon from '../icons/NotAllowedIcon'
import clsx from 'clsx'
import { generateRandomAvatarColor, getNameAbbreviation } from '@/lib/utils'

function CustomNameAvatar({ rawValue }: Readonly<{ rawValue: string }>) {
  if (rawValue) {
    const { bgColor, textColor } = generateRandomAvatarColor(rawValue)
    const firstname = rawValue.split(' ')[0]
    return (
      <span className="text-primary-dark flex items-center gap-x-2 font-normal text-base">
        <span
          className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {getNameAbbreviation(rawValue)}
        </span>
        {firstname}
      </span>
    )
  } else {
    return null
  }
}
function PeriodRender({ period }: Readonly<{ period: string }>) {
  const MIN_LIMIT_OFFSET = 0.5
  const MAX_LIMIT_OFFSET = 1.5
  const [stringRender, setStringRender] = useState('')
  const [style, setStyle] = useState({
    text: 'text-secondary',
    bg: 'bg-secondary/10',
  })

  useEffect(() => {
    const [first, second] = period.split('/')
    const firstCount = Number(first)
    const secondCount = Number(second)
    if (!Number(first) && !Number(second)) {
      setStringRender('')
    } else {
      const offset = secondCount - firstCount
      if (offset <= MIN_LIMIT_OFFSET && firstCount !== 0) {
        setStyle({
          bg: 'bg-success/10',
          text: 'text-success',
        })
      } else if (
        offset > MIN_LIMIT_OFFSET &&
        offset <= MAX_LIMIT_OFFSET &&
        firstCount !== 0
      ) {
        setStyle({
          bg: 'bg-average/10',
          text: 'text-average',
        })
      } else if (firstCount === 0) {
        setStyle({
          bg: 'bg-neutral-dark',
          text: 'text-neutral-darker',
        })
      }
      setStringRender(
        `${Number(first).toString()}j / ${Number(second).toString()}j`
      )
    }
  }, [period])
  if (stringRender) {
    return (
      <span className={clsx(style.bg, style.text, 'py-1 px-3 rounded-full')}>
        {stringRender}
      </span>
    )
  }
  return <NotAllowedIcon className="w-4 h-4" />
}

interface Props {
  periodList: string[]
  userList: IStaffingData['users']
}
export default function Table({ periodList, userList }: Readonly<Props>) {
  const [currentFilter, setCurrentFilter] = useState('')
  const [filterList, setFilterList] = useState<string[]>([])
  const [currentUserList, setCurrentUserList] = useState<
    IStaffingData['users']
  >([])

  function getWeekNumber(date: Date) {
    const target = new Date(date.valueOf())
    target.setDate(target.getDate() + 4 - (target.getDay() || 7))
    const yearStart = new Date(target.getFullYear(), 0, 1) as any
    const weekNumber = Math.ceil(
      (((target as any) - yearStart) / 86400000 + 1) / 7
    )
    return weekNumber
  }

  useEffect(() => {
    setCurrentUserList(userList)
    setFilterList([...userList.map((item) => item.position)])
  }, [userList])

  useEffect(() => {
    setCurrentUserList(() =>
      userList.filter((item) => {
        if (currentFilter) {
          return item.position === currentFilter
        } else {
          return true
        }
      })
    )
  }, [currentFilter])

  return (
    <div className="text-primary-dark">
      <ul className="flex [&_li]:relative [&_li]:mr-4 [&_li]:hover:cursor-pointer mb-6 [&_li]:pb-3 border-b">
        <li
          className={!currentFilter ? 'border-b-2 border-b-primary-light' : ''}
          aria-hidden
          onClick={() => setCurrentFilter('')}
        >
          Tous
        </li>
        {filterList.map((item) => (
          <li
            onClick={() => setCurrentFilter(item)}
            className={
              currentFilter === item ? 'border-b-2 border-b-primary-light' : ''
            }
            aria-hidden
            key={item}
          >
            {item}
          </li>
        ))}
      </ul>
      <TableEl className="[&_*]:text-xs bg-white rounded overflow-hidden">
        <TableHeader>
          <TableRow className="bg-primary-dark [&_th]:text-white [&_th]:font-normal [&_th]:text-xs hover:bg-primary-dark">
            <TableHead
              className={
                currentFilter ? 'border-r-4 border-r-neutral-dark' : ''
              }
            >
              Profil
            </TableHead>
            {!currentFilter ? (
              <TableHead className="border-r-4 border-r-neutral-dark">
                Rôle
              </TableHead>
            ) : (
              ''
            )}
            {periodList.map((item) => (
              <TableHead
                className={clsx(
                  getWeekNumber(new Date()) === Number(item.split('W')[1])
                    ? 'bg-primary-light text-center'
                    : ''
                )}
                key={item}
              >
                {getWeekNumber(new Date()) === Number(item.split('W')[1])
                  ? 'Semaine actuelle'
                  : item}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="[&_td]:py-4 ">
          {currentUserList.map((user) => (
            <TableRow key={user.id}>
              <TableCell
                className={
                  currentFilter ? 'border-r-4 border-r-neutral-dark' : ''
                }
              >
                <CustomNameAvatar
                  rawValue={`${user.first_name} ${user.last_name}`}
                />
              </TableCell>
              {!currentFilter ? (
                <TableCell className="border-r-4 border-r-neutral-dark">
                  {user.position}
                </TableCell>
              ) : (
                ''
              )}
              {periodList.map((period) => (
                <TableCell
                  className={clsx(
                    getWeekNumber(new Date()) === Number(period.split('W')[1])
                      ? 'bg-primary-light/5'
                      : ''
                  )}
                  key={period}
                >
                  <PeriodRender period={user.staffing[period]} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableEl>
    </div>
  )
}
