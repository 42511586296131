import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
} from 'recharts'
import CustomTooltip from './CustomTooltip'

interface IChartData {
  date: string
  value: number
}
interface ICustomBarChartProps {
  data: IChartData[]
  showReferenceLine?: boolean
  legend?: string
  tooltip?: JSX.Element
  formatYHour?: boolean
  fullColor?: boolean
}

const CustomBarChart: React.FC<ICustomBarChartProps> = ({
  data,
  showReferenceLine = false,
  legend = '',
  fullColor,
  tooltip = <CustomTooltip legend={legend} />,
  formatYHour = false,
}) => {
  return (
    <div className="w-full h-[212px] relative">
      <ResponsiveContainer width="95%" height={212}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="toleranceGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(255, 222, 52, 0.2)" />
              <stop offset="80%" stopColor="rgba(255, 255, 255, 0.1)" />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#F6F6F6" strokeWidth={1.5} />
          <XAxis
            axisLine={false}
            tickLine={false}
            dataKey="date"
            tick={{ fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => (formatYHour ? `${value}h` : `${value}`)}
          />
          {showReferenceLine && (
            <ReferenceArea y1={0} y2={200} fill="url(#toleranceGradient)" />
          )}
          {showReferenceLine && (
            <ReferenceLine
              y={200}
              strokeDasharray="6 6"
              stroke="#FFDE34"
              strokeWidth={2}
            />
          )}
          <Tooltip content={tooltip} cursor={{ fill: 'transparent' }} />
          <Bar dataKey="value" radius={[6, 6, 6, 6]} barSize={48}>
            {data?.map((_, index) => (
              <Cell
                key={`cell-${JSON.stringify(data)}`}
                fill={index === data.length - 1 || fullColor ? '#1F73E0' : '#ECECEC'}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {showReferenceLine && (
        <div className="absolute right-2 top-[45%] -translate-y-[45%] transform">
          <div className="relative">
            <div className="text-primary-dark text-xs bg-third text-center rounded-sm h-6 inline-flex items-center justify-center px-3">
              Niv. de tolerance
              <div className="absolute -left-1 top-1/2 -translate-y-1/2 h-2 w-2 bg-third rotate-45" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomBarChart
