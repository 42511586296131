import useClientSatisfaction, {
  TIMELINE_DATA,
} from '@/atom/client-satisfaction'
import { TimelineLayout } from '@/components/timeline/timeline-layout'
import { cn } from '@/lib/utils'
import { CheckCircle2Icon } from 'lucide-react'
export default function SatisfactionTimeline() {
  const { mainStepState, currentMainStep } = useClientSatisfaction()
  const activeCheckIconStyle = 'size-6 fill-primary-light text-white'
  const unsetCheckIconStyle = 'size-5 text-neutral-darker/60'
  const pastCheckIconStyle = 'size-6 fill-primary-light/60 text-white'

  const handlePast = (curr: number) =>
    mainStepState.includes(curr) ? pastCheckIconStyle : unsetCheckIconStyle
  return (
    <div className="bg-white h-fit flex flex-col px-6 py-4 border rounded">
      <div className="-ml-2 inline-flex gap-x-2">
        <CheckCircle2Icon
          className={cn(
            currentMainStep === 0 ? activeCheckIconStyle : pastCheckIconStyle
          )}
        />{' '}
        Questionnaire
      </div>

      <TimelineLayout items={TIMELINE_DATA} />

      <div
        className={cn(
          mainStepState.includes(1) ? '-ml-[8px]' : '-ml-[6px]',
          'mt-1 inline-flex py-1 gap-x-2 items-center'
        )}
      >
        <CheckCircle2Icon
          className={cn(
            currentMainStep === 1 ? activeCheckIconStyle : handlePast(1)
          )}
        />{' '}
        Avis global sur le dernier sprint
      </div>
      <div className={cn(mainStepState.includes(1) ? "border-l-primary-light/50" : "border-l-neutral-darker/30","h-6 ml-[3px] border-l-2")} />
      <div
        className={cn(
          mainStepState.includes(2) ? '-ml-[8px]' : '-ml-[6px]',
          'inline-flex py-1 gap-x-2 items-center'
        )}
      >
        <CheckCircle2Icon
          className={cn(
            currentMainStep === 2 ? activeCheckIconStyle : handlePast(2)
          )}
        />{' '}
        Récaptiluatif
      </div>
    </div>
  )
}
