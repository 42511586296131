import UsefulLinkItem from '@/components/dashboard/UsefulLinkItem'
import { ScrollArea } from '@/components/ui/scroll-area'

interface Link {
  icon: string
  name: string
  url: string
  category?: string
}
const defaultLinkValue = [
  {
    category: 'GITHUB',
    name: 'Github',
    url: 'https://github.com/Hello-Pomelo/jira-client-view',
    icon: 'https://my-pomelo.fra1.digitaloceanspaces.com/assets/github.png',
  },
]
const UsefulLinks: React.FC<{ links: Link[] }> = ({
  links = defaultLinkValue,
}) => {
  return (
    <ScrollArea>
      <ul className="flex flex-col gap-4 max-h-[290px] ">
        {links.map((link, index) => (
          <li key={link.url}>
            <UsefulLinkItem
              urlImage={link.icon}
              name={link.name}
              link={link.url}
            />
          </li>
        ))}
      </ul>
    </ScrollArea>
  )
}

export default UsefulLinks
