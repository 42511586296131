import CrossIcon from '../icons/CrossIcon'
import WarningIcon from '../icons/WarningIcon'
import { Button } from '../ui/button'
import { DialogClose, DialogDescription, DialogTitle } from '../ui/dialog'

export default function CancelModalContent({
  onCancel,
  validationString = 'Ignorer le changement',
}: Readonly<{ onCancel: Function; validationString?: string }>) {
  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground ">
        <CrossIcon className="h-5 w-5" />
      </DialogClose>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="p-1 bg-secondary/10 rounded-md">
          <WarningIcon />
        </div>
        <p className="text-base font-semibold text-primary-dark">
          Vos changement ne seront pas enregistrés
        </p>
      </div>
      <p className="text-center my-6 text-primary-dark font-normal text-sm">
        Nous ne pourrons pas enregistrer vos données si vous quittez cette page.
      </p>
      <div className="flex justify-center gap-x-2">
        <DialogClose asChild>
          <Button className="bg-neutral-dark/30" variant={'outline'}>
            Retour
          </Button>
        </DialogClose>
        <Button onClick={() => onCancel()}>{validationString}</Button>
      </div>
    </div>
  )
}
