import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
  Cell,
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import { useState } from 'react'

const MixedChart = ({ data, selectedSprint }: any) => {
  const [activeBarIndex, setActiveBarIndex] = useState<number | null>(null)
  const [hoveredSprint, setHoveredSprint] = useState<string | null>(null)

  const handleMouseEnter = (index: number) => {
    setActiveBarIndex(index)
  }

  const handleMouseLeave = () => {
    setActiveBarIndex(null)
  }

  const getBarFill = (index: number, sprint: string, color: string) => {
    // Grey out bars if they're not in the selected sprint and are hovered
    if (selectedSprint && sprint !== selectedSprint) {
      return activeBarIndex === index ? '#ECECEC' : color
    }
    return color
  }

  return (
    <div className="w-full">
      <div className="h-[260px]">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#F6F6F6" strokeWidth={1.5} />
            <XAxis
              tickLine={false}
              axisLine={false}
              dataKey="sprint"
              tickMargin={8}
              fontSize={12}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              yAxisId="left"
              orientation="left"
              fontSize={12}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              yAxisId="right"
              orientation="right"
              domain={[0, 5]}
              tickCount={6}
              fontSize={12}
            />
            <Tooltip
              content={<CustomTooltip activeBarIndex={activeBarIndex} />}
              filterNull={true}
            />
            {selectedSprint && (
              <ReferenceLine
                x={selectedSprint}
                stroke="rgba(31, 115, 224, 0.2)"
                strokeWidth={125}
                label={{
                  position: 'top',
                  value: 'Sprint sélectionné',
                  fill: '#1F73E0',
                  fontSize: 12,
                }}
                yAxisId="left"
              />
            )}
            <Line
              yAxisId="right"
              type="step"
              dataKey="developers"
              name="Nombre de développeurs"
              stroke="#D9306B"
              strokeWidth={1}
              dot={false}
            />
            <Bar
              yAxisId="left"
              dataKey="developed"
              name="Développé"
              fill="#1F73E0"
              radius={[6, 6, 6, 6]}
              barSize={24}
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={handleMouseLeave}
            >
              {data.map((entry: any, index: any) => (
                <Cell
                  key={`cell-developed-${index}`}
                  fill={
                    hoveredSprint === entry.sprint || hoveredSprint === null
                      ? '#1F73E0'
                      : '#ECECEC'
                  }
                  style={{
                    transition: 'all',
                    transitionDelay: '50ms',
                    transitionDuration: '100ms',
                  }}
                  onMouseEnter={() => setHoveredSprint(entry.sprint)}
                  onMouseLeave={() => setTimeout(() => setHoveredSprint(null))}
                />
              ))}
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="toTest"
              name="À tester"
              radius={[6, 6, 6, 6]}
              barSize={24}
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
            >
              {data.map((entry: any, index: any) => (
                <Cell
                  key={`cell-toTest-${index}`}
                  fill={
                    hoveredSprint === entry.sprint || hoveredSprint === null
                      ? '#f97316'
                      : '#ECECEC'
                  }
                  style={{
                    transition: 'all',
                    transitionDelay: '50ms',
                    transitionDuration: '100ms',
                  }}
                  onMouseEnter={() => setHoveredSprint(entry.sprint)}
                  onMouseLeave={() => setHoveredSprint(null)}
                />
              ))}
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="completed"
              name="Terminé"
              radius={[6, 6, 6, 6]}
              barSize={24}
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              {data.map((entry: any, index: any) => (
                <Cell
                  key={`cell-completed-${index}`}
                  fill={
                    hoveredSprint === entry.sprint || hoveredSprint === null
                      ? '#22c55e'
                      : '#ECECEC'
                  }
                  style={{
                    transition: 'all',
                    transitionDelay: '50ms',
                    transitionDuration: '100ms',
                  }}
                  onMouseEnter={() => setHoveredSprint(entry.sprint)}
                  onMouseLeave={() => setHoveredSprint(null)}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default MixedChart
