import clsx from 'clsx'
import { LoaderCircleIcon } from 'lucide-react'
interface INotificationProps {
  ticket: string
  type: string
  onCancel?: React.MouseEventHandler<HTMLSpanElement>
  isLoad: boolean
  showCancel?: boolean
  previousAction?: string
}
import { NOTIFICATION } from '@/configs/constants'

const Notification: React.FC<INotificationProps> = ({
  ticket,
  type,
  onCancel,
  isLoad,
  showCancel = true,
  previousAction,
}) => {
  return (
    <div
      className={clsx(
        "flex justify-between items-center text-primary-dark relative before:contents[''] before:top-0 before:rounded-s-md before:absolute before:w-1 before:h-full  before:left-0  transition duration-300 ease-in-out px-8 py-6",
        type === NOTIFICATION.success || type === NOTIFICATION.cancel
          ? 'bg-success/10 before:bg-success hover:bg-success/5'
          : 'bg-secondary/10 before:bg-secondary hover:bg-secondary/5'
      )}
    >
      {type !== NOTIFICATION.cancel ? (
        <div>
          Le ticket{' '}
          <span className="underline font-bold text-base text-primary-dark">
            {ticket}
          </span>{' '}
          a été {type === NOTIFICATION.success ? 'accepté' : 'refusé'}
        </div>
      ) : (
        <div>
          {previousAction === NOTIFICATION.success
            ? "L'acceptation"
            : 'Le refus'}{' '}
          ticket est annulée
        </div>
      )}

      {showCancel && (
        <button
          disabled={isLoad}
          className="appearance-none bg-transparent border-0 inline-flex gap-2 text-primary-light text-base hover:cursor-pointer hover:underline transition duration-300 ease-in-out"
          onClick={onCancel}
        >
          <span>Annuler</span>{' '}
          {isLoad && <LoaderCircleIcon className="animate-spin" />}
        </button>
      )}
    </div>
  )
}

export default Notification
