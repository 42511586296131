import LeaveIcon from '@/components/icons/Leave'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import { useState, useEffect } from 'react'
import { getProfileInformation, getProjectList } from '@/services/api-client'
import CrossIcon from '@/components/icons/CrossIcon'
import VerticalTabs, { TabItem } from '@/components/ui/vertifcal-tab'
import ProfilIcon from '@/components/icons/Profil'
import BellIcon from '@/components/icons/BellIcon'
import TeamIcon from '@/components/icons/Team'
import MyTeam from '@/components/navigation/topbar/MyTeam'
import MyNotifications from '@/components/navigation/topbar/MyNotifications'
import MyProfile from '@/components/navigation/topbar/MyProfile'
import MyProject, { Project } from '@/components/navigation/topbar/MyProject'
import QuestionMarkIcon from '@/components/icons/QuestionMark'
import { useAtom } from 'jotai'
import { ProjectsAtom } from '@/atom/projects'
import { IProject, IMappedProject } from '@/types/IProject'
import Cookies from 'js-cookie'
import {
  ArrowRight,
  CheckCircle2,
  ChevronDown,
  Folder,
  User2,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/configs/route-naming'
import FolderIcon from '@/components/icons/folder'

export default function TopBar() {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState<Project[] | null>(null)
  const [_, setProjectAtomState] = useAtom(ProjectsAtom)
  const [profilePicture, setProfilePicture] = useState()
  const navigate = useNavigate()

  const getProjects = async () => {
    try {
      const { data }: { data: IProject[] } = await getProjectList()
      setProjects(data)
      setProjectAtomState({ isPending: false, list: [...data] })
    } catch (error) {
      throw new Error(`Error fetching project list ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const getProfileData = async () => {
    try {
      const { data } = await getProfileInformation()
      setProfilePicture(data.profile_picture)
    } catch (error) {
      throw new Error(`Unexpected error: ${error}`)
    }
  }

  const handleDisconnect = () => {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      Cookies.remove(cookieName)
    })
    window.location.href = ROUTES.AUTH.LOGOUT
  }

  useEffect(() => {
    getProjects()
    getProfileData()
  }, [])

  const computeRequestCount = (request: { count: number; status: string }[]) =>
    request.reduce((sum, curr) => curr.count + sum, 0)

  const getRecentProjects = (): IMappedProject[] => {
    // Sort projects by modified_at date in descending order

    if (projects?.length) {
      const sortedProjects = [...projects].sort((a, b) => {
        const dateA = new Date(a.modified_at)
        const dateB = new Date(b.modified_at)
        return dateB.getTime() - dateA.getTime()
      })
      const formattedProject = sortedProjects.map((item) => ({
        logoDarkUrl: item.client_logo_dark,
        logoUrl: item.client_logo,
        clientName: item.client.name,
        id: item.id,
        key: item.key,
        name: item.name,
        requestCount: computeRequestCount(item.requests_count),
      }))
      return formattedProject.slice(0, 3)
    }
    return []
  }

  const getTabItems = (): TabItem[] => {
    const baseItems: TabItem[] = [
      {
        label: (
          <div className="inline-flex gap-3 items-center">
            <ProfilIcon />
            <span className="text-primary-dark text-sm">Mon profil</span>
          </div>
        ),
        value: 'account',
        content: <MyProfile />,
      },
      {
        label: (
          <div className="inline-flex gap-3 items-center">
            <BellIcon />
            <span className="text-primary-dark text-sm">Mes notifications</span>
          </div>
        ),
        value: 'notifications',
        content: <MyNotifications />,
      },
      {
        label: (
          <div className="inline-flex gap-3 items-center">
            <TeamIcon />
            <span className="text-primary-dark text-sm">Mon équipe</span>
          </div>
        ),
        value: 'team',
        content: <MyTeam />,
      },
    ]

    // Only add projects tab if there are projects
    if (projects && projects.length > 0) {
      baseItems.splice(2, 0, {
        label: (
          <div className="inline-flex gap-3 items-center">
            <FolderIcon />
            <span className="text-primary-dark text-sm">Mes projets</span>
          </div>
        ),
        value: 'project',
        content: <MyProject projects={projects} loading={loading} />,
      })
    }

    return baseItems
  }

  const openProfilModal = () => {
    setShowModal(true)
  }

  const logoDarkUrl = Cookies.get('projectUrlAvatarDark')
  const logoUrl = Cookies.get('projectUrlAvatar')
  const projectName = Cookies.get('projectName')

  const handleChangeProject = (project: IMappedProject) => {
    Cookies.set('projectID', project.id)
    Cookies.set('projectKey', project.key)
    Cookies.set('projectName', project.name)
    Cookies.set('projectUrlAvatar', project.logoUrl)
    Cookies.set('projectUrlAvatarDark', project.logoDarkUrl)
    window.location.reload()
  }

  return (
    <>
      <div className="bg-primary-dark h-12 flex items-center text-light px-8">
        <div className="flex justify-between items-center w-full">
          <div className="inline-flex gap-4">
            {projectName && (
              <figure className="w-6 flex h-6 overflow-hidden rounded">
                {logoDarkUrl && logoDarkUrl !== 'null' ? (
                  <img
                    src={logoDarkUrl}
                    alt={projectName}
                    className="object-contain h-auto w-full"
                  />
                ) : (
                  <Folder />
                )}
              </figure>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="inline-flex items-center gap-2 hover:cursor-pointer">
                  <span className="text-sm text-light">Projets</span>
                  <ChevronDown className="w-4" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" asChild>
                <div className="p-4 flex flex-col">
                  {projectName && (
                    <DropdownMenuItem asChild>
                      <div className="pointer-events-none flex items-center justify-between border-0 text-primary-dark text-sm p-2 w-full">
                        <div className="inline-flex items-center gap-2">
                          <figure className="w-6 h-6 flex">
                            {logoUrl && logoUrl !== 'null' ? (
                              <img
                                src={logoUrl}
                                alt={projectName}
                                className="object-contain h-auto w-full"
                              />
                            ) : (
                              <FolderIcon />
                            )}
                          </figure>
                          <span>{projectName}</span>
                        </div>
                        <CheckCircle2 className="w-4" />
                      </div>
                    </DropdownMenuItem>
                  )}
                  <div className="p-2 uppercase text-neutral-darker relative text-xs before:contents[''] before:top-1/2 before:-translate-y-1/2 before:h-[1px] before:bg-neutral-dark before:absolute before:w-1/4 before:right-0">
                    Modifié récemment
                  </div>
                  <div className="flex flex-col gap-y-2">
                    {getRecentProjects().map((project) => (
                      <DropdownMenuItem
                        key={project.id}
                        className="hover:cursor-pointer flex items-center justify-between border-0 text-primary-dark text-sm p-2 w-full"
                      >
                        <div
                          aria-hidden
                          onClick={() => handleChangeProject(project)}
                        >
                          <div className="flex items-center gap-2">
                            <figure className="w-6 h-6 flex rounded overflow-hidden">
                              {project.logoUrl ? (
                                <img
                                  src={project.logoUrl}
                                  alt={project.name}
                                  className="max-w-6 object-contain h-auto"
                                />
                              ) : (
                                <FolderIcon />
                              )}
                            </figure>
                            <span>{project.name}</span>
                          </div>
                        </div>
                      </DropdownMenuItem>
                    ))}
                  </div>

                  <DropdownMenuItem asChild>
                    <button
                      onClick={() => navigate(ROUTES.CLIENT.PROJECT)}
                      className="appearance-none inline-flex justify-center items-center hover:cursor-pointer w-full pt-2 border-t border-neutral-dark"
                    >
                      <span className="text-neutral-darker text-sm py-1 px-2">
                        Afficher tous les projets
                      </span>
                      <span className="[&_path]:stroke-neutral-darker [&_svg]:w-5">
                        <ArrowRight className="w-0" />
                      </span>
                    </button>
                  </DropdownMenuItem>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="inline-flex items-center gap-5">
            {/* <div className="hover:cursor-pointer">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div>
                    <QuestionMarkIcon />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent asChild>
                  <ul className="p-4">
                    <DropdownMenuItem asChild>
                      <li className="w-full">
                        <button className="appearance-none border-0 text-primary-dark text-sm py-1 px-2 hover:cursor-pointer">
                          Centre d’aide
                        </button>
                      </li>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <li className="text-primary-dark text-sm py-1 px-2 hover:cursor-pointer">
                        <button className="appearance-none border-0 text-primary-dark text-sm py-1 px-2 hover:cursor-pointer">
                          Contactez le support
                        </button>
                      </li>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <li className="text-primary-dark text-sm py-1 px-2 hover:cursor-pointer border-b border-neutral-dark">
                        <button className="appearance-none border-0 text-primary-dark text-sm py-1 px-2 hover:cursor-pointer">
                          Signalez un bug
                        </button>
                      </li>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <li className="text-primary-dark text-sm py-1 px-2 hover:cursor-pointer ">
                        <button className="appearance-none border-0 text-primary-dark text-sm py-1 px-2 hover:cursor-pointer">
                          Raccourci clavier
                        </button>
                      </li>
                    </DropdownMenuItem>
                  </ul>
                </DropdownMenuContent>
              </DropdownMenu>
            </div> */}
            <div className="hover:cursor-pointer">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  {profilePicture ? (
                    <figure>
                      <img
                        alt="photo-de-profil"
                        className="object-cover size-8 rounded-[50%]"
                        src={profilePicture}
                      />
                    </figure>
                  ) : (
                    <div className="border-2 border-white size-8 rounded-[50%] flex justify-center items-center">
                      <User2 />
                    </div>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" asChild>
                  <div className="p-4 flex flex-col">
                    <DropdownMenuItem asChild>
                      <button
                        className="appearance-none border-0 text-primary-dark text-sm p-2 hover:cursor-pointer w-full"
                        onClick={openProfilModal}
                      >
                        Voir mon profil
                      </button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <button
                        className="appearance-none border-0 text-primary-dark text-sm p-2 hover:cursor-pointer w-full"
                        onClick={openProfilModal}
                      >
                        Modifier mon profil
                      </button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <button className="appearance-none text-primary-dark text-sm p-2 hover:cursor-pointer pb-2 border-b border-neutral-dark">
                        Gérer mes notfications
                      </button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <button
                        className="appearance-none inline-flex justify-center items-center hover:cursor-pointer w-full"
                        onClick={handleDisconnect}
                      >
                        <span className="text-neutral-darker text-sm py-1 px-2">
                          Se déconnecter
                        </span>
                        <span className="[&_path]:stroke-neutral-darker [&_svg]:w-5">
                          <LeaveIcon />
                        </span>
                      </button>
                    </DropdownMenuItem>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showModal} onOpenChange={() => setShowModal(false)}>
        <DialogContent className="flex w-full max-w-[1070px] min-h-[670px] p-0 ">
          <DialogDescription className="hidden" />
          <DialogTitle className="hidden" />
          <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent ">
            <CrossIcon className="h-5 w-5" />
          </DialogClose>
          <div className="w-full">
            <VerticalTabs items={getTabItems()} className="h-full" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
