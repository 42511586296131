const EvolutionLegend = () => {
  return (
    <div className="flex flex-col gap-6 h-full justify-center items-start">
      <div>
        <p className="text-primary-dark text-sm inline-flex items-center gap-1">
          <span className="-mt-0.5 w-2 h-2 rounded-full bg-neutral-darker inline-block"></span>
          <span>Directive</span>
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-dark text-sm inline-flex items-center gap-1">
          <span className="-mt-1 w-2 h-2 rounded-full bg-average inline-block"></span>
          <span>Restants Dev (Pts)</span>
        </p>
        <p className="text-neutral-darker text-xs font-normal">
          TO DO et IN PROGRESS
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-dark text-sm inline-flex items-center gap-1 ">
          <span className="-mt-0.5 w-2 h-2 rounded-full bg-primary-light inline-block"></span>
          <span>Restants team (Pts)</span>
        </p>
        <p className="text-neutral-darker text-xs font-normal">
          CLIENT TESTING et PO TESTING
        </p>
      </div>
    </div>
  )
}

export default EvolutionLegend
