import AgendaSprintIcon from '@/components/icons/AgendaSprint'
import { formatDate } from '@/lib/utils'
import clsx from 'clsx'

interface ISprintDurationProps {
  startDate: string
  endDate: string
  className?: string
}

const SprintDuration: React.FC<ISprintDurationProps> = ({
  startDate = '2024-02-12',
  endDate = '2024-03-12',
  className,
}) => {
  return (
    <div className={clsx('bg-neutral-light rounded p-3', className)}>
      <div className="flex items-center gap-2">
        <div className="rounded bg-primary-light/10 p-2">
          <AgendaSprintIcon />
        </div>
        <div>
          <div className="text-neutral-darker text-xs">Durée du sprint</div>
          <div className="text-primary-dark font-semibold text-base">
            <span className="capitalize">{formatDate(startDate)}</span> -{' '}
            <span className="capitalize">{formatDate(endDate)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SprintDuration
