import { useEffect, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '../../ui/button'
import ChevronLeft from '../../icons/ChevronLeft'
import ChevronRight from '../../icons/ChevronRight'
import FilterBar from './FilterBar'
import { Input } from '@/components/ui/input'
import { SearchIcon } from 'lucide-react'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  oraiginalDataset: TData[]
  handleFilter: (v: string) => void
}

export function DataTable<TData, TValue>({
  columns,
  data,
  oraiginalDataset,
  handleFilter,
}: Readonly<DataTableProps<TData, TValue>>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  })

  const getPageNumbers = () => {
    const pageCount = table.getPageCount() // Total number of pages
    const pageIndex = table.getState().pagination.pageIndex + 1
    const totalPages = pageCount
    const delta = 2 // Number of pages around the current page

    const range = []
    for (
      let i = Math.max(2, pageIndex - delta);
      i <= Math.min(totalPages - 1, pageIndex + delta);
      i++
    ) {
      range.push(i)
    }

    if (pageIndex - delta > 2) {
      range.unshift('...')
    }
    if (pageIndex + delta < totalPages - 1) {
      range.push('...')
    }

    range.unshift(1) // Always show the first page
    if (totalPages > 1) {
      range.push(totalPages) // Always show the last page
    }

    return range
  }

  return (
    <div>
      <div className="flex items-center pb-4">
        <div className="flex items-center justify-between w-full border-b">
          <FilterBar setFilterProps={handleFilter} totalCount={oraiginalDataset.length} data={oraiginalDataset} />
          <Input
            placeholder="Rechercher par sujet ou clé"
            className="placeholder:text-[#717171] max-w-[275px] h-8 mb-2 shadow-none border placeholder:font-medium text-sm bg-neutral-dark"
            prefixIcon={<SearchIcon width={20} height={20} className="-mt-2" />}
            value={(table.getColumn('key')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('key')?.setFilterValue(event.target.value)
            }
          />
        </div>
      </div>
      <div className="border border-neutral-dark rounded-sm [&>div]:rounded-sm">
        <Table>
          <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="bg-white [&_td]:p-3">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Pas de resultas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination Section */}
      {getPageNumbers().length > 1 ? (
        <div className="flex items-center justify-end space-x-2 py-4">
          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeft />
          </Button>

          {/* Page numbers */}
          {getPageNumbers().map((page, index) =>
            typeof page === 'string' ? (
              <span
                key={index + new Date().toLocaleDateString()}
                className="w-8 h-8 p-0 flex items-center justify-center"
              >
                {page}
              </span>
            ) : (
              <Button
                key={index + new Date().toLocaleDateString()}
                variant={
                  table.getState().pagination.pageIndex + 1 === page
                    ? 'default'
                    : 'outline'
                }
                className="w-8 h-8 p-0"
                onClick={() => table.setPageIndex(page - 1)}
              >
                {page}
              </Button>
            )
          )}

          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRight />
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
