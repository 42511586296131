import ReleaseItem from '@/components/dashboard/ReleaseItem'
import { ReleaseNotes } from '@/types/IDashboard'
import { NavLink } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'
import { ROUTES } from '@/configs/route-naming'

interface ReleasesData {
  releases: ReleaseNotes[]
}

const Release: React.FC<ReleasesData> = ({ releases = [] }) => {
  const isNew = (index: number) => index === 0
  return (
    <div className="flex flex-col gap-y-2">
      {releases.slice(0, 3).map((release, index) => (
        <NavLink
          to={ROUTES.CLIENT.RELEASE + '#' + release.id}
          className="pb-1"
          key={release.id}
        >
          <ReleaseItem
            version={release.number}
            isNew={isNew(index)}
            date={release.modified_at}
          />
        </NavLink>
      ))}
      <NavLink
        to={ROUTES.CLIENT.RELEASE + '#' + releases[0].id}
        className="absolute w-full left-0 bottom-0 flex py-3 hover:cursor-pointer hover:text-neutral-darker gap-x-4 text-sm text-neutral-darker/60 font-normal justify-center items-center"
      >
        Voir toutes les versions <ArrowRight className="w-4 h-4" />
      </NavLink>
    </div>
  )
}

export default Release
