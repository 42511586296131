const TimeTracking: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 18.7499C14.6022 18.7499 18.3332 15.019 18.3332 10.4166C18.3332 5.81421 14.6022 2.08325 9.99984 2.08325C5.39746 2.08325 1.6665 5.81421 1.6665 10.4166C1.6665 15.019 5.39746 18.7499 9.99984 18.7499Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 5.41675V10.4167L6.6665 12.0834"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TimeTracking
