import axios from 'axios'
import Cookies from 'js-cookie'
import { ROUTES } from './route-naming'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let res = error.response
    if (res.status == 401) {
      window.location.href = ROUTES.AUTH.LOGIN
    }
    console.error('Looks like there was a problem. Status Code:' + res.status)
    return Promise.reject(new Error(error))
  }
)

axiosClient.defaults.headers.common['Authorization'] =
  `Token ${Cookies.get('apitoken')}`

export default axiosClient
