import { InfoIcon } from 'lucide-react'
import CircleChart from './CircleChart'
import { IStaffingData } from '@/types/IStaffing'
import { useEffect, useState } from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip'

interface Props {
  userList: IStaffingData['users']
}

const DESIGN_STAFF = ['Designer', 'Lead Designer'].map((item) =>
  item.toLowerCase()
)
const PRODUCT_STAFF = ['Product Owner', 'Directeur Projet', 'QA'].map((item) =>
  item.toLowerCase()
)
const DEV_STAFF = [
  'Ingénieur Devops',
  'Tech Lead',
  'Team Lead',
  'Développeur',
  'Developpeur',
].map((item) => item.toLowerCase())

export function Metric({ userList }: Readonly<Props>) {
  const [totalActiveDay, setTotalActiveDay] = useState(0)
  const [totalDay, setTotalDay] = useState(0)
  const [spentDayPercent, setSpentDayPercent] = useState(0)
  const [designStats, setDesignStats] = useState(0)
  const [devStats, setDevStats] = useState(0)
  const [productStats, setProductStats] = useState(0)
  useEffect(() => {
    // ALL
    const staffingArr = userList
      .map((item) => Object.values(item.staffing))
      .flat()
      .map((item) => item.split('/'))
    const { spentDay, totalDay } = computeDay(staffingArr)

    // DESIGN
    const designStaff = userList
      .filter((item) => DESIGN_STAFF.includes(item.position.toLowerCase()))
      .map((item) => Object.values(item.staffing))
      .flat()
      .map((item) => item.split('/'))
    const { spentDay: designSpent, totalDay: designTotal } =
      computeDay(designStaff)
    setDesignStats(handlePercentage(designSpent, designTotal))

    // PRODUCT
    const productStaff = userList
      .filter((item) => PRODUCT_STAFF.includes(item.position.toLowerCase()))
      .map((item) => Object.values(item.staffing))
      .flat()
      .map((item) => item.split('/'))
    const { spentDay: productSpent, totalDay: productTotal } =
      computeDay(productStaff)
    setProductStats(handlePercentage(productSpent, productTotal))

    // DEV
    const devStaff = userList
      .filter((item) => DEV_STAFF.includes(item.position.toLowerCase()))
      .map((item) => Object.values(item.staffing))
      .flat()
      .map((item) => item.split('/'))
    const { spentDay: devSpent, totalDay: devTotal } = computeDay(devStaff)
    setDevStats(handlePercentage(devSpent, devTotal))

    setSpentDayPercent((spentDay / totalDay) * 100)
    setTotalActiveDay(spentDay)
    setTotalDay(totalDay)
  }, [userList])

  const handlePercentage = (spent: number, total: number) => {
    if (total === 0 && spent === 0) {
      return 0
    } else if (total === 0 && spent > 0) {
      return 100
    } else {
      return (spent / total) * 100
    }
  }

  const computeDay = (arr: string[][]) => {
    const spentDay = Number(
      arr.reduce((acc, [day, _]) => acc + Number(day), 0).toFixed(2)
    )
    const totalDay = Number(
      arr.reduce((acc, [_, day]) => acc + Number(day), 0).toFixed(2)
    )
    return { spentDay, totalDay }
  }
  return (
    <div>
      <div className="bg-white border rounded-md text-primary-dark mb-6">
        <div className="border-b px-8 py-6">Résumé du mois</div>
        <div className="grid grid-cols-3">
          <div className="flex flex-col justify-between px-8 py-6">
            <div>Temps total travaillé</div>
            <div className="flex items-center gap-x-4">
              <div className="[&>div]:w-[34px] [&>div]:h-[34px]">
                <CircleChart
                  revertColor
                  noLabel
                  value={spentDayPercent}
                  size="xs"
                />
              </div>
              <p className="text-3xl font-semibold">
                {totalActiveDay} j{' '}
                <span className="text-neutral-darker/60 text-2xl">
                  / {totalDay} j
                </span>
              </p>
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-3">
            <div className="p-8 border-l flex justify-center items-center flex-col gap-y-4">
              <div className="[&>div]:w-[80px] [&>div]:h-[80px]">
                <CircleChart value={designStats} size="sm" />
              </div>
              <div className="flex items-center gap-x-2">
                Equipe Design
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoIcon className="w-4 h-4 flex-[0_0_auto]" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" sideOffset={10}>
                      <div className="relative">
                        Comprend les rôle suivant :
                        <ul className="list-disc pl-6">
                          {DESIGN_STAFF.map((item) => (
                            <li className="capitalize" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <div className="p-8 border-l flex justify-center items-center flex-col gap-y-4">
              <div className="[&>div]:w-[80px] [&>div]:h-[80px]">
                <CircleChart value={devStats} size="sm" />
              </div>
              <div className="flex items-center gap-x-2">
                Equipe Développement{' '}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoIcon className="w-4 h-4 flex-[0_0_auto]" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" sideOffset={10}>
                      <div className="relative">
                        Comprend les rôle suivant :
                        <ul className="list-disc pl-6">
                          {DEV_STAFF.map((item) => (
                            <li className="capitalize" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <div className="p-8 border-l flex justify-center items-center flex-col gap-y-4">
              <div className="[&>div]:w-[80px] [&>div]:h-[80px]">
                <CircleChart value={productStats} size="sm" />
              </div>
              <div className="flex items-center gap-x-2">
                Equipe Produit{' '}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoIcon className="w-4 h-4 flex-[0_0_auto]" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" sideOffset={10}>
                      <div className="relative">
                        Comprend les rôle suivant :
                        <ul className="list-disc pl-6">
                          {PRODUCT_STAFF.map((item) => (
                            <li className="capitalize" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
