import React from 'react'
import { ResponsiveContainer } from 'recharts'

interface GaugeChartProps {
  value: number
  unit: string
}

interface Point {
  x: number
  y: number
}

interface GaugeDimensions {
  outerGaugeWidth: number
  innerGaugeWidth: number
  gapBetweenGauges: number
  outerRadius: number
  innerRadius: number
}

type ColorZone = {
  maxValue: number
  color: string
}

const GaugeWithColor: React.FC<GaugeChartProps> = ({ value = 32.9, unit }) => {
  const colorZones: ColorZone[] = [
    { maxValue: 59.9, color: '#0BB54F' }, // green
    { maxValue: 79.9, color: '#FA8334' }, // orange
    { maxValue: 100, color: '#D9306B' }, // red
  ]

  const dimensions: GaugeDimensions = {
    outerGaugeWidth: 5,
    innerGaugeWidth: 15,
    gapBetweenGauges: 2,
    outerRadius: 75,
    innerRadius: 75 - 5 / 2 - 2 - 15 / 2, // Calculated without `this`
  }

  const normalizedValue = Math.min(Math.max(value, 0), 100)

  const startAngle = -200
  const totalAngle = 210

  const getColor = (value: number): string => {
    const zone = colorZones.find((zone) => value <= zone.maxValue)
    return zone ? zone.color : colorZones[colorZones.length - 1].color
  }

  const generateArc = (
    startDegree: number,
    endDegree: number,
    radius: number
  ): string => {
    const getPointOnArc = (degree: number): Point => {
      const adjustedDegree = startAngle + (degree / 180) * totalAngle
      return {
        x: radius * Math.cos((adjustedDegree * Math.PI) / 180) + 100,
        y: radius * Math.sin((adjustedDegree * Math.PI) / 180) + 100,
      }
    }

    const start = getPointOnArc(startDegree)
    const end = getPointOnArc(endDegree)
    const largeArcFlag = endDegree - startDegree <= 180 ? '0' : '1'

    return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}`
  }

  const generateColorSegments = (): JSX.Element[] => {
    let startDegree = 0
    return colorZones.map((zone, index) => {
      const endDegree = (zone.maxValue / 100) * 190
      const segment = (
        <path
          key={`segment-${JSON.stringify(zone)}`}
          d={generateArc(startDegree, endDegree, dimensions.outerRadius)}
          fill="none"
          stroke={zone.color}
          strokeWidth={dimensions.outerGaugeWidth - 2}
        />
      )
      startDegree = endDegree
      return segment
    })
  }

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={130}>
        <svg viewBox="0 0 200 150" className="w-full">
          <g className="translate-y-2">
            {/* Outer grey background */}
            <path
              d={generateArc(0, 190, dimensions.outerRadius)}
              fill="none"
              stroke="#e5e7eb"
              strokeWidth={dimensions.outerGaugeWidth}
            />
            {/* Colored segments */}
            {generateColorSegments()}
          </g>

          <g className="translate-y-2">
            {/* Inner grey background */}
            <path
              d={generateArc(0, 190, dimensions.innerRadius)}
              fill="none"
              stroke="#e5e7eb"
              strokeWidth={dimensions.innerGaugeWidth}
            />
            {/* Value arc */}
            <path
              d={generateArc(
                0,
                (normalizedValue / 100) * 190,
                dimensions.innerRadius
              )}
              fill="none"
              stroke={getColor(normalizedValue)}
              strokeWidth={dimensions.innerGaugeWidth}
              className="transition-all duration-500"
            />
          </g>

          {/* Centered value */}
          <text
            x="100"
            y="120"
            textAnchor="middle"
            className="text-2xl font-semibold"
          >
            <tspan fill="#11103B">{value}</tspan>
            <tspan fill="#717171" fontSize={18}>
              {unit}
            </tspan>
          </text>
        </svg>
      </ResponsiveContainer>
    </div>
  )
}

export default GaugeWithColor
