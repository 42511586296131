import { Star } from 'lucide-react'
interface ISatisfactionTopicItemProps {
  topic: string
  rating: Number
  chipBgClass?: string
}

const SatisfactionTopicItem: React.FC<ISatisfactionTopicItemProps> = ({
  topic,
  rating,
  chipBgClass = 'bg-primary-dark',
}) => {
  const currentRating = Number(rating?.toFixed(1)).toString().replace('.', ',')

  return (
    <li className="flex justify-between items-center ">
      <span className="flex items-center gap-2">
        <span className={`w-2 h-2  rounded-full ${chipBgClass}`}></span>
        <span className="text-sm text-primary-dark">{topic}</span>
      </span>
      <p className="inline-flex items-center gap-3">
        <Star
          size={16}
          className="fill-primary-dark stroke-primary-dark"
          strokeWidth={1}
        />
        <span className="text-base text-primary-dark">{currentRating}</span>
      </p>
    </li>
  )
}

export default SatisfactionTopicItem
