import { IPerformanceAdvice } from '@/types/IPerformances'
import CircleChart from './chart/CircleChart'
import MetricDetailsFilter from './MetricDetailsFilter'
import { useEffect, useState } from 'react'
import MetricDetailsByTags from './MetricDetailsByTags'

interface Props {
  score: number
  advice: IPerformanceAdvice[]
}
const IS_SUCCESS_LIMIT = 90
export default function MetricDetailsContent({
  score = 0,
  advice,
}: Readonly<Props>) {
  const [isSuccessFilter, setIsSuccessFilter] = useState(false)
  const [failedCount, setFailedCount] = useState(0)
  const [successCount, setSuccessCount] = useState(0)
  const [filteredAdvice, setFilteredAdvice] = useState<IPerformanceAdvice[]>([])

  useEffect(() => {
    const tmpSuccess = advice.filter((item) => item.score >= IS_SUCCESS_LIMIT)
    const tmpFailed = advice.filter((item) => item.score < IS_SUCCESS_LIMIT)
    setSuccessCount(tmpSuccess.length)
    setFailedCount(tmpFailed.length)
    if (isSuccessFilter) {
      // filter all item over 90
      setFilteredAdvice(tmpSuccess)
    } else {
      setFilteredAdvice(tmpFailed)
    }
  }, [isSuccessFilter, advice])
  return (
    <>
      <div className="">
        <div className="px-4 flex justify-center [&>div]:first:h-[160px]">
          <CircleChart value={score} size="medium" withMaxHeight={false} />
        </div>
      </div>
      <MetricDetailsFilter
        setSuccess={setIsSuccessFilter}
        isSuccess={isSuccessFilter}
        failedCount={failedCount}
        successCount={successCount}
      />
      <div className="pt-4">
        <MetricDetailsByTags filteredAdvice={filteredAdvice} />
      </div>
    </>
  )
}
