import HeadingTitle from '@/components/ui/heading-title'

import GeneralInformations from '@/components/app-status/GeneralInformations'
import LastIncidents from '@/components/app-status/LastIncidents'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import { useEffect, useState } from 'react'
import { getMonitoringData } from '@/services/monitor.service'
import { LoaderCircleIcon } from 'lucide-react'
import { IApplicationStatus } from '@/types/IAppStatus'
import { getLastIncidents } from '@/services/last-incidents.service'
import EmptyData from '@/components/empty-data'
import { IIncident } from '@/types/IIncidents'

export default function AppStatus() {
  const [isConfigured, setIsConfigured] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [appStatusData, setAppStatusData] = useState<IApplicationStatus>()
  const [lastIncidents, setLastIncidents] = useState<IIncident[]>([])

  async function getMonitoring() {
    try {
      const { data }: { data: IApplicationStatus[] } = await getMonitoringData()
      if (!data.length) {
        setIsConfigured(false)
      }
      setAppStatusData(data[0])
      const { data: lastIncidentData } = await getLastIncidents()
      setLastIncidents(lastIncidentData)
    } catch (error) {
      setIsConfigured(false)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getMonitoring()
  }, [])
  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }
  return (
    <div>
      <HeadingTitle label="État de l'application" className="mb-0" />
      {isConfigured ? (
        <>
          <div className="bg-white rounded">
            <h3 className="px-8 py-6 font-semibold text-xl border-b">
              Informations générales
            </h3>
            <GeneralInformations generalInfo={appStatusData!} />
          </div>
          <div className="pt-4">
            <div className="bg-white rounded">
              <h3 className="px-8 py-6 font-semibold text-xl border-b">
                Derniers incidents
              </h3>
              {lastIncidents.length ? (
                <LastIncidents lastIncidents={lastIncidents} />
              ) : (
                <div className="p-8">
                  <EmptyData msg="Pas d'incidents" />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <MonitoringNoConfiguration
          context="État de l'application"
          urlImg="/img/not-configured-img.png"
          detailList={[
            'Vérifier la validité de votre certificat SSL',
            "Être informé de tout incident technique sur l'application",
            "Suivre la date d'expiration de votre nom de domaine",
          ]}
        />
      )}
    </div>
  )
}
