import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import { DataTable } from '@/components/sprint-past/Datatable'
import ClipboardCopyIcon from '@/components/icons/ClipboardCopyIcon'
import EyeOpenIcon from '@/components/icons/Eye'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import SortIcon from '@/components/icons/SortIcon'
import VerticalDot from '@/components/icons/VerticalDot'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Dialog, DialogContent } from '@/components/ui/dialog'

import {
  formatTimeElapsed,
  generateRandomAvatarColor,
  getNameAbbreviation,
} from '@/lib/utils'
import { getSprintPastList } from '@/services/api-client'
import TicketDetails from '@/components/backlog/ticket-details/index'
import { TicketAtom, TicketPastAtom } from '@/atom/ticket'
import { TicketPast } from '@/types/ITicketPast'

import { toast } from 'sonner'
import { Comment } from '@/types/ITicket'
import { LoaderCircleIcon } from 'lucide-react'
import { filterFormAtom, ticketEpicListAtom } from '@/atom/ticket-filter'

type TicketsListType = {
  id: string
  subject: string
  epic: string
  filterKey?: string
  startDate: string
  endDate: string
  priority: { urlIcon: string; name: string }
  assignTo: string
  id_subject: string
  actions: string
  lastComment: string
}

function extractString(input: string = ''): string {
  const regex = /Module\s+"([^"]+)"/
  const match = RegExp(regex).exec(input)

  if (match) {
    return match[1]
  } else {
    return input
  }
}

export default function SprintPast() {
  const columns: ColumnDef<TicketsListType>[] = [
    {
      accessorKey: 'id_subject',
      header: 'Clé - Sujet',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id_subject')}
          </span>
        )
      },
    },
    {
      accessorKey: 'id',
      header: 'Clé',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id')}
          </span>
        )
      },
    },
    {
      accessorKey: 'subject',
      header: 'Sujet',
      cell: ({ row }) => {
        return (
          <div
            aria-hidden
            onClick={() => setCurrentTicketKey(row.getValue('id'))}
            className="hover:cursor-pointer hover:underline transition-all text-primary-dark w-[600px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium"
          >
            {row.getValue('subject')}
          </div>
        )
      },
    },
    {
      accessorKey: 'epic',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Epic
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const { bgColor } = generateRandomAvatarColor(row.getValue('epic'))
        const { bgColor: bgColorTransparency } = generateRandomAvatarColor(
          row.getValue('epic'),
          0.15
        )
        let content
        if (row.getValue('epic')) {
          content = (
            <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
              <span
                className="flex justify-center items-center text-sm font-medium px-3 py-1 rounded-sm"
                style={{ backgroundColor: bgColorTransparency, color: bgColor }}
              >
                {row.getValue('epic')}
              </span>
            </span>
          )
        } else {
          content = <NotAllowedIcon />
        }

        return content
      },
    },
    {
      accessorKey: 'priority',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Priorité
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const priority: { name: string; urlIcon: string } =
          row.getValue('priority')
        return (
          <img
            alt={priority.name}
            className="w-6 h-6"
            title={priority.name}
            src={priority.urlIcon.split(' ')[1]}
          />
        )
      },
    },
    {
      accessorKey: 'assignTo',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Assigné à
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const assignTo: string = row.getValue('assignTo')
        const { bgColor, textColor } = generateRandomAvatarColor(assignTo)
        const firstname = assignTo.split(' ')[0]
        return (
          <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {getNameAbbreviation(row.getValue('assignTo'))}
            </span>
            {firstname}
          </span>
        )
      },
    },
    {
      accessorKey: 'lastComment',
      header: 'Commentaires',
      cell: ({ row }) => {
        const lastComment: string = row.getValue('lastComment')
        return (
          <div className="text-neutral-darker/80">
            {lastComment ? (
              <div className="flex flex-col">
                <span>Dernier commentaire</span>{' '}
                {formatTimeElapsed(lastComment)}
              </div>
            ) : (
              <NotAllowedIcon />
            )}
          </div>
        )
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="h-8 w-8 p-0 border border-neutral-dark rounded-lg"
              >
                <span className="sr-only">Open menu</span>
                <VerticalDot />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="border-neutral-dark w-52 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            >
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onClick={() => setCurrentTicketKey(row.getValue('id'))}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <EyeOpenIcon /> Voir
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/ticket/${row.getValue('id')}`
                  )
                  toast('Lien copié dans le presse-papiers')
                }}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <ClipboardCopyIcon /> Copier le lien
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  const [_, setTicketAtom] = useAtom(TicketPastAtom)
  const setTicketToDialogAtom = useSetAtom(TicketAtom)
  const [currentTicketKey, setCurrentTicketKey] = useState<string>()
  const [allTicketsList, setAllTicketsList] = useState<TicketsListType[]>([])
  const [ticketsList, setTicketsList] = useState<TicketsListType[]>([])
  const [apiResult, setApiResult] = useState<TicketPast[]>()
  const [index, setIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const filterForm = useAtomValue(filterFormAtom)
  const setTicketEpicList = useSetAtom(ticketEpicListAtom)

  useEffect(() => {
    const filteredTicket = filterCurrentTicket(currentTicketKey as string)
    setTicketAtom(filteredTicket as TicketPast)
    setTicketToDialogAtom(filteredTicket as any)
  }, [currentTicketKey])

  async function getTickets() {
    const { data } = await getSprintPastList()
    return Promise.resolve(data)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getTickets()
        setApiResult(result)
        setAllTicketsList((prev) => [
          ...prev,
          ...result.map((item: any) => ({
            id: item.key,
            subject: item.fields.summary,
            epic: extractString(item.fields.parent?.fields?.summary),
            filterKey: item.fields.customfield_10020[0].name,
            startDate: item.fields.customfield_10020[0].startDate,
            endDate: item.fields.customfield_10020[0].endDate,
            priority: {
              urlIcon: `${item.fields.priority.id} ${item.fields.priority.iconUrl}`,
              name: item.fields.priority.name,
            },
            assignTo: item.fields.reporter.displayName,
            id_subject: `${item.key} ${item.fields.summary}`,
            actions: item.fields.self,
            lastComment: (item.fields.comment.comments as Comment[]).length
              ? item.fields.comment.comments.at(-1).updated
              : '',
          })),
        ])
      } catch (error) {
        throw new Error('Unexpected error occured')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (apiResult?.length) {
      const initResult: TicketsListType[] = [
        ...apiResult.map((item: any) => ({
          id: item.key,
          subject: item.fields.summary,
          epic: extractString(item.fields.parent?.fields?.summary),
          filterKey: item.fields.customfield_10020[0].name,
          startDate: item.fields.customfield_10020[0].startDate,
          endDate: item.fields.customfield_10020[0].endDate,
          priority: {
            urlIcon: `${item.fields.priority.id} ${item.fields.priority.iconUrl}`,
            name: item.fields.priority.name,
          },
          assignTo: item.fields.reporter.displayName,
          id_subject: `${item.key} ${item.fields.summary}`,
          actions: item.fields.self,
          lastComment: (item.fields.comment.comments as Comment[]).length
            ? item.fields.comment.comments.at(-1).updated
            : '',
        })),
      ]
      const filteredArr = [
        ...initResult
          .filter((item) => {
            if (filterForm.epicName.length) {
              return filterForm.epicName.includes(item.epic)
            } else {
              return item
            }
          })
          .filter((item) => {
            if (filterForm.priority.length) {
              return filterForm.priority.includes(item.priority.name)
            } else {
              return item
            }
          }),
      ]
      setTicketsList(() => filteredArr)
    }
  }, [filterForm, apiResult])

  useEffect(() => {
    if (apiResult) {
      const ticketEpicNameList = Array.from(
        new Set(
          apiResult.map((item) =>
            extractString(item.fields?.parent?.fields.summary)
          )
        )
      )
      setTicketEpicList((prev) => ({
        ...(prev as any),
        ticketEpicNameList,
      }))
    }
    return () => {
      setTicketEpicList({})
    }
  }, [apiResult])

  function filterCurrentTicket(key: string): TicketPast | undefined {
    return apiResult?.find((item) => key === item.key)
  }
  const uniqueFilterKeys = allTicketsList.reduce<string[]>((acc, curr) => {
    const filterKey = curr.filterKey ?? ''
    if (!acc.includes(filterKey)) {
      acc.push(filterKey)
    }
    return acc
  }, [])

  const sprintOptions = allTicketsList.reduce<
    { filterKey: string; startDate: string; endDate: string }[]
  >((acc, curr) => {
    const filterKey = curr.filterKey ?? ''
    if (!acc.some((sprint) => sprint.filterKey === filterKey)) {
      acc.push({
        filterKey,
        startDate: curr.startDate,
        endDate: curr.endDate,
      })
    }
    return acc
  }, [])

  useEffect(() => {
    const defaultTicketValue = allTicketsList.filter(
      (item) => item.filterKey === uniqueFilterKeys[uniqueFilterKeys.length - 1]
    )
    setTicketsList([...defaultTicketValue])
  }, [allTicketsList])

  function handleSprintChange(selectedSprintKey: string) {
    const selectedIndex = uniqueFilterKeys.findIndex(
      (key) => key === selectedSprintKey
    )
    setIndex(selectedIndex)
    const data = allTicketsList.filter(
      (item) => item.filterKey === selectedSprintKey
    )
    setTicketsList(data)
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  return (
    <>
      <DataTable
        infos={{
          filterKey: ticketsList[0]?.filterKey ?? '',
          startDate: ticketsList[0]?.startDate || '',
          endDate: ticketsList[0]?.endDate || '',
        }}
        sprints={sprintOptions}
        onSprintChange={handleSprintChange}
        columns={columns}
        data={ticketsList}
      />
      <Dialog
        open={!!currentTicketKey}
        onOpenChange={() => setCurrentTicketKey(undefined)}
      >
        <DialogContent className="max-w-[768px]">
          <TicketDetails />
        </DialogContent>
      </Dialog>
    </>
  )
}
