import useClientSatisfaction, { STEP_CONFIG } from '@/atom/client-satisfaction'
import SatisfactionTimeline from '@/components/client-satisfaction/SatisfactionTimeline'
import CommonSatisfactionStep from '@/components/client-satisfaction/step/Common'
import SummarySatisfactionStep from '@/components/client-satisfaction/step/Summary'
import HeadingTitle from '@/components/ui/heading-title'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '@/configs/route-naming'

export default function ClientSatisfactionStep() {
  const { currentStepKeyState, currentMainStep } = useClientSatisfaction()
  return (
    <div>
      <HeadingTitle>
        <div className="text-primary-dark pb-4 font-semibold text-2xl flex items-center gap-x-1">
          <div className="text-neutral-darker/60">
            <NavLink
              to={ROUTES.PROTECTED.FEEDBACK}
              className="hover:text-neutral-darker transition-colors"
            >
              Satisfaction client
            </NavLink>{' '}
            ›
          </div>{' '}
          Ajout d'un avis client
        </div>
      </HeadingTitle>
      <div className="grid grid-cols-3 gap-x-4">
        <SatisfactionTimeline />
        <div className="col-span-2 bg-white rounded-md border">
          {currentMainStep === 0 &&
            STEP_CONFIG.map(
              (item) =>
                currentStepKeyState === item.key && (
                  <CommonSatisfactionStep
                    key={item.id}
                    config={{ ...item, mainKey: 0 }}
                  />
                )
            )}
          {currentMainStep === 1 && (
            <CommonSatisfactionStep
              key={6}
              config={{
                key: 'GLO',
                id: 6,
                title:
                  'Que pensez vous de notre collaboration sur ce dernier sprints ?',
                mainKey: 1,
              }}
            />
          )}
          {currentMainStep === 2 && <SummarySatisfactionStep />}
        </div>
      </div>
    </div>
  )
}
