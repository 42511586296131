import Chat from '@/components/chat/Chat'
import ChatMembers from '@/components/chat/ChatMembers'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
export default function ChatPage() {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isDisplayContacts, setIsDisplayContacts] = useState(false)
  const ajustParentPadding: any = useOutletContext()
  useEffect(() => {
    ajustParentPadding('pr-0 pt-0')
    return () => {
      ajustParentPadding('')
    }
  }, [])
  return (
    <div
      ref={containerRef}
      className={clsx(
        isDisplayContacts ? 'grid' : '',
        'grid-cols-3 h-[calc(100vh-3rem)] w-full group-[div]:pt-0'
      )}
    >
      <div className="col-span-2 h-full">
        <Chat toggleContactView={setIsDisplayContacts} />
      </div>
      {isDisplayContacts ? (
        <div className="relative bg-white rounded border border-neutral-dark">
          <ChatMembers toggleContactView={setIsDisplayContacts} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
