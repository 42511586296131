import { Card, CardContent } from '@/components/ui/card'
import TimeSeriesChart from './TimeSerieChart'
import GaugeWithColor from './GaugeWithColor'
import InformationIcon from './../icons/Information'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

interface IDataPoint {
  date: string
  value: number
}
interface IChartCardProps {
  title: string
  gaugeValue: number
  unit: string
  icon: JSX.Element
  chartData: IDataPoint[]
  infos: string
}

const ChartCard: React.FC<IChartCardProps> = ({
  title,
  gaugeValue,
  icon,
  unit,
  chartData,
  infos,
}) => {
  return (
    <Card>
      <CardContent className="p-0">
        <div className="grid grid-cols-[278px_1fr] ">
          <div className="self-center flex flex-col items-center justify-center p-6 border-r border-neutral-dark h-full">
            <div className="flex flex-col justify-center items-center ">
              <div className="inline-flex gap-2 items-center">
                <div className="p-1 bg-primary-light/10 rounded-sm">{icon}</div>
                <span>{title}</span>
                <div className="[&_svg]:w-4 [&_svg]:h-4 mt-1.5">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <InformationIcon />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" sideOffset={10}>
                        <div className="max-w-72">{infos}</div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
              <div className="-m-4">
                <GaugeWithColor value={gaugeValue} unit={unit} />
              </div>
            </div>
          </div>
          <div className="self-center pt-4 px-8 max-h-[278px]">
            <TimeSeriesChart value={gaugeValue} unit={unit} data={chartData} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ChartCard
