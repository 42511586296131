const ReportIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5001 1.66602H7.50008C7.03984 1.66602 6.66675 2.03911 6.66675 2.49935V4.16602C6.66675 4.62625 7.03984 4.99935 7.50008 4.99935H12.5001C12.9603 4.99935 13.3334 4.62625 13.3334 4.16602V2.49935C13.3334 2.03911 12.9603 1.66602 12.5001 1.66602Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 3.33398H14.9999C15.4419 3.33398 15.8659 3.50958 16.1784 3.82214C16.491 4.1347 16.6666 4.55862 16.6666 5.00065V16.6673C16.6666 17.1093 16.491 17.5333 16.1784 17.8458C15.8659 18.1584 15.4419 18.334 14.9999 18.334H4.99992C4.55789 18.334 4.13397 18.1584 3.82141 17.8458C3.50885 17.5333 3.33325 17.1093 3.33325 16.6673V5.00065C3.33325 4.55862 3.50885 4.1347 3.82141 3.82214C4.13397 3.50958 4.55789 3.33398 4.99992 3.33398H6.66659"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.16602L7.5 14.166"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.16602L12.5 14.166"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReportIcon
