import React, { useEffect, useState } from 'react'
import { createRatingAtom } from '@/atom/dashboard'
import { useAtom } from 'jotai'

interface IPerformanceItemProps {
  label: string
  rating: string
  id: string
}

const PerformanceItem: React.FC<IPerformanceItemProps> = ({
  label,
  rating,
  id,
}) => {
  const ratingAtom = React.useMemo(() => createRatingAtom(id), [id])
  const [storedRating, setStoredRating] = useAtom(ratingAtom)
  const [operator, setOperator] = useState<'+' | '-'>('-')
  const [difference, setDifference] = useState(0)

  useEffect(() => {
    const newRating = parseInt(rating, 10)
    if (storedRating === null) {
      setStoredRating(newRating)
    } else {
      const diff = newRating - storedRating
      setDifference(Math.abs(diff))
      setOperator(diff >= 0 ? '+' : '-')
      setStoredRating(newRating)
    }
  }, [rating, storedRating, setStoredRating])

  return (
    <div className="border border-neutral-dark rounded-lg bg-neutral-light flex justify-between items-center px-2 py-3">
      <span className="text-neutral-darker">{label}</span>
      <div className="inline-flex gap-1 items-center">
        <span className="font-semibold text-base">{rating}/100</span>
        {difference > 0 && (
          <span
            className={`bg-${operator === '+' ? 'success' : 'error'}/10 rounded-full text-xs px-1 py-0.5`}
          >
            {operator}
            {difference}
          </span>
        )}
      </div>
    </div>
  )
}

export default PerformanceItem
