import clsx from 'clsx'
import React from 'react'

interface HeadingTitleProps {
  label?: string
  className?: string
  children?: React.ReactNode
  containerClassName?: string
}
const HeadingTitle: React.FC<HeadingTitleProps> = ({
  label,
  className,
  children,
  containerClassName
}) => {
  return (
    <div className={containerClassName}>
      {children}
      {label && (
        <h1 className={clsx('font-semibold text-2xl mb-6', className)}>
          {label}
        </h1>
      )}
    </div>
  )
}

export default HeadingTitle
