import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const projectID = Cookies.get('projectID') as string

interface IMonitoringParams {
  days_ago?: string
}

export function getServerMonitoring(qparams?: IMonitoringParams) {
  return axiosClient.get(`/agents/${projectID}`, { params: qparams })
}
