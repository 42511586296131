import React, { useMemo } from 'react'
import { Label, Pie, PieChart } from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'

export interface DonutChartData {
  key: string
  value: number
  fill: string
}

export interface DonutChartProps {
  data: DonutChartData[]
  config: ChartConfig
  label?: {
    title: string
    subtitle: string
  }
  className?: string
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  config,
  label,
  className = 'mx-auto aspect-square max-h-[250px]',
}) => {
  const total = useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.value, 0)
  }, [data])

  return (
    <ChartContainer config={config} className={className}>
      <PieChart>
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Pie
          data={data}
          dataKey="value"
          nameKey="key"
          innerRadius={60}
          strokeWidth={5}
        >
          <Label
            content={({ viewBox }) => {
              if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground text-5xl font-bold"
                    >
                      {total.toLocaleString() ?? 0}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={(viewBox.cy ?? 0) + 28}
                      fill="#717171"
                      fontSize="14px"
                      fontWeight="500"
                    >
                      {label ? label.subtitle : 'Total'}
                    </tspan>
                  </text>
                )
              }
            }}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  )
}

export default DonutChart
