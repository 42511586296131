import { TicketType } from '@/types/ITicket'
import { Badge } from '../ui/badge'
import TicketCard from './TicketCard'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  filterFormAtom,
  filterStringAtom,
  ticketCountAtom,
  ticketEpicListAtom,
} from '@/atom/ticket-filter'
import { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '../ui/dialog'
import TicketDetails from '../backlog/ticket-details'
import { TicketAtom } from '@/atom/ticket'

interface Props {
  ticketsTodo: TicketType[]
  ticketsInProgress: TicketType[]
  ticketsTesting: TicketType[]
  ticketsDone: TicketType[]
}

function TicketListing({
  ticketsList,
  label,
}: Readonly<{ ticketsList: TicketType[]; label: string }>) {
  const filterForm = useAtomValue(filterFormAtom)
  const filterString = useAtomValue(filterStringAtom)
  const setTicketCount = useSetAtom(ticketCountAtom)
  const setTicketEpicList = useSetAtom(ticketEpicListAtom)
  const [list, setList] = useState<TicketType[]>([])

  useEffect(() => {
    const filteredArr = [
      ...ticketsList
        .filter(
          (item) =>
            item.fields.summary
              .toLowerCase()
              .includes(filterString.toLowerCase()) ||
            item.key.toLowerCase().includes(filterString.toLowerCase())
        )
        .filter((item) => {
          if (filterForm.epicName.length) {
            return filterForm.epicName.includes(
              item.fields?.parent?.fields.summary
            )
          } else {
            return item
          }
        })
        .filter((item) => {
          if (filterForm.priority.length) {
            return filterForm.priority.includes(item.fields.priority.name)
          } else {
            return item
          }
        }),
    ]
    setList(() => filteredArr)
  }, [filterString, filterForm])
  useEffect(() => {
    setTicketCount((prev) => ({
      ...(prev as Record<string, number>),
      [label]: list.length,
    }))
  }, [list.length])

  useEffect(() => {
    const ticketEpicNameList = Array.from(
      new Set(ticketsList.map((item) => item.fields?.parent?.fields.summary))
    )
    setTicketEpicList((prev) => ({
      ...prev,
      [label]: ticketEpicNameList,
    }))
    return () => {
      setTicketEpicList({})
    }
  }, [ticketsList])
  return (
    <div>
      <div className="flex text-sm items-center gap-x-2">
        {label}
        <Badge className="text-neutral-darker shadow-none rounded-md bg-neutral-darker/20 hover:bg-neutral-darker/20">
          {list.length}
        </Badge>{' '}
      </div>
      <div className="py-4 flex flex-col gap-y-4">
        {list.map((ticketDetails) => (
          <TicketCard ticketDetails={ticketDetails} key={ticketDetails.id} />
        ))}
      </div>
    </div>
  )
}
export default function SprintListing({
  ticketsTodo,
  ticketsInProgress,
  ticketsTesting,
  ticketsDone,
}: Readonly<Props>) {
  const [ticketAtom, setTicketAtom] = useAtom(TicketAtom)

  return (
    <>
      <div className="pt-6">
        <div className="grid grid-cols-4 gap-x-8">
          {/* TO DO */}
          <TicketListing label="À faire" ticketsList={ticketsTodo} />

          {/* IN PROGRESS */}
          <TicketListing label="En cours" ticketsList={ticketsInProgress} />

          {/* TESTING */}
          <TicketListing
            label="En phase de test"
            ticketsList={ticketsTesting}
          />

          {/* DONE */}
          <TicketListing label="Terminé" ticketsList={ticketsDone} />
        </div>
      </div>
      <Dialog open={!!ticketAtom} onOpenChange={() => setTicketAtom(undefined)}>
        <DialogContent className="max-w-[768px] pb-0">
          <TicketDetails />
        </DialogContent>
      </Dialog>
    </>
  )
}
