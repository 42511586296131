import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { extractFeedbackRatings } from '@/lib/utils.satisfaction'
import { InputData } from '@/types/IClientSatisfaction'
import CustomRating from './CustomRating'

interface IFeedBackAccordionProps {
  feedback: InputData
}

interface IAccordion {
  label: string
  comments: string
  rating: number
}

const FeedBackAccordion: React.FC<IFeedBackAccordionProps> = ({ feedback }) => {
  const accordionItems: IAccordion[] = [
    {
      label: 'Communication',
      comments: extractFeedbackRatings(feedback.comment).communication,
      rating: feedback.grade_communication,
    },
    {
      label: 'Conseil et enjeux',
      comments: extractFeedbackRatings(feedback.comment).conseil,
      rating: feedback.grade_understanding,
    },
    {
      label: 'Réactivité et anticipation',
      comments: extractFeedbackRatings(feedback.comment).reactivite,
      rating: feedback.grade_reactivity,
    },
    {
      label: 'Qualité des livrables',
      comments: extractFeedbackRatings(feedback.comment).deliverables,
      rating: feedback.grade_deliverables,
    },
    {
      label: 'Respect des délais',
      comments: extractFeedbackRatings(feedback.comment).deadlines,
      rating: feedback.grade_deadlines,
    },
  ]

  return (
    <Accordion type="multiple">
      {accordionItems.map((item: IAccordion) => (
        <AccordionItem
          key={item.label}
          value={item.label}
          className="border-b-0"
        >
          <AccordionTrigger className="border-t border-b-0 bg-white text-primary-dark text-sm font-normal flex-row hover:no-underline justify-between gap-x-4 px-4 pr-8">
            <div className="px-4 text-lg text-primary-dark flex items-center gap-x-8">
              {item.label}
            </div>
          </AccordionTrigger>
          <AccordionContent className="px-8 text-primary-dark text-sm">
            <div className="flex flex-col">
              <div className="self-start mb-2">
                <CustomRating
                  rating={item.rating}
                  showNumber={false}
                  size={14}
                />
              </div>
              <p className="p">{item.comments}</p>
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default FeedBackAccordion
