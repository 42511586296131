import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import MetricDetailsContent from './MetricDetailsContent'
import {
  Datapoint,
  IPerformance,
  IPerformanceAdvice,
} from '@/types/IPerformances'
import { useEffect, useState } from 'react'

interface Props {
  selectedPage: IPerformance
}

const metricList = [
  {
    label: 'Bonnes pratiques',
    key: 'bestpractice',
  },
  {
    label: 'Performances',
    key: 'performance',
  },
  {
    label: 'Accessibilité',
    key: 'privacy',
  },
]
enum TARGET_KEY {
  BEST_PRACTICE = 'Bonnes pratiques',
  PERFORMANCE = 'Performances',
  ACCESSIBILITY = 'Vie privée',
}

const CHECK_KEY = ['bestpractice', 'performance', 'privacy']

export default function MetricDetails({ selectedPage }: Readonly<Props>) {
  const [stats, setStats] = useState<{
    bestpractice: number
    performance: number
    privacy: number
  }>()
  const [advice, setAdvice] = useState<{
    bestpractice: IPerformanceAdvice[]
    performance: IPerformanceAdvice[]
    privacy: IPerformanceAdvice[]
  }>({
    bestpractice: [],
    performance: [],
    privacy: [],
  })
  function computedAverage(data: Datapoint[]) {
    let average = 0
    if (data?.length) {
      const values = data.map((item) => item[0])
      average = Math.round(
        values.reduce((sum, value) => sum + value, 0) / values.length
      )
    }
    return average
  }

  function handleAverage() {
    const bestPracticeData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.BEST_PRACTICE
    )
    const performanceData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.PERFORMANCE
    )
    const accessibilityData = selectedPage.metrics.find(
      (item) => item.target === TARGET_KEY.ACCESSIBILITY
    )
    setStats({
      privacy: computedAverage(accessibilityData?.datapoints!),
      bestpractice: computedAverage(bestPracticeData?.datapoints!),
      performance: computedAverage(performanceData?.datapoints!),
    })
  }

  function handleContent() {
    const tmp: Record<string, IPerformanceAdvice[]> = {
      bestpractice: [],
      performance: [],
      privacy: [],
    }
    selectedPage.last_advices.forEach((item) => {
      for (let k of CHECK_KEY) {
        if (item.tags.includes(k)) {
          tmp[k].push(item)
        }
      }
    })
    setAdvice(tmp as any)
  }
  useEffect(() => {
    handleAverage()
    handleContent()
  }, [selectedPage])

  return (
    <Accordion type="multiple" className='border-t'>
      {metricList.map((item) => (
        <AccordionItem key={item.key} value={item.key}>
          <AccordionTrigger className="bg-white text-primary-dark text-sm font-normal flex-row hover:no-underline justify-between gap-x-4 px-4 border-b">
            <div className="px-4 text-lg text-primary-dark flex items-center gap-x-8">{item.label}</div>
          </AccordionTrigger>
          <AccordionContent className="px-4 pt-4 text-primary-dark text-sm">
            {(stats as any)?.[item.key] && (
              <MetricDetailsContent
                advice={(advice as any)[item.key]}
                score={(stats as any)?.[item.key]}
              />
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
