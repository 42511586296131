import { useEffect, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Input } from '../ui/input'
import SearchIcon from '../icons/SearchIcon'
import { Button } from '../ui/button'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'
import FilterTabs from './FilterTabs'
import { IWeakness } from '@/types/IVulnerability'
import AppAlert from '../ui/app-alert'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import EmptyData from '../empty-data'
import { CheckCircle2 } from 'lucide-react'

type VulnListType = {
  name: {
    summary: string
    severity: string
    package: string
  }
  weaknesses: IWeakness[]
  version: string[]
  search?: string
  severity?: string
}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  vulnList: VulnListType[]
}

export function DataTable<TData, TValue>({
  columns,
  data,
  vulnList,
}: Readonly<DataTableProps<TData, TValue>>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [totalVuln, setTotalVuln] = useState(0)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  })

  const getPageNumbers = () => {
    const pageCount = table.getPageCount() // Total number of pages
    const pageIndex = table.getState().pagination.pageIndex + 1
    const totalPages = pageCount
    const delta = 2 // Number of pages around the current page

    const range = []
    for (
      let i = Math.max(2, pageIndex - delta);
      i <= Math.min(totalPages - 1, pageIndex + delta);
      i++
    ) {
      range.push(i)
    }

    if (pageIndex - delta > 2) {
      range.unshift('...')
    }
    if (pageIndex + delta < totalPages - 1) {
      range.push('...')
    }

    range.unshift(1) // Always show the first page
    if (totalPages > 1) {
      range.push(totalPages) // Always show the last page
    }

    return range
  }

  const handleFilterStatus = (filter: string) => {
    if (filter === 'Tous') {
      table.resetColumnFilters()
    } else {
      table.getColumn('severity')?.setFilterValue(filter)
    }
  }

  useEffect(() => {
    table.getColumn('search')?.toggleVisibility(false)
    table.getColumn('severity')?.toggleVisibility(false)
  }, [])

  return (
    <div>
      {!!vulnList.length && (
        <AppAlert className="rounded-md !p-2" variant="warning">
          <div className="flex items-center gap-x-4">
            <button className="rounded-md bg-average/20 p-2">
              <ExclamationTriangleIcon
                width={24}
                height={24}
                className="[&_path]:fill-average"
              />
            </button>
            <p className="text-sm text-primary-dark font-normal">
              Il reste{' '}
              <span className="font-semibold ml-1">
                {totalVuln} dépendances à mettre à jour
              </span>
            </p>
          </div>
        </AppAlert>
      )}
      <div className="flex pt-6 items-center justify-between border-b">
        <FilterTabs
          setTotalVuln={setTotalVuln}
          rawData={vulnList}
          setFilterStatus={handleFilterStatus}
        />
        <Input
          placeholder="Rechercher par nom ou risque"
          value={(table.getColumn('search')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('search')?.setFilterValue(event.target.value)
          }
          className="placeholder:text-[#717171] max-w-[275px] h-8 mb-2 placeholder:font-medium text-sm bg-white"
          prefixIcon={<SearchIcon width={20} height={20} className="-mt-2" />}
        />
      </div>
      {!vulnList.length ? (
        <div className="h-96">
          <EmptyData
            icon={
              <CheckCircle2
                width={40}
                height={40}
                className="text-success stroke-[1px]"
              />
            }
            msg="Aucune vulnérabilités détectés"
            bgIcon="bg-success/10"
          />
        </div>
      ) : (
        <div className="mt-5 border border-neutral-dark rounded-sm [&>div]:rounded-sm">
          <Table>
            <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody className="bg-white [&_td]:p-3">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="h-96">
                      <EmptyData
                        icon={
                          <CheckCircle2
                            width={40}
                            height={40}
                            className="text-success stroke-[1px]"
                          />
                        }
                        msg="Aucune vulnérabilités détectés"
                        bgIcon="bg-success/10"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}

      {/* Pagination Section */}
      {!!vulnList.length && getPageNumbers().length > 1 && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeft />
          </Button>

          {/* Page numbers */}
          {getPageNumbers().map((page, index) =>
            typeof page === 'string' ? (
              <span
                key={index + new Date().toLocaleDateString()}
                className="w-8 h-8 p-0 flex items-center justify-center"
              >
                {page}
              </span>
            ) : (
              <Button
                key={index + new Date().toLocaleDateString()}
                variant={
                  table.getState().pagination.pageIndex + 1 === page
                    ? 'default'
                    : 'outline'
                }
                className="w-8 h-8 p-0"
                onClick={() => table.setPageIndex(page - 1)}
              >
                {page}
              </Button>
            )
          )}

          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRight />
          </Button>
        </div>
      )}
    </div>
  )
}
