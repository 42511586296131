import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const projectID = Cookies.get('projectID')

export function getClientSatisfaction() {
  return axiosClient.get(`/feedback/${projectID}`)
}

interface PayloadType {
  grade_deliverables: number
  grade_reactivity: number
  grade_communication: number
  grade_understanding: number
  grade_deadlines: number
  sprint: string
  comment: string
}
export function postClientSatisfaction(payload: PayloadType) {
  return axiosClient.post(`/feedback/${projectID}`, {
    ...payload,
    project: projectID,
    grade_support: 0,
    grade_proactivity: 0
  })
}
