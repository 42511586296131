import { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Button } from '../ui/button'
import { Separator } from '../ui/separator'
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

const dateArr = [
  { label: 'Du plus récent au plus ancien', value: 'desc' },
  { label: 'Du plus ancien au plus récent', value: 'asc' },
]
export default function FormFilter({
  setFormFilter,
  formFilter,
}: Readonly<{
  setFormFilter: (v: { dateFilter: string }) => void
  formFilter: { dateFilter: string }
}>) {
  const [dateFilter, setDateFilter] = useState('asc')
  return (
    <div className="px-2 py-1 flex flex-col gap-y-2 w-full">
      <div>
        <label htmlFor="date" className="text-neutral-darker/60 text-xs">
          DATE
        </label>
        <Select
          onValueChange={setDateFilter}
          value={formFilter.dateFilter || 'asc'}
        >
          <SelectTrigger className="h-8" isClassic>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Fruits</SelectLabel>
              {dateArr.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <Separator />
      <div className="flex justify-end">
        <DropdownMenuItem className="hover:outline-none">
          <Button onClick={() => setFormFilter({ dateFilter })}>
            Appliquer
          </Button>
        </DropdownMenuItem>
      </div>
    </div>
  )
}
