const ArrowRight: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 6.5H10"
        stroke="#717171"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 3L10 6.5L6.5 10"
        stroke="#717171"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowRight
