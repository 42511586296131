import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const projectKey = Cookies.get('projectKey')

export function getAllTicketsList() {
  return axiosClient.get(`/tickets/${projectKey}?status=Backlog`)
}

export function getTicketInProgress() {
  return axiosClient.get(`/tickets/${projectKey}?status=In+Progress`)
}

export function getTicketDone() {
  return axiosClient.get(`/tickets/${projectKey}?status=Done`)
}

export function getTicketPassed() {
  return axiosClient.get(`/tickets/${projectKey}?status=Passed`)
}

export function getTicketTesting() {
  return axiosClient.get(`/tickets/${projectKey}?status=Client+Testing`)
}

export function getTicketToDo() {
  return axiosClient.get(`/tickets/${projectKey}?status=To+Do`)
}

export function getTicketDetails(ticketKey: string) {
  return axiosClient.get(`/ticket/${ticketKey}`)
}

export function updateTicketStatus(
  issueId: string,
  payload: { approve?: boolean; reject?: boolean }
) {
  return axiosClient.put(`/client_tickets/${issueId}`, payload)
}

export function cancelTicketAction(
  issueId: string,
  payload: { unreject?: boolean; unapprove?: boolean }
) {
  return axiosClient.put(`/client_tickets/${issueId}`, payload)
}
