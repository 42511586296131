import { IComment } from '@/types/IComment'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import {
  JiraTextFormatAdapter,
  formatTimeElapsed,
  getNameAbbreviation,
} from '@/lib/utils'
import { Comment } from '@/types/ITicket'
import MarkdownRenderer from '../markdown'

interface Props {
  comments: Comment
}
export default function CommentItemIssue({ comments }: Readonly<Props>) {
  return (
    <div className="py-4">
      <div className="border-t pt-6 ">
        <div className="flex gap-x-4">
          <Avatar className="border">
            <AvatarImage
              src={comments.author.avatarUrls[0]}
              alt={comments.author.emailAddress}
            />
            <AvatarFallback>
              {getNameAbbreviation(`${comments.author.displayName}`)}
            </AvatarFallback>
          </Avatar>
          <div>
            <p className="font-medium text-base text-primary-dark">
              {comments.author.displayName}
            </p>
            <p className="text-sm text-neutral-darker/80">
              {formatTimeElapsed(comments.created)}
            </p>
          </div>
        </div>
        <div className="pt-4 text-sm text-primary-dark">
          <MarkdownRenderer
            content={
              (comments as any).newBody
                ? (comments as any).newBody
                : JiraTextFormatAdapter(comments.body ?? '')
            }
          />
          {(comments as any).file ? (
            <img className='w-40 h-auto my-3' alt={(comments as any).file} src={(comments as any).file} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
