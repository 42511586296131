import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { PlusIcon } from 'lucide-react'
import { useState } from 'react'
import CreateUsefullLinkForm from './Form'

export default function UserFullLinkSection() {
  const [isOpenFormModal, setIsOpenFormModal] = useState(false)
  return (
    <>
      <Button onClick={() => setIsOpenFormModal(true)} className="w-9 h-9 p-0">
        <PlusIcon />
      </Button>
      <Dialog
        open={!!isOpenFormModal}
        onOpenChange={() => setIsOpenFormModal(false)}
      >
        <DialogContent className="max-w-[640px] pb-0">
          <CreateUsefullLinkForm closeModal={() => setIsOpenFormModal(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}
