import MarkdownRenderer from '@/components/markdown'
import AttachmentsIcon from '@/components/icons/Attachments'
import {
  formatDateTime,
  generateRandomAvatarColor,
  JiraTextFormatAdapter,
} from '@/lib/utils'
import {
  getTicketDetails,
  postDocuments,
  updateTicketStatus,
  sendRefusedTicketComments,
} from '@/services/api-client'
import { Button } from '@/components/ui/button'
import { LoaderCircleIcon, Paperclip } from 'lucide-react'
import CrossIcon from '@/components/icons/CrossIcon'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import HexaWarningIcon from '@/components/icons/HexaWarning'
import {
  DialogClose,
  DialogDescription,
  DialogTitle,
} from '@radix-ui/react-dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { NOTIFICATION } from '@/configs/constants'
import { STATUS } from '@/hooks/use-ticket-stats'
import CommentsIssue from '../commments/CommentsIssue'
import { Comment } from '@/types/ITicket'

interface ITicketSummaryDetailProps {
  title: string
  priorityStatus: string
  ticketStatus: string
  ticketKey: string
  date: Date
  emitTicketkey: Function
  sprint: string
  epic: string
  prioImg: string
}

const prio: { [key: string]: string } = {
  Highest: 'Très Élevée',
  High: 'Élevée',
  Medium: 'Moyenne',
  Low: 'Basse',
  Lowest: 'Très Basse',
}

const prioBgColor: { [key: string]: string } = {
  Highest: 'bg-red-600/10',
  High: 'bg-orange-500/10',
  Medium: 'bg-yellow/10',
  Low: 'bg-blue-300/10',
  Lowest: 'bg-green-400/10',
}

const prioTxtColor: { [key: string]: string } = {
  Highest: 'text-red-600',
  High: 'text-orange-500',
  Medium: 'text-third',
  Low: 'text-blue-300',
  Lowest: 'text-green-400',
}

const formSchema = z.object({
  comment: z
    .string()
    .min(1, { message: 'Le commentaire ne peut pas être vide.' }),
})

const TicketSummaryDetail: React.FC<ITicketSummaryDetailProps> = ({
  title,
  priorityStatus,
  ticketStatus,
  ticketKey,
  date,
  emitTicketkey,
  epic,
  sprint,
  prioImg,
}) => {
  const [isLoadDetails, setIsLoadDetails] = useState(false)
  const [description, setDescription] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentComment, setCurrentComment] = useState('')
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [loadAccept, setLoadAccept] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>()
  const [commentsList, setCommentsList] = useState<Comment[]>([])

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      comment: '',
    },
  })

  const sendRefusedComments = async () => {
    try {
      await updateTicketStatus(ticketKey, { reject: true })
    } catch (error) {
      throw new Error(`Unexpected error ${error} `)
    }
  }

  const handlePaperclipClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
      toast.success(`Fichier sélectionné : ${event.target.files[0].name}`)
    }
  }

  const acceptTicket = async () => {
    try {
      setLoadAccept(true)
      await updateTicketStatus(ticketKey, { approve: true })
    } catch (error) {
      throw new Error(`Unexpected error ${error} `)
    } finally {
      setLoadAccept(false)
      emitTicketkey({ key: ticketKey, type: NOTIFICATION.success })
    }
  }

  const postRefusedTicketComments = async () => {
    try {
      await sendRefusedTicketComments(ticketKey, { content: currentComment })
      if (file) {
        await postDocuments(ticketKey, {
          file,
        })
        setFile(null)
      }
    } catch (error) {
      throw new Error(`Unexpected error ${error} `)
    }
  }

  const onSubmit = async () => {
    setIsLoadingBtn(true)
    await Promise.all([
      sendRefusedComments(),
      postRefusedTicketComments(),
    ]).then(() => setIsLoadingBtn(false))
    emitTicketkey({ key: ticketKey, type: NOTIFICATION.error })
    setShowModal(false)
    setCurrentComment('')
  }

  async function getDescription(ticketKey: string) {
    try {
      setIsLoadDetails(true)
      const { data } = await getTicketDetails(ticketKey)
      setDescription(data['fields']['description'])
      setCommentsList(data['fields']['comment']['comments'])
    } catch (error) {
      throw new Error(`Unexpected error occured ${error}`)
    } finally {
      setIsLoadDetails(false)
    }
  }
  useEffect(() => {
    ticketKey && getDescription(ticketKey)
  }, [ticketKey])

  function openCommentsModal() {
    setShowModal(true)
  }

  function closeCommentsModal() {
    setShowModal(false)
  }

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentComment(event.target.value)
  }

  const commentValue = form.watch('comment')
  const isCommentEmpty = !commentValue || commentValue.trim() === ''

  return (
    <>
      {title && priorityStatus && ticketStatus && ticketKey && date && (
        <div className="bg-white  text-primary-dark rounded-sm border border-neutral-dark">
          <div className="flex justify-between px-8 py-6 border-b border-neutral-dark">
            <div>
              <h2 className="text-primary-dark text-2xl">{title}</h2>
              {/* <span
                className={`rounded-full py-1 px-3   mt-3 inline-block  ${prioTxtColor[priorityStatus]} ${prioBgColor[priorityStatus]}`}
              >
                Priorité {prio[priorityStatus]?.toLowerCase()}
              </span> */}
              <ul className="pt-6 [&_li]:flex flex flex-col gap-y-4 [&_li]:items-center [&_li>span]:font-normal">
                <li>
                  <span className="text-neutral-darker/60 w-32">Sprint</span>
                  <span
                    className="px-3 py-1 rounded-full text-xs"
                    style={{
                      backgroundColor: generateRandomAvatarColor(sprint, 0.2)
                        .bgColor,
                      color: generateRandomAvatarColor(sprint).bgColor,
                    }}
                  >
                    {sprint}
                  </span>
                </li>
                <li>
                  <span className="text-neutral-darker/60 w-32">Epic</span>
                  <span
                    style={{
                      backgroundColor: generateRandomAvatarColor(epic, 0.2)
                        .bgColor,
                      color: generateRandomAvatarColor(epic).bgColor,
                    }}
                    className="uppercase text-xs px-3 py-1 rounded-md"
                  >
                    {epic || <NotAllowedIcon />}
                  </span>
                </li>
                <li>
                  <span className="text-neutral-darker/60 w-32">Priorité</span>
                  <span className="capitalize flex items-center gap-x-2">
                    <img
                      className="w-6 h-6"
                      alt={prio[priorityStatus]?.toLowerCase()}
                      src={prioImg}
                    />{' '}
                    {prio[priorityStatus]?.toLowerCase()}
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/ticket/${ticketKey}`
                  )
                  toast('Lien copié dans le presse-papiers')
                }}
                className="inline-flex justify-end items-center gap-2 [&_path]:stroke-primary-light"
              >
                <span className="block text-primary-light font-semibold text-sm">
                  {ticketKey}
                </span>
                <AttachmentsIcon />
              </button>
              <span className="block text-neutral-darker text-sm">
                {formatDateTime(date)}
              </span>
            </div>
          </div>
          {isLoadDetails && !description ? (
            <div className="w-full h-80 items-center flex justify-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          ) : (
            <>
              <div className="p-10  ">
                <div className="text-primary-dark font-normal text-base  ">
                  {description ? (
                    <MarkdownRenderer
                      content={JiraTextFormatAdapter(description)}
                    />
                  ) : (
                    <span className="inline-flex gap-2 items-center">
                      <NotAllowedIcon /> <span>Pas de description</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="pt-6 px-8">
                <CommentsIssue
                  commentsProps={commentsList}
                  id={ticketKey}
                  isDialog
                />
              </div>
            </>
          )}

          {ticketStatus === STATUS.toTest && (
            <div className="p-6 flex gap-2 justify-end border-t border-neutral-dark">
              <Button
                variant="destructive"
                type="button"
                onClick={openCommentsModal}
              >
                Refuser
              </Button>
              <Button
                variant="outline"
                disabled={loadAccept}
                onClick={acceptTicket}
              >
                Accepter
                {loadAccept && <LoaderCircleIcon className="animate-spin" />}
              </Button>
            </div>
          )}
        </div>
      )}
      <Dialog open={showModal} onOpenChange={() => setShowModal(false)}>
        <DialogContent className="max-w-[640px]">
          <DialogDescription />
          <DialogTitle />
          <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <CrossIcon className="h-5 w-5" />
          </DialogClose>
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="w-8 h-8 inline-flex items-center justify-center bg-secondary/10 rounded [&_path]:stroke-secondary">
              <HexaWarningIcon />
            </div>
            <span className=" text-base font-medium text-primary-dark">
              Refus d’un ticket
            </span>
            <span className="text+base text-primary-dark">
              Ajouter un commentaire pour expliquer la raison du refus
            </span>
            <div className="px-8 pt-4 w-full">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4"
                >
                  <FormField
                    control={form.control}
                    name="comment"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="relative ">
                            <Textarea
                              {...field}
                              value={currentComment}
                              placeholder="Ajoutez votre commentaire ici..."
                              className="  h-[144px] max-h-[144px]  resize-none overflow-y-auto min-h-[144px] pr-10 rounded-sm border bg-neutral-dark"
                              onChange={(e) => {
                                field.onChange(e)
                                handleCommentChange(e)
                              }}
                            />
                            <input
                              type="file"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                              accept="image/png,image/jpeg,image/gif,video/mp4,video/quicktime,video/x-ms-wmv,video/x-flv,video/webm,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/plain,application/pdf"
                              className="hidden"
                            />
                            <Paperclip
                              className="absolute bottom-4 right-4 h-5 w-5 hover:cursor-pointer hover:stroke-neutral-darker/60 stroke-neutral-darker transition duration-300 ease-in-out"
                              onClick={handlePaperclipClick}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex flex-row justify-end gap-4 pt-4">
                    <Button
                      type="button"
                      onClick={closeCommentsModal}
                      className="border-none bg-transparent text-base text-neutral-darker shadow-none hover:bg-transparent hover:underline"
                    >
                      Annuler
                    </Button>
                    <Button
                      type="submit"
                      disabled={isCommentEmpty || isLoadingBtn}
                    >
                      {isLoadingBtn && (
                        <LoaderCircleIcon className="animate-spin" />
                      )}
                      Envoyer et refuser le ticket
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TicketSummaryDetail
