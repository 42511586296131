import React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@/lib/utils'

export interface TabItem {
  label: React.ReactNode
  value: string
  content: React.ReactNode
  disabled?: boolean
}

interface VerticalTabsProps {
  items: TabItem[]
  defaultValue?: string
  value?: string
  onValueChange?: (value: string) => void
  className?: string
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  items,
  defaultValue,
  value,
  onValueChange,
  className,
}) => {
  const ACTIVE_CLASS =
    'relative leading-none data-[state=active]:before:contents[""]  data-[state=active]:before:rounded-e-sm data-[state=active]:before:absolute data-[state=active]:before:w-1 data-[state=active]:before:h-6 data-[state=active]:before:bg-primary-light data-[state=active]:before:left-0'
  return (
    <TabsPrimitive.Root
      defaultValue={defaultValue ?? items[0].value}
      value={value}
      onValueChange={onValueChange}
      className={cn('flex space-x-4', className)}
    >
      <TabsPrimitive.List className="flex flex-col min-w-[300px] h-full p-8">
        {items.map((item) => (
          <TabsPrimitive.Trigger
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            className={cn(
              'w-full p-2 text-left rounded-lg hover:bg-neutral-light dark:hover:bg-gray-800',
              'data-[state=active]:bg-neutral-light  dark:data-[state=active]:bg-gray-800',
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
              'disabled:pointer-events-none disabled:opacity-50',
              ACTIVE_CLASS
            )}
          >
            {item.label}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>

      <div className="flex-1 border-l p-8">
        {items.map((item) => (
          <TabsPrimitive.Content
            key={item.value}
            value={item.value}
            className="outline-none"
          >
            {item.content}
          </TabsPrimitive.Content>
        ))}
      </div>
    </TabsPrimitive.Root>
  )
}

export default VerticalTabs
