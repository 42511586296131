import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { ISprint } from '@/types/ISprintProgress'

interface SprintSelectorProps {
  sprints: ISprint[]
  currentSprint: ISprint
  onSprintChange: (sprintKey: string) => void
}

const SprintSelector = ({
  sprints,
  currentSprint,
  onSprintChange,
}: SprintSelectorProps) => {
  const formatDateRange = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return ''
    return `${format(new Date(startDate), 'dd MMM', { locale: fr })} au ${format(new Date(endDate), 'dd MMM', { locale: fr })}`
  }

  return (
    <Select value={currentSprint.filterKey} onValueChange={onSprintChange}>
      <SelectTrigger isClassic className="bg-white h-8">
        <SelectValue>
          {currentSprint.filterKey
            ? `${currentSprint.filterKey} - ${formatDateRange(currentSprint.startDate, currentSprint.endDate)}`
            : 'Sélectionnez un sprint'}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {sprints.map((sprint) => (
          <SelectItem key={sprint.filterKey} value={sprint.filterKey}>
            {`${sprint.filterKey} - ${formatDateRange(sprint.startDate, sprint.endDate)}`}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default SprintSelector
