import { IAccess } from '@/types/IAccess'
import { useEffect, useState } from 'react'
import * as CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

export default function useUserAccess() {
  const SECRET = 'BrjUhSAwprm/MHHrfZzwVTsbNbC1JXKu0ZhsT5jT7bU='
  const [userAccess, setUserAccess] = useState<IAccess>()

  const encrypt = (plainText: string) =>
    CryptoJS.AES.encrypt(plainText, SECRET).toString()

  const decrypt = (cypherText: string) => {
    const bytes = CryptoJS.AES.decrypt(cypherText, SECRET)
    const _d = bytes.toString(CryptoJS.enc.Utf8)
    return _d
  }

  const getDecryptedUserAccess = () => {
    try {
      const encryptValue = Cookies.get('user_access') as string
      const decryptedValue = decrypt(encryptValue)
      return JSON.parse(decryptedValue) as IAccess
    } catch (error) {
      //   Cookies.remove('apitoken')
      //   location.href = '/auth/logout'
    }
  }

  useEffect(() => {
    if (userAccess) {
      const encrypted = encrypt(JSON.stringify(userAccess))
      Cookies.set('user_access', encrypted)
    }
  }, [userAccess])
  return {
    setUserAccess,
    getDecryptedUserAccess,
    decrypt,
  }
}
