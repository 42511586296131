import { useEffect, useState } from 'react'
import { Badge } from '../ui/badge'
import { IWeakness } from '@/types/IVulnerability'
import clsx from 'clsx'

const filterArr = [
  { value: 'Critical', label: 'Urgent', style: 'bg-black' },
  { value: 'High', label: 'Haute', style: 'bg-secondary' },
  {
    value: 'Medium',
    label: 'Moyenne',
    style: 'bg-average',
  },
  {
    value: 'Low',
    label: 'Basse',
    style: 'bg-success',
  },
]

type VulnListType = {
  name: {
    summary: string
    severity: string
    package: string
  }
  weaknesses: IWeakness[]
  version: string[]
  search?: string
  severity?: string
}
export default function FilterTabs({
  rawData,
  setFilterStatus,
  setTotalVuln,
}: Readonly<{
  rawData?: VulnListType[]
  setFilterStatus: (v: string) => void
  setTotalVuln: (v: number) => void
}>) {
  const activeMenuClass =
    "after:content[' '] after:absolute after:w-full relative after:border-b-2 after:border-primary-light after:-bottom-3 after:left-0"

  const [activeMenu, setActiveMenu] = useState('Tous')
  const [countOccurrences, setCountOccurrences] =
    useState<Record<string, number>>()
    const [total, setTotal] = useState(0)
  function getTotalCount(_countOccurrences: Record<string, number>) {
    return Object.values(_countOccurrences).reduce(
      (acc, item) => acc + item,
      0
    )
  }
  useEffect(() => {
    if (rawData) {
      const _countOccurrences = rawData.reduce(
        (acc, num) => {
          if (num.severity) {
            const severity = num.severity
            acc[severity] = (acc[severity] || 0) + 1
          }
          return acc
        },
        {} as Record<string, number>
      )
      setTotal(getTotalCount(_countOccurrences))
      setCountOccurrences(_countOccurrences)
    }
  }, [rawData])

  useEffect(() => {
    setFilterStatus(activeMenu)
  }, [activeMenu])
  useEffect(() => {
    setTotalVuln(total)
  }, [total])
  return (
    <ul className="text-sm [&>li]:cursor-pointer flex text-primary-dark gap-x-4 [&>li>span]:text-neutral-darker [&>li>span]:rounded-md [&>li>span]:py-1 [&>li>span]:px-2 [&>li>span]:bg-neutral-dark">
      <li className={activeMenu === 'Tous' ? activeMenuClass : ''}>
        <button
          className="flex items-center gap-x-1"
          onClick={() => setActiveMenu('Tous')}
        >
          Tous
          <Badge className="text-neutral-darker shadow-none rounded-md bg-neutral-darker/20 hover:cursor-pointer hover:bg-neutral-dark">
            {total}
          </Badge>
        </button>
      </li>
      {filterArr.map((item) => (
        <li
          key={item.value}
          className={activeMenu === item.value ? activeMenuClass : ''}
        >
          <button
            className="flex items-center gap-x-1"
            onClick={() => setActiveMenu(item.value)}
          >
            <div className={clsx(item.style, 'w-2 h-2 rounded-[50%]')} />
            {item.label}{' '}
            {countOccurrences ? (
              <Badge className="text-neutral-darker shadow-none rounded-md bg-neutral-darker/20 hover:cursor-pointer hover:bg-neutral-dark">
                {countOccurrences[item.value] || 0}
              </Badge>
            ) : (
              ''
            )}
          </button>
        </li>
      ))}
    </ul>
  )
}
