import { ROUTES } from '@/configs/route-naming'
import Cookies from 'js-cookie'
import { Navigate, Outlet } from 'react-router-dom'

const REDIRECT_PATH = ROUTES.AUTH.LOGIN
export default function AuthGard() {
  const isAuthenticated = Cookies.get('apitoken')
  if (!isAuthenticated) {
    window.location.href = REDIRECT_PATH
    return <Navigate to={REDIRECT_PATH} replace />
  }
  return <Outlet />
}
