import { CustomTooltipProps } from '@/types/IChartTooltip'

const CustomTooltip = ({
  active,
  payload,
  label,
  legend,
}: CustomTooltipProps) => {
  if (active && payload?.length) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-3 min-w-[200px]">
        <div className="text-xs text-neutral-darker">{label}</div>
        <div className="text-sm text-primary-dark">
          {payload[0]?.value} {legend}
        </div>
      </div>
    )
  }
  return null
}

export default CustomTooltip
