const LineAreaTooltip = ({ active, payload }: any) => {
  if (active && payload?.length) {
    return (
      <div className="bg-white p-2 border rounded-sm border-neutral-dark max-w-16 h-10">
        <div className=" min-w-[120px] text-sm text-primary-dark">
          {payload[0].value}%
        </div>
      </div>
    )
  }
  return null
}
export default LineAreaTooltip
