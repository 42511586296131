import { cn } from '@/lib/utils'
import { CustomTooltipProps } from '@/types/IChartTooltip'
import { useEffect, useState } from 'react'

type Type = { activeBarIndex: number | null } & CustomTooltipProps

interface ContainerProps {
  storypoints: number
  ticketCount: number
  color: string
  desc: string
}
const Container = ({
  storypoints,
  ticketCount,
  color,
  desc,
}: ContainerProps) => {
  return (
    <div className="flex items-start gap-x-2 text-sm">
      <div className={cn(color, 'size-2 rounded-[2px] mt-[6px]')} />
      <div>
        <div>
          {storypoints} story points {desc}
        </div>
        <div className="text-neutral-darker/60">({ticketCount} tickets)</div>
      </div>
    </div>
  )
}

const CustomTooltip = ({ active, payload, label, activeBarIndex }: Type) => {
  const [barData, setBarData] = useState(null)

  useEffect(() => {
    payload && setBarData(payload[Number(activeBarIndex)])
  }, [activeBarIndex])

  if (active && payload && !!payload.length) {
    if (barData && activeBarIndex !== null) {
      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg text-primary-dark">
          <p className="text-xs text-neutral-darker/60">
            {label}
          </p>
          {activeBarIndex === 0 && (
            <Container
              color="bg-primary-light"
              storypoints={payload[0].payload.developed}
              ticketCount={payload[0].payload.inProgressTicketCount}
              desc="en cours"
            />
          )}
          {activeBarIndex === 1 && (
            <Container
              color="bg-average"
              storypoints={payload[0].payload.toTest}
              ticketCount={payload[0].payload.testingTicketCount}
              desc="à tester"
            />
          )}
          {activeBarIndex === 2 && (
            <Container
              color="bg-success"
              storypoints={payload[0].payload.completed}
              ticketCount={payload[0].payload.compledtedTicketCount}
              desc="terminées"
            />
          )}
        </div>
      )
    }
  }
  return null
}

export default CustomTooltip
