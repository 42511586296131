import useClientSatisfaction from '@/atom/client-satisfaction'
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineTitle,
  TimelineIcon,
} from '@/components/timeline/timeline'
import { cn } from '@/lib/utils'

interface TimelineLayoutProps {
  items: { id: number; title: string; value: string }[]
}
export const TimelineLayout = ({ items }: TimelineLayoutProps) => {
  const { currentStepKeyState, pastSectionState, currentMainStep } =
    useClientSatisfaction()
  const activeVerticalBarTimelineStyle = 'bg-primary-light'
  const pastDotTimelineStyle = (key: string) =>
    pastSectionState.includes(key) ? 'bg-[#8EB8EF]' : ''
  return (
    <Timeline className="text-primary-dark text-sm">
      <TimelineItem>
        <TimelineConnector
          className={cn(activeVerticalBarTimelineStyle, '-top-[4px]')}
        />
        <TimelineHeader>
          <TimelineIcon className="opacity-0" />
          <TimelineTitle></TimelineTitle>
        </TimelineHeader>
      </TimelineItem>
      {items.map((item, index) => (
        <TimelineItem key={item.id}>
          <TimelineConnector
            className={cn(
              index === items.length - 1 ? 'h-8' : '',
              pastSectionState.includes(item.value) &&
                activeVerticalBarTimelineStyle
            )}
          />
          <TimelineHeader>
            <TimelineIcon
              className={cn(
                currentStepKeyState === item.value && currentMainStep === 0
                  ? 'bg-primary-light'
                  : pastDotTimelineStyle(item.value)
              )}
            />
            <TimelineTitle>{item.title}</TimelineTitle>
          </TimelineHeader>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
