import { useEffect, useState } from 'react'
import CustomRating from '../CustomRating'
import Form from './Form'
import useClientSatisfaction from '@/atom/client-satisfaction'

export default function CommonSatisfactionStep({
  config,
}: Readonly<{
  config: { key: string; title: string; id: number; mainKey: number }
}>) {
  const { stepData } = useClientSatisfaction()
  const [gradeAverage, setGradeAverage] = useState(0)
  useEffect(() => {
    const validStepList = stepData.filter((item) => item.key !== 'GLO')
    const total = validStepList.reduce((acc, curr) => acc + curr.grade, 0)
    setGradeAverage(Number((total / 5).toFixed(1)))
  }, [stepData])
  return (
    <div>
      <div className="border-b py-6 px-8 flex flex-col items-start gap-y-3">
        {config.id < 6 && (
          <p className="text-neutral-darker/60 text-sm">Theme {config.id}/5</p>
        )}
        <h3 className="font-semibold text-xl">{config.title}</h3>
        {config.id === 6 && (
          <div className="flex items-center gap-x-2">
            <p className="text-sm text-neutral-darker/60">
              La note moyenne des thèmes précédents étant de :{' '}
            </p>
            <div className="[&>div]:gap-x-1 [&>div>span]:font-semibold [&>div>span]:text-base">
              <CustomRating className="[&_svg]:h-5" rating={gradeAverage} />
            </div>
          </div>
        )}
      </div>
      <div className="py-6 px-8">
        <Form mainKey={config.mainKey} stepKey={config.key} />
      </div>
    </div>
  )
}
