import { DialogTitle } from '@/components/ui/dialog'
import ActionsTop from './ActionsTop'
import { DialogDescription } from '@radix-ui/react-dialog'
import { useAtom } from 'jotai'
import { TicketAtom } from '@/atom/ticket'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import clsx from 'clsx'
import {
  JiraTextFormatAdapter,
  extractStringEpic,
  formatDateTime,
  generateRandomAvatarColor,
  getNameAbbreviation,
} from '@/lib/utils'
import MarkdownRenderer from '@/components/markdown'
import { useEffect, useState } from 'react'
import { getTicketDetails } from '@/services/api-client'
import { LoaderCircleIcon } from 'lucide-react'
import { Separator } from '@/components/ui/separator'
import CommentsIssue from '@/components/commments/CommentsIssue'
import { CustomField10020, TicketType } from '@/types/ITicket'

function CustomNameAvatar({ rawValue }: Readonly<{ rawValue: string }>) {
  if (rawValue) {
    const { bgColor, textColor } = generateRandomAvatarColor(rawValue)
    const firstname = rawValue.split(' ')[0]
    return (
      <span className="text-primary-dark flex items-center gap-x-2 font-normal text-base">
        <span
          className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {getNameAbbreviation(rawValue)}
        </span>
        {firstname}
      </span>
    )
  } else {
    return null
  }
}

export default function TicketDetails() {
  const [ticketAtom] = useAtom(TicketAtom)
  const [isLoadDetails, setIsLoadDetails] = useState(false)
  const [description, setDescription] = useState(null)
  const [comments, setComments] = useState<
    TicketType['fields']['comment']['comments']
  >([])
  async function getDescription(ticketKey: string) {
    try {
      setIsLoadDetails(true)
      const { data }: { data: TicketType } = await getTicketDetails(ticketKey)
      setDescription((data as any)['fields']['description'])
      setComments(data['fields']['comment']['comments'])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadDetails(false)
    }
  }

  useEffect(() => {
    ticketAtom?.key && getDescription(ticketAtom?.key)
  }, [ticketAtom])

  return (
    <div>
      {/* dialog top actions */}
      <ActionsTop className="pb-4" ticketKey={ticketAtom?.key as string} />
      <div className="max-h-[80vh] overflow-y-auto">
        {/* dialog main content */}
        <div className="pt-8 pb-6">
          {/* dialog title */}
          <DialogDescription />
          <DialogTitle />
          <div className="w-full grid grid-cols-4">
            <h1 className="text-primary-dark font-semibold w-full max-w-[554px] col-span-3">
              {ticketAtom?.fields.summary}
            </h1>
            <span className="text-[#717171] font-semibold w-full text-end">
              {ticketAtom?.key}
            </span>
          </div>
        </div>
        <ul className="pb-6 flex [&>] flex-col gap-y-4 [&>li]:flex [&>li]:w-full  max-w-96 [&>li>span:first-child]:w-60  [&>li>span:first-child]:text-base [&>li>span:first-child]:font-normal [&>li>span:first-child]:text-[#717171]">
          <li>
            <span>Sprint</span>
            {(ticketAtom?.fields?.customfield_10020 as CustomField10020[]) ? (
              <span
                style={{
                  backgroundColor: generateRandomAvatarColor(
                    (
                      ticketAtom?.fields.customfield_10020 as CustomField10020[]
                    )[0].name,
                    0.2
                  ).bgColor,
                  color: generateRandomAvatarColor(
                    (
                      ticketAtom?.fields.customfield_10020 as CustomField10020[]
                    )[0].name
                  ).bgColor,
                }}
                className="py-1 px-3 rounded-md whitespace-nowrap"
              >
                {
                  (
                    ticketAtom?.fields.customfield_10020 as CustomField10020[]
                  )[0].name
                }
              </span>
            ) : (
              <NotAllowedIcon />
            )}
          </li>
          <li>
            <span>EPIC</span>
            {ticketAtom?.fields?.parent ? (
              <span
                style={{
                  backgroundColor: generateRandomAvatarColor(
                    ticketAtom?.fields.parent.fields.summary,
                    0.2
                  ).bgColor,
                  color: generateRandomAvatarColor(
                    ticketAtom?.fields.parent.fields.summary
                  ).bgColor,
                }}
                className="py-1 px-3 rounded-md whitespace-nowrap"
              >
                {extractStringEpic(ticketAtom?.fields.parent.fields.summary)}
              </span>
            ) : (
              <NotAllowedIcon />
            )}
          </li>
          <li>
            <span>Statut</span>
            <span
              className={clsx(
                'py-1 px-3 rounded-md uppercase relative border border-neutral-dark'
              )}
              style={{
                color:
                  ticketAtom?.fields.status.statusCategory.colorName ===
                  'yellow'
                    ? 'orange'
                    : ticketAtom?.fields.status.statusCategory.colorName ||
                      'gray',
              }}
            >
              <span
                className="absolute top-0 left-0 w-full h-full rounded-md"
                style={{
                  backgroundColor:
                    ticketAtom?.fields.status.statusCategory.colorName ===
                    'yellow'
                      ? 'orange'
                      : ticketAtom?.fields.status.statusCategory.colorName,
                  opacity: 0.2,
                }}
              />
              {ticketAtom?.fields.status.statusCategory.name}
            </span>
          </li>
          <li>
            <span>Priorité</span>
            <div className="flex items-center gap-x-2">
              <img
                src={ticketAtom?.fields.priority.iconUrl}
                className="w-6 h-6"
                alt="icon"
              />
              <span className="font-normal text-primary-dark text-base">
                {ticketAtom?.fields.priority.name}
              </span>
            </div>
          </li>
          <li>
            <span>Assigné à</span>
            {ticketAtom?.fields.assignee ? (
              <CustomNameAvatar
                rawValue={ticketAtom?.fields.assignee?.displayName}
              />
            ) : (
              <NotAllowedIcon />
            )}
          </li>
          <li>
            <span>Date de création</span>
            <span className="text-primary-dark">
              {formatDateTime(new Date(ticketAtom?.fields.created as Date))}
            </span>
          </li>
        </ul>
        {/* separator */}
        {/* <div className="w-full border-b border-b-neutral-dark absolute left-1/2 -translate-x-1/2" /> */}

        {/* description */}
        {isLoadDetails && !description ? (
          <div className="w-full h-60 items-center flex justify-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        ) : (
          <>
            <div className="pt-6">
              <div className="p-6">
                <div className="text-primary-dark font-normal text-base ">
                  <MarkdownRenderer
                    content={JiraTextFormatAdapter(description ?? '')}
                  />
                </div>
              </div>
            </div>
            <Separator className="my-4" />
            <div className="px-4">
              <CommentsIssue
                id={ticketAtom?.key!}
                commentsProps={comments}
                isDialog
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
