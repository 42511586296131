import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const project = Cookies.get('projectID')

export function postUsefullLink(payload: {
  category?: string
  name: string
  url: string
  icon: string
}) {
  return axiosClient.post(`/link/${project}`, {...payload, project})
}
