import InclinedArrow from '@/components/icons/InclinedArrow'
import { ROUTES } from '@/configs/route-naming'
import { useNavigate } from 'react-router-dom'

interface ITicketCardProps {
  tickets: string
  label: string
}
const TicketCard: React.FC<ITicketCardProps> = ({ tickets, label }) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-1 flex-col justify-between  bg-primary-light rounded p-4">
      <div className="flex justify-end">
        <button
          onClick={() => navigate(ROUTES.CLIENT.TICKETS_TO_TEST)}
          className="appearance-none rounded cursor-pointer bg-white p-2 mb-6 [&_path]:stroke-primary-light"
        >
          <InclinedArrow />
        </button>
      </div>
      <div>
        <div className="font-semibold text-white text-5xl">{tickets}</div>
        <div className="text-white text-sm ">{label}</div>
      </div>
    </div>
  )
}

export default TicketCard
