import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MarkdownRenderer = ({
  content,
  dark,
}: {
  content: any
  dark?: boolean
}) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={`prose ${dark ? 'text-gray-300' : ''} [&_h2]:mt-4 [&_h2]:mb-2 [&_h1]:mt-4 [&_h1]:mb-2 [&_hr]:mt-2`}
    >
      {content}
    </ReactMarkdown>
  )
}

export default MarkdownRenderer
