import { CustomTooltipProps } from '@/types/IChartTooltip'

const EvolutionTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload?.length) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-neutral-dark">
        <div className="text-sm text-neutral-darker mb-3">{label} 2024</div>
        <div className="space-y-2">
          <div className="flex items-start space-x-2">
            <div className="w-2 h-2 rounded-full bg-average mt-2"></div>
            <div>
              <div className="font-medium text-primary-dark">
                {payload[1].value} story points restants Dev
              </div>
              <div className="text-neutral-darker">
                {/* ({payload[0].payload.testingTicketCount} tickets) */}
              </div>
            </div>
          </div>
          <div className="flex items-start space-x-2">
            <div className="w-2 h-2 rounded-full bg-primary-light mt-2"></div>
            <div>
              <div className="font-medium text-primary-dark">
                {payload[0].value} story points restants Team
              </div>
              <div className="text-neutral-darker">
                {/* ({payload[0].payload.inProgressTicketCount} tickets) */}
              </div>
            </div>
          </div>
          <div className="pt-1 flex space-x-2">
            <div className="w-2 h-2 bg-neutral-darker rounded-full mt-2"></div>
            <div className="font-medium text-primary-dark">
              {payload[2].payload.directive} story points (DIRECTIVE)
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default EvolutionTooltip
