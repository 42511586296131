interface IHeadingTagprops {
  label: string
}
const HeadingTag: React.FC<IHeadingTagprops> = ({ label }) => {
  return (
    <div className="bg-neutral-light rounded-full py-2 px-3 inline ">
      <span className="text-sm text-neutral-darker">{label}</span>
    </div>
  )
}

export default HeadingTag
