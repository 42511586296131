const FolderIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00008 11.6667L6.25008 9.25008C6.38598 8.9802 6.59268 8.75233 6.84809 8.59087C7.1035 8.42941 7.398 8.34041 7.70008 8.33341L16.6667 8.33341M16.6667 8.33341C16.9214 8.33297 17.1727 8.39086 17.4014 8.50265C17.6302 8.61444 17.8303 8.77715 17.9864 8.9783C18.1425 9.17945 18.2504 9.41369 18.3019 9.66304C18.3534 9.91239 18.3471 10.1702 18.2834 10.4167L17.0001 15.4167C16.9072 15.7764 16.6969 16.0947 16.4025 16.3212C16.1081 16.5476 15.7465 16.6693 15.3751 16.6667H3.33341C2.89139 16.6667 2.46746 16.4912 2.1549 16.1786C1.84234 15.866 1.66675 15.4421 1.66675 15.0001L1.66675 4.16675C1.66675 3.72472 1.84234 3.3008 2.1549 2.98824C2.46746 2.67567 2.89139 2.50008 3.33341 2.50008L6.58342 2.50008C6.86215 2.49735 7.13713 2.56457 7.38316 2.6956C7.6292 2.82662 7.83845 3.01727 7.99175 3.25008L8.66675 4.25008C8.81851 4.48052 9.0251 4.66968 9.268 4.80058C9.5109 4.93149 9.78249 5.00003 10.0584 5.00008H15.0001C15.4421 5.00008 15.866 5.17567 16.1786 5.48824C16.4912 5.8008 16.6667 6.22472 16.6667 6.66675V8.33341Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FolderIcon
