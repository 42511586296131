import { Button } from '../ui/button'
import { Checkbox } from '../ui/checkbox'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import { MultiSelect } from '../ui/multi-select'
import { Separator } from '../ui/separator'

interface Props {
  priorityResultFilter: string[]
  epicNameResultFilter: string[]
  ticketEpicNameListOptions: any[]
  handleCheckbox: (v: string) => void
  setEpicNameResultFilter: (v: string[]) => void
  applyFilter: () => void
}

export default function FilterBarForm({
  priorityResultFilter,
  epicNameResultFilter,
  ticketEpicNameListOptions,
  handleCheckbox,
  setEpicNameResultFilter,
  applyFilter,
}: Readonly<Props>) {
  const priorityList = [
    {
      label: 'Urgent',
      value: 'Highest',
    },
    {
      label: 'Forte',
      value: 'High',
    },
    {
      label: 'Moyenne',
      value: 'Medium',
    },
    {
      label: 'Faible',
      value: 'Low',
    },
  ]
  return (
    <div className="text-primary-dark px-2 pb-2">
      <p className="uppercase text-xs text-neutral-darker/60">Priorité</p>
      {priorityList.map((item) => (
        <div key={item.value} className="flex items-center gap-x-2 pt-2">
          <Checkbox
            checked={priorityResultFilter.includes(item.value)}
            onCheckedChange={() => handleCheckbox(item.value)}
            className="border-neutral-dark shadow-none"
            id={item.value}
          />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor={item.value}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {item.label}
            </label>
          </div>
        </div>
      ))}
      <p className="uppercase text-xs text-neutral-darker/60 py-2">Epic</p>
      <MultiSelect
        placeholder="Sélectionner une EPIC"
        defaultValue={epicNameResultFilter}
        onValueChange={(e) => setEpicNameResultFilter(e)}
        options={ticketEpicNameListOptions
          .map((item) => ({
            value: item,
            label: item,
          }))
          .filter((item) => !!item?.value)}
      />
      <Separator className="my-2" />
      <div className="justify-end flex">
        <DropdownMenuItem>
          <Button onClick={applyFilter}>Appliquer</Button>
        </DropdownMenuItem>
      </div>
    </div>
  )
}
