import GoalIcon from '@/components/icons/Goal'
import LineAreaChart from '@/components/dashboard/LineAreaChart'
interface ICoverageProps {
  data: any[]
}
const Coverage: React.FC<ICoverageProps> = ({ data }) => {
  const recentCoverage = data[data.length - 1]?.coverage

  const chartData = data.map((item) => {
    return { date: item.timestamp, coverage: item.coverage }
  })
  return (
    <div className="flex justify-center items-center flex-col gap-4">
      <div className="flex flex-col items-center justify-center pt-6">
        <p>
          <span className="text-primary-dark text-5xl">{recentCoverage}</span>
          <span className="text-neutral-darker text-3xl">%</span>
        </p>
        <div className="text-neutral-darker text-sm">de tests unitaire</div>
      </div>
      <div className="inline-flex items-center gap-2 p-2 bg-neutral-light rounded-md">
        <div className="bg-primary-light/10 rounded-md w-8 h-8 inline-flex items-center justify-center">
          <GoalIcon />
        </div>
        <span className="text-primary-dark text-sm">L'objectif est de 60%</span>
      </div>
      <div className="w-full ">
        <LineAreaChart data={chartData} />
      </div>
    </div>
  )
}

export default Coverage
