import { postChatActivity } from '@/services/chat.service'
import { WyChat, Weavy } from '@weavy/uikit-react'
import { useEffect, useRef } from 'react'

interface Props {
  url: string
  accessToken: string
  chatID: string
}
const ChatView = ({ url, accessToken, chatID }: Readonly<Props>) => {
  const chatRef = useRef(null)
  const weavy = new Weavy({
    url: url,
    tokenFactory: async () => accessToken,
  })
  useEffect(() => {
    const buttonCallbackFn = async () => {
      await postChatActivity(chatID)
    }
    let TARGET_BUTTON: HTMLElement
    setTimeout(() => {
      if (chatRef.current) {
        try {
          const wyChatElement: HTMLElement = chatRef.current
          const shadowRoot =
            wyChatElement.shadowRoot ||
            wyChatElement.attachShadow({ mode: 'open' })
          const wyChatChild = Array.from(shadowRoot.children)
          const wyChatConversation = wyChatChild[1]
          const shadowRoot2 =
            wyChatConversation.shadowRoot ||
            wyChatConversation.attachShadow({ mode: 'open' })
          const wyChatConversationChild = Array.from(shadowRoot2.children)
          const footer = wyChatConversationChild[1]
          const wymessageEditor = Array.from(
            footer.getElementsByTagName('wy-message-editor')
          )[0]
          const shadowRoot3 =
            wymessageEditor.shadowRoot ||
            wymessageEditor.attachShadow({ mode: 'open' })
          const shadowRoot3Child = Array.from(shadowRoot3.children)[0]
          const wymessageEditorInputs = shadowRoot3Child.children[0]
          const wyButton = Array.from(
            wymessageEditorInputs.getElementsByTagName('wy-button')
          )[0]
          const shadowRoot4 =
            wyButton.shadowRoot || wyButton.attachShadow({ mode: 'open' })
          const TARGET_BUTTON = Array.from(shadowRoot4.children)[0]
          TARGET_BUTTON.addEventListener('click', buttonCallbackFn)
        } catch (error) {
          throw new Error('An error occurred, HTML structures changed!')
        }
      }
    }, 2000)

    return () => {
      if (TARGET_BUTTON) {
        TARGET_BUTTON.removeEventListener('click', buttonCallbackFn)
      }
    }
  }, [chatRef])
  useEffect(() => {
    return () => {
      weavy.disconnect()
      weavy.destroy()
    }
  }, [])
  const uid = chatID
  return <WyChat ref={chatRef} uid={uid} />
}

export default ChatView
