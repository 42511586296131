import { PieChart, Pie, Cell } from 'recharts'

interface IGaugeChartProps {
  value: number
  max: number
}

const GaugeChart: React.FC<IGaugeChartProps> = ({ value, max }) => {
  const numberOfDots = 30 // Total number of dots in the gauge
  const filledDots = Math.round((value / max) * numberOfDots)

  // Create an array of data points for each dot
  const data = Array(numberOfDots)
    .fill(10)
    .map((_, index) => ({
      name: index,
      value: 1,
      fill: index < filledDots ? '#1F73E0' : '#ECECEC',
    }))

  return (
    <div className="relative flex justify-center pointer-events-none">
      <PieChart width={300} height={130}>
        {data.map((entry, index) => (
          <Pie
            key={entry.name}
            data={[entry]}
            cx={150}
            cy={90}
            startAngle={180 - index * 6}
            endAngle={180 - index * 6 - 4}
            innerRadius={60}
            outerRadius={90}
            paddingAngle={2}
            cornerRadius={2}
            dataKey="value"
          >
            <Cell fill={entry.fill} />
          </Pie>
        ))}
      </PieChart>

      <div className="inline-flex flex-col items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-1 ">
        <span className="text-3xl font-semibold text-primary-dark">
          {value}
        </span>
        <span className="text-neutral-darker">Points</span>
      </div>
    </div>
  )
}

export default GaugeChart
