import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from 'recharts'
import { ChartConfig, ChartContainer } from '@/components/ui/chart'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

export const description = 'A radial chart with text'

const chartDataInit = [
  { browser: 'safari', visitors: 80, fill: 'var(--color-safari)', bg: '' },
]

const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  safari: {
    label: 'Safari',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

const stats = {
  low: {
    fillColor: '#D9306B',
    bg: 'success',
  },
  medium: {
    fillColor: '#FA8334',
    bg: 'success',
  },
  high: {
    fillColor: '#0BB54F',
    bg: 'success',
  },
}

function LabelContent(props: any) {
  if (props.viewBox && 'cx' in props.viewBox && 'cy' in props.viewBox) {
    return (
      <text
        x={props.viewBox.cx}
        y={props.viewBox.cy}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <tspan
          x={props.viewBox.cx}
          y={props.viewBox.cy}
          className={clsx(props.getLabelSize(), `fill-primary-dark font-bold`)}
        >
          {props.chartData[0].visitors.toLocaleString()}
        </tspan>
      </text>
    )
  }
}

export default function CircleChart({
  value,
  size = 'medium',
  withMaxHeight = true,
}: Readonly<{
  value: number
  size?: 'xs' | 'sm' | 'medium'
  withMaxHeight?: boolean
}>) {
  const [chartData, setChartData] = useState([...chartDataInit])
  function getLabelValue() {
    if (value >= 0 && value < 50) {
      return 'low'
    } else if (value >= 50 && value < 90) {
      return 'medium'
    } else {
      return 'high'
    }
  }

  function getBGColor() {
    switch (getLabelValue()) {
      case 'low':
        return 'fill-secondary/5'
      case 'medium':
        return 'fill-average/5'
      case 'high':
        return 'fill-success/5'
    }
  }

  function getLabelSize() {
    switch (size) {
      case 'sm':
        return 'text-xl'
      case 'xs':
        return 'text-xs'
      case 'medium':
        return 'text-4xl'
    }
  }
  const chartSize = {
    xs: {
      height: 32,
      innerRadius: 13,
      outerRadius: 16,
      polarRadius: [13, 12],
      textSize: 'xs',
    },
    sm: {
      height: 78,
      innerRadius: 31,
      outerRadius: 39,
      polarRadius: [32, 29],
      textSize: 'xl',
    },
    medium: {
      height: 160,
      innerRadius: 63,
      outerRadius: 80,
      polarRadius: [66, 60],
      textSize: '4xl',
    },
  }
  function computedSize() {
    return Math.round((value / 100) * 360 + 90)
  }
  useEffect(() => {
    setChartData((prev) => [
      {
        ...prev[0],
        fill: stats[getLabelValue()].fillColor,
        visitors: value,
        bg: stats[getLabelValue()].bg,
      },
    ])
  }, [value])
  useEffect(() => {}, [])
  return (
    <ChartContainer
      config={chartConfig}
      className={`mx-0 aspect-square ${withMaxHeight ? 'max-' : ''}h-[${chartSize[size].height}px]`}
    >
      <RadialBarChart
        data={chartData}
        startAngle={90} //90
        endAngle={computedSize()} //450
        innerRadius={chartSize[size].innerRadius} // Scaled innerRadius
        outerRadius={chartSize[size].outerRadius}
      >
        <PolarGrid
          gridType="circle"
          radialLines={false}
          stroke="none"
          className={getBGColor()}
          polarRadius={chartSize[size].polarRadius}
        />
        <RadialBar dataKey="visitors" background cornerRadius={10} />
        <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
          <Label
            content={
              <LabelContent getLabelSize={getLabelSize} chartData={chartData} />
            }
          />
        </PolarRadiusAxis>
      </RadialBarChart>
    </ChartContainer>
  )
}
