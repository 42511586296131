import { Button } from '../ui/button'

interface Props {
  context: string
  detailList: string[]
  urlImg: string
}
export default function MonitoringNoConfiguration({
  context,
  detailList,
  urlImg,
}: Readonly<Props>) {
  return (
    <div className="grid grid-cols-3 h-[448px]">
      <div className="bg-neutral-dark h-full flex items-center">
        <img
          alt="Etat de l'application non configurée"
          src={urlImg}
        />
      </div>
      <div className="col-span-2 bg-white text-primary-dark px-8 flex items-center">
        <div>
          <h3 className="font-semibold text-xl">
            Fonctionnalité non configurée
          </h3>
          <p className="text-sm pt-4">
            L’onglet “{context}” n’est pas encore activé pour votre projet. Nous
            vous invitons à contacter votre Product Owner afin de vérifier la
            possibilité d'activer cette fonctionnalité.
          </p>
          <div className="pt-4 text-sm">
            <p>Cet onglet vous permettra de :</p>
            <ul className="list-disc text-sm pl-6">
              {detailList.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="pt-8">
            <Button>Contactez votre Product Owner</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
