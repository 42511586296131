const HomeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8.47825C2.5 7.86107 2.78495 7.27846 3.27212 6.89954L8.77212 2.62177C9.49434 2.06004 10.5057 2.06004 11.2279 2.62177L16.7279 6.89954C17.2151 7.27846 17.5 7.86107 17.5 8.47825V16.6667C17.5 17.1088 17.3244 17.5327 17.0118 17.8453C16.6993 18.1578 16.2754 18.3334 15.8333 18.3334H4.16667C3.72464 18.3334 3.30072 18.1578 2.98816 17.8453C2.67559 17.5327 2.5 17.1088 2.5 16.6667V8.47825Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.3333V12C7.5 10.8954 8.39543 10 9.5 10H10.5C11.6046 10 12.5 10.8954 12.5 12V18.3333"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HomeIcon;