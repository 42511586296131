const ServerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1843_9297)">
        <path
          d="M16.6665 1.66667H3.33317C2.4127 1.66667 1.6665 2.41286 1.6665 3.33334V6.66667C1.6665 7.58715 2.4127 8.33334 3.33317 8.33334H16.6665C17.587 8.33334 18.3332 7.58715 18.3332 6.66667V3.33334C18.3332 2.41286 17.587 1.66667 16.6665 1.66667Z"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6665 11.6667H3.33317C2.4127 11.6667 1.6665 12.4129 1.6665 13.3333V16.6667C1.6665 17.5871 2.4127 18.3333 3.33317 18.3333H16.6665C17.587 18.3333 18.3332 17.5871 18.3332 16.6667V13.3333C18.3332 12.4129 17.587 11.6667 16.6665 11.6667Z"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 5H5.00833"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15H5.00833"
          stroke="#11103B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1843_9297">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ServerIcon
