import * as React from 'react'

import { cn } from '@/lib/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: React.ReactNode
  label?: React.ReactNode
}

const prefixIconClass = 'pl-9'
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefixIcon, label, ...props }, ref) => {
    return (
      <div className="relative flex flex-col gap-y-2">
        {label ? (
          <label
            htmlFor={props.id || ''}
            className="text-primary-dark text-sm font-semibold"
          >
            {label}
          </label>
        ) : (
          ''
        )}
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-sm placeholder:font-light placeholder:text-neutral-darker focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
            prefixIcon && prefixIconClass
          )}
          ref={ref}
          {...props}
        />
        {prefixIcon && (
          <span className="absolute top-1/2 left-1 -translate-y-1/2">
            {prefixIcon}
          </span>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
