import ChevronTop from '@/components/icons/ChevronTop'
import ChatIcon from '@/components/icons/Chat'
import clsx from 'clsx'
import { formatTimeElapsed } from '@/lib/utils'
import { Comment, TicketType } from '@/types/ITicket'

interface ITicketSummaryItemProps {
  label: string
  ticketKey: string
  count: number
  isActive: boolean
  created: string
  comments: Comment[]
  prioImg: string
  handleClick: () => void
}
const ACTIVE_TICKET_CLASS =
  "relative before:contents[''] before:top-0 before:rounded-s-md before:absolute before:w-1 before:h-full before:bg-primary-light before:left-0 hover:bg-neutral-dark/60 transition duration-300 ease-in-out"

const TicketSummaryItem: React.FC<ITicketSummaryItemProps> = ({
  label,
  ticketKey,
  count,
  created,
  handleClick,
  isActive,
  comments,
  prioImg
}) => {
  function daysBetween(_dateToCompare: Date) {
    const today = new Date().getTime()
    const dateToCompare = new Date(_dateToCompare).getTime()
    const differenceMs = Math.abs(dateToCompare - today)
    return Math.ceil(differenceMs / (1000 * 60 * 60 * 24))
  }
  const handleCommentIconBg = (
    comments: TicketType['fields']['comment']['comments']
  ) => {
    const commentsDateList = comments.map((item) => item.updated)
    for (let _date of commentsDateList) {
      const tmpDate = new Date(_date)
      if (daysBetween(tmpDate) <= 3) return 'bg-yellow'
      return 'bg-neutral-dark'
    }
  }
  return (
    <div
      aria-hidden
      className={clsx(
        'flex flex-col gap-2 px-6 py-8 hover:bg-primary-light/5 cursor-pointer transition duration-300 ease-in-out bg-white border border-neutral-dark ',
        isActive ? ACTIVE_TICKET_CLASS : ''
      )}
      onClick={handleClick as any}
    >
      <div className="text-primary-dark text-sm">{label}</div>
      <div className="flex justify-between items-center ">
        <div className="inline-flex items-center gap-2">
          <span className="font-semibold text-primary-dark text-xs">
            {ticketKey}
          </span>
          <span className="text-neutral-darker text-xs">
            {formatTimeElapsed(created)}
          </span>
        </div>
        <div className="inline-flex gap-2">
          <img className='w-6 h-6' src={prioImg} alt={ticketKey} />
          <div className="relative">
            {count ? (
              <span
                className={clsx(
                  handleCommentIconBg(comments),
                  'absolute top-[-6px] right-[-2px] w-4 h-4 inline-flex items-end justify-center rounded-sm text-xs text-neutral-darker'
                )}
              >
                {count}
              </span>
            ) : (
              ''
            )}
            <ChatIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketSummaryItem
