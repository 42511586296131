const BacklogIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1668 6.25005L4.16674 6.25L4.16676 10H19.1667L19.1668 6.25005Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10.0001L2.5 10L2.50001 13.75H17.5L17.5 10.0001Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 13.75L2.50001 13.75L2.5 15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V13.75Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667L2.50001 6.25H17.5V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BacklogIcon
