import EmptyData from '@/components/empty-data'
import PlusIcon from '@/components/icons/PlusIcon'
import CreateReleaseForm from '@/components/release/Form/create-form'
import EditReleaseForm from '@/components/release/Form/edit-form'
import ReleaseDetailListing from '@/components/release/ReleaseDetailListing'
import ReleaseVersionList from '@/components/release/ReleaseVersionList'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import HeadingTitle from '@/components/ui/heading-title'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
import { getReleaseList } from '@/services/api-client'
import { IRelease } from '@/types/IRelease'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

export default function Release() {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenFormModal, setIsOpenFormModal] = useState(false)
  const [releases, setReleases] = useState<IRelease[]>([])
  const [selectedRelease, setSelectedRelease] = useState<IRelease>()
  const { hasAccess } = useDirOwnerProdAccess()
  async function getRealeases() {
    try {
      const { data }: { data: IRelease[] } = await getReleaseList()
      setReleases(data)
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  function handleFormPostAction(incomingRelease?: IRelease, isEdit?: boolean) {
    if (!incomingRelease && !isEdit) {
      /**
       * USER CANCEL ACTION
       */
      setIsOpenFormModal(false)
    }
    if (incomingRelease && !isEdit) {
      /**
       * USER CREATE NEW VERSION
       */
      setReleases((prev) => [incomingRelease, ...prev])
      setIsOpenFormModal(false)
    } else {
      /**
       * USER UPDATE A VERSION
       */
      incomingRelease &&
        setReleases((prev) => [
          ...prev.map((release) =>
            incomingRelease.id === release.id ? incomingRelease : release
          ),
        ])
      setSelectedRelease(undefined)
    }
  }

  useEffect(() => {
    getRealeases()
  }, [])
  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }
  return (
    <>
      <div>
        <div className="flex justify-between">
          <HeadingTitle label="Notes de version" className="mb-0" />
          {hasAccess ? (
            <Button
              onClick={() => setIsOpenFormModal(true)}
              className="w-auto flex gap-2"
            >
              <PlusIcon /> Ajout d'une note de version
            </Button>
          ) : (
            ''
          )}
        </div>
        {releases.length ? (
          <div className="grid grid-cols-4 gap-x-6">
            <ReleaseDetailListing
              setSelectedRelease={setSelectedRelease}
              isLoading={isLoading}
              releases={releases}
              className="col-span-3 pb-5"
            />
            {!isLoading && (
              <div>
                <ReleaseVersionList releases={releases} />
              </div>
            )}
          </div>
        ) : (
          <div className="h-[80vh]">
            <EmptyData msg="La liste de version est vide" />
          </div>
        )}
      </div>
      {/* CREATE FORM MODAL */}
      <Dialog
        open={!!isOpenFormModal}
        onOpenChange={() => setIsOpenFormModal(false)}
      >
        <DialogContent className="max-w-[640px] pb-0">
          <CreateReleaseForm closeModal={handleFormPostAction} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!selectedRelease}
        onOpenChange={() => setSelectedRelease(undefined)}
      >
        <DialogContent className="max-w-[640px] pb-0">
          <EditReleaseForm
            releaseToUpdate={selectedRelease!}
            closeModal={handleFormPostAction}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
