import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import { ChartData } from '@/types/IClientSatisfaction'

interface IMultipleLineChartProps {
  data: ChartData[]
}

const MultipleLineChart: React.FC<IMultipleLineChartProps> = ({ data }) => {
  const renderXAxisTick = (props: any) => {
    const { x, y, payload } = props
    const fullDate = payload.value.split('-')
    const year = fullDate[0]
    const week = fullDate[1]

    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text dy={16} fontSize={12} fill="#717171">
          {year}
        </text>
        <text dy={30} fontSize={12} fill="#717171">
          {week}
        </text>
      </g>
    )
  }
  return (
    <ResponsiveContainer width="95%" height={342}>
      <LineChart accessibilityLayer width={800} height={342} data={data}>
        <XAxis
          dataKey="week"
          tickLine={false}
          axisLine={false}
          angle={-45}
          textAnchor="end"
          height={40}
          tick={renderXAxisTick}
        />
        <YAxis tickLine={false} axisLine={false} />
        <CartesianGrid stroke="#F6F6F6" strokeWidth={1.5} />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Line
          type="linear"
          dot={false}
          dataKey="Communication"
          stroke="#1F73E0"
        />
        <Line
          type="linear"
          dot={false}
          dataKey="Conseil et enjeux"
          stroke="#FA8334"
        />
        <Line type="linear" dot={false} dataKey="Réactivité" stroke="#FFDE34" />
        <Line
          type="linear"
          dot={false}
          dataKey="Qualité des livrables"
          stroke="#D9306B"
        />
        <Line
          type="linear"
          dot={false}
          dataKey="Respect des délais"
          stroke="#171717"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default MultipleLineChart
