import axiosClient from '@/configs/axios'
import Cookies from 'js-cookie'
const project = Cookies.get('projectID')

export function getChat() {
  return axiosClient.get(`/chats/${project}`)
}

export function postChatActivity(chatID: string) {
    return axiosClient.post(`/chat_activity/${chatID}`)
}