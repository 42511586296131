const BellIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 5.33337C4 4.27251 4.42143 3.25509 5.17157 2.50495C5.92172 1.7548 6.93913 1.33337 8 1.33337C9.06087 1.33337 10.0783 1.7548 10.8284 2.50495C11.5786 3.25509 12 4.27251 12 5.33337C12 10 14 11.3334 14 11.3334H2C2 11.3334 4 10 4 5.33337Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8667 14C6.97829 14.203 7.14233 14.3722 7.34169 14.4901C7.54106 14.608 7.76842 14.6702 8.00003 14.6702C8.23165 14.6702 8.45901 14.608 8.65837 14.4901C8.85773 14.3722 9.02178 14.203 9.13337 14"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BellIcon
