import { IMappedProject } from '@/types/IProject'
import { NavLink } from 'react-router-dom'
import RequestIcon from '../icons/Request'
import Cookies from 'js-cookie'
import { ROUTES } from '@/configs/route-naming'

export default function ProjectCard({
  project,
}: Readonly<{ project: IMappedProject }>) {
  const chooseProject = (e: MouseEvent) => {
    e.preventDefault()
    Cookies.set('projectID', project.id)
    Cookies.set('projectKey', project.key)
    Cookies.set('projectName', project.name)
    Cookies.set('projectUrlAvatar', project.logoUrl)
    Cookies.set('projectUrlAvatarDark', project.logoDarkUrl)
    window.location.href = ROUTES.CLIENT.HOME
  }
  return (
    <NavLink
      to={ROUTES.CLIENT.HOME}
      onClick={(e) => chooseProject(e as any)}
      className="min-w-96 bg-white p-6 border rounded"
    >
      <div className="max-w-10 h-10 overflow-hidden rounded-md bg-white border flex items-center">
        {project.logoUrl ? (
          <img
            className="max-w-10 object-cover h-auto rounded-md"
            src={project.logoUrl}
            alt={project.id + '-' + project.name}
          />
        ) : (
          ''
        )}
      </div>
      <div className="pt-1">
        <p className="font-semibold text-base text-primary-dark">
          {project.name}
        </p>
        <p className="text-sm text-primary-light font-normal">
          {project.clientName}
        </p>
      </div>
      <div className="pt-6 flex flex-col gap-y-2 w-fit">
        <div className="gap-x-1 bg-neutral-dark py-1 px-2 text-primary-dark font-medium flex items-center border rounded-full">
          <RequestIcon />
          {project.requestCount}{' '}
          {project.requestCount === 0
            ? 'demande examinée'
            : 'demandes examinées'}
        </div>
      </div>
    </NavLink>
  )
}
