const EyeOpenIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.33325 7.99967C1.33325 7.99967 3.33325 3.33301 7.99992 3.33301C12.6666 3.33301 14.6666 7.99967 14.6666 7.99967C14.6666 7.99967 12.6666 12.6663 7.99992 12.6663C3.33325 12.6663 1.33325 7.99967 1.33325 7.99967Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke="#11103B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EyeOpenIcon
