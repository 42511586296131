import { extractFeedbackRatings } from '@/lib/utils.satisfaction'
import { InputData } from '@/types/IClientSatisfaction'
import EmptyData from '../empty-data'
interface IGlobalFeedbackProps {
  feedback: InputData
}
const GlobalFeedback: React.FC<IGlobalFeedbackProps> = ({ feedback }) => {
  return (
    <div className="bg-neutral-light px-8 py-6 flex flex-col gap-2 rounded">
      <h4 className="text-base text-primary-dark">Avis global</h4>
      <p className="text-primary-dark text-sm">
        {(extractFeedbackRatings(feedback.comment).global
          ? extractFeedbackRatings(feedback.comment).global
          : feedback.comment) ?? <EmptyData />}
      </p>
    </div>
  )
}

export default GlobalFeedback
