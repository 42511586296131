import { CustomTooltipProps } from '@/types/IChartTooltip'

const CustomBarTooltip = ({ active, payload, legend }: CustomTooltipProps) => {
  if (!active || !payload?.length) return null

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg border border-neutral-dark">
      <p className="text-xs mb-1 font-normal text-neutral-darker">
        Story point : {payload[0].payload.date}
      </p>
      <p className="text-sm text-primary-dark">{payload[0].value}h passées</p>
      <p className="text-sm text-neutral-darker">
        ({payload[0].payload.tickets} tickets)
      </p>
    </div>
  )
}

export default CustomBarTooltip
