import { Badge } from '@/components/ui/badge'
import clsx from 'clsx'

export default function MetricDetailsFilter({
  isSuccess = false,
  setSuccess,
  successCount,
  failedCount,
}: Readonly<{
  isSuccess?: boolean
  setSuccess: (v: boolean) => void
  successCount: number
  failedCount: number
}>) {
  const selectedMenuClass =
    'before:border-b-2 before:border-primary-light before:w-full before:-bottom-[17px] before:absolute'
  return (
    <div className="border-b pb-4 flex justify-between ">
      <ul className="flex items-center gap-x-4">
        {failedCount ? (
          <li className={clsx('relative', !isSuccess ? selectedMenuClass : '')}>
            <button
              onClick={() => setSuccess(false)}
              className="text-sm font-semibold flex items-center gap-1"
            >
              Audit échoué{' '}
              <Badge className="w-6 h-6 flex items-center justify-center hover:bg-neutral-dark bg-neutral-dark shadow-none text-neutral-darker">
                {failedCount}
              </Badge>
            </button>
          </li>
        ) : (
          ''
        )}
        {successCount > 0 ? (
          <li className={clsx('relative', isSuccess ? selectedMenuClass : '')}>
            <button
              onClick={() => setSuccess(true)}
              className="text-sm font-semibold flex items-center gap-1"
            >
              Audit réussis{' '}
              <Badge className="w-6 h-6 flex items-center justify-center hover:bg-neutral-dark bg-neutral-dark shadow-none text-neutral-darker">
                {successCount}
              </Badge>
            </button>
          </li>
        ) : (
          ''
        )}
      </ul>
      <ul className="flex gap-x-4 [&>li]:flex [&>li]:items-center [&>li]:gap-x-1">
        <li>
          <div className="h-2 w-2 bg-secondary rounded-[50%]" />
          0-49
        </li>
        <li>
          <div className="h-2 w-2 bg-[#FA8334] rounded-[50%]" />
          50-89
        </li>
        <li>
          <div className="h-2 w-2 bg-success rounded-[50%]" />
          90-100
        </li>
      </ul>
    </div>
  )
}
