import clsx from 'clsx'
import SearchIcon from '@/components/icons/SearchIcon'
import CircleChart from '@/components/performances/chart/CircleChart'
import { IPerformance } from '@/types/IPerformances'
import { useEffect, useState } from 'react'
import { Input } from '../ui/input'

interface Props {
  pageList: IPerformance[]
  handleChangePage: (v: IPerformance) => void
  selectedPage: IPerformance
}
const selectedPageClass = `bg-primary-light/5 before:w-2`

export default function PageAnalyzed({
  pageList,
  handleChangePage,
  selectedPage,
}: Readonly<Props>) {
  const [isdisplayInput, setIsdisplayInput] = useState(false)
  const [avarageTotalScore, setAvarageTotalScore] = useState(0)
  const [stringFilter, setStringFilter] = useState('')
  const [currentPageList, setCurrentPageList] = useState(pageList)
  function computedTotalPerfAvarage() {
    const total = selectedPage.metrics.find(
      (item) => item.target.toLocaleLowerCase() === 'total'
    )
    if (total) {
      const values = total.datapoints.map((item) => item[0])
      const average = Math.round(
        values.reduce((sum, value) => sum + value, 0) / values.length
      )
      setAvarageTotalScore(average)
    }
  }

  useEffect(() => {
    const result = pageList.filter((item) =>
      item.name.toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase())
    )
    setCurrentPageList(result)
  }, [stringFilter])
  useEffect(() => {
    computedTotalPerfAvarage()
  }, [selectedPage])
  return (
    <div className="bg-neutral-dark p-2 pt-0 rounded-md h-fit">
      <div className="text-sm text-primary-dark font-semibold flex items-center justify-between p-4">
        Pages analysées{' '}
        <div className="flex items-center gap-x-1">
          <button onClick={() => setIsdisplayInput((prev) => !prev)}>
            <SearchIcon />
          </button>
          {isdisplayInput ? (
            <Input
              type="text"
              className="w-[150px] p-0 h-5 border-0 placeholder:text-sm shadow-none focus-visible:ring-0"
              placeholder="Rechercher par nom"
              onChange={(e) => setStringFilter(e.target.value)}
              autoFocus
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {currentPageList.length ? (
        <ul className="bg-white rounded-md">
          {currentPageList.map((item, index) => (
            <li key={item.name}>
              <button
                onClick={() => handleChangePage(item)}
                className={clsx(
                  index === currentPageList.length - 1
                    ? 'before:rounded-bl-md'
                    : ' ',
                  index === 0 ? 'before:rounded-tl-md' : '',
                  selectedPage.name === item.name ? selectedPageClass : ' ',
                  'text-primary-dark flex justify-between items-center relative before:h-full before:absolute  before:bg-primary-light before:left-0 p-4 border-b last:border-none w-full h-full'
                )}
              >
                <span>{item.name}</span>{' '}
                <span className="[&>div]:h-[32px]">
                  <CircleChart
                    value={avarageTotalScore}
                    size="xs"
                    withMaxHeight={false}
                  />
                </span>
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm text-neutral-darker/60 text-center py-8">
          Pas de resulat
        </p>
      )}
    </div>
  )
}
