import { IComment } from '@/types/IComment'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import { formatTimeElapsed, getNameAbbreviation } from '@/lib/utils'

interface Props {
  comments: IComment
}
export default function CommentItem({ comments }: Readonly<Props>) {
  return (
    <div className="py-4">
      <div className="border-t pt-6 ">
        <div className="flex gap-x-4">
          <Avatar className="border">
            <AvatarImage
              src={comments.user.user_profile.profile_picture}
              alt={comments.user.email}
            />
            <AvatarFallback>
              {getNameAbbreviation(
                `${comments.user.first_name} ${comments.user.last_name}`
              )}
            </AvatarFallback>
          </Avatar>
          <div>
            <p className="font-medium text-base text-primary-dark">
              {comments.user.first_name}
            </p>
            <p className="text-sm text-neutral-darker/80">
              {formatTimeElapsed(comments.created_at)}
            </p>
          </div>
        </div>
        <div className="pt-4 text-sm text-primary-dark">{comments.content}</div>
      </div>
    </div>
  )
}
